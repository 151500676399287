var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "set-password-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
      },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMessage,
                expression: "showMessage",
              },
            ],
            class: _vm.classObject,
          },
          [_vm._v(_vm._s(_vm.error || _vm.success || _vm.tokenError))]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.tokenError,
              expression: "!tokenError",
            },
          ],
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.token,
                  expression: "!token",
                },
              ],
              staticClass: "input-section full-width",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.passwordCurrent,
                    expression: "passwordCurrent",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "password",
                  name: "passwordCurrent",
                  placeholder: "Current Password",
                  disabled: _vm.isDisabled,
                },
                domProps: { value: _vm.passwordCurrent },
                on: {
                  change: _vm.textChanged,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.passwordCurrent = $event.target.value
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "input-section full-width" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "password",
                placeholder: "New Password",
                disabled: _vm.isDisabled,
              },
              domProps: { value: _vm.password },
              on: {
                change: _vm.textChanged,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-section full-width" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.passwordConfirm,
                  expression: "passwordConfirm",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "passwordConfirm",
                placeholder: "Confirm Password",
                disabled: _vm.isDisabled,
              },
              domProps: { value: _vm.passwordConfirm },
              on: {
                change: _vm.textChanged,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.passwordConfirm = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "actions cf" }, [
            _c(
              "button",
              {
                staticClass: "submit",
                attrs: { type: "submit", disabled: _vm.isDisabled },
              },
              [_vm._v("Submit")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tokenError,
              expression: "tokenError",
            },
          ],
          staticClass: "actions cf",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-default pull-right",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("Close")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }