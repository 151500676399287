var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-body" },
    [
      _c("view-common", {
        ref: "common",
        attrs: { config: _vm.getViewCommonConfig() },
      }),
      _vm._v(" "),
      _c(
        "form",
        [
          _c("vue-form-generator", {
            ref: "vfgInstance",
            attrs: {
              model: _vm.model.data,
              options: _vm.model.options,
              schema: _vm.parsedSchema,
            },
            on: {
              "model-updated": _vm.onModelUpdated,
              validated: _vm.onValidated,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }