var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _vm.hasPE
      ? _c(
          "div",
          { staticClass: "translate-view" },
          [
            _c(
              "view-common",
              { ref: "common", attrs: { config: _vm.getViewCommonConfig() } },
              [
                _c(
                  "template",
                  { slot: "toolbar" },
                  [
                    _c(
                      "view-toolbar-button",
                      {
                        attrs: {
                          icon: "plus",
                          enable: _vm.addNewButtonEnabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addTranslation()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.t("New Translation")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "context-translation" },
                  [
                    _c(
                      "context-menu-item",
                      {
                        attrs: { icon: "close" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteTranslation(
                              _vm.currentContextTarget.row
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.t("Delete")))]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c("ag-grid-vue", {
              ref: "grid",
              staticClass: "ag-grid-vue ag-theme-balham",
              attrs: {
                deltaRowDataMode: true,
                getRowNodeId: _vm.getRowNodeId,
                suppressAsyncEvents: true /* otherwise we cannot prevent default context menu */,
                suppressContextMenu: true,
                rowData: _vm.model.translations,
                floatingFilter: true,
                pagination: true,
                paginationAutoPageSize: true,
                enableSorting: true,
                enableColResize: true,
                defaultColDef: _vm.defaultColumnDefinitions,
                columnDefs: _vm.columnDefinitions,
                getRowClass: _vm.getRowClass,
              },
              on: {
                gridReady: _vm.onGridReady,
                cellValueChanged: _vm.onCellValueChanged,
                cellContextMenu: _vm.onCellContextMenu,
                firstDataRendered: _vm.onFirstDataRendered,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "actions right" }, [
              _vm.model.hasChanges
                ? _c("span", { staticClass: "badge badge-warning" }, [
                    _vm._v("Unsaved changes"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.saveTranslations()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.t("Save")))]
              ),
            ]),
          ],
          1
        )
      : _c("div", { staticClass: "translate-view-error" }, [
          _vm._v(
            "\n\t\tYour current product and/or license does not support TranslateView\n\t"
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }