const ViewRenderer = require('../view-renderer');
const log = require('core/src/log').instance("function/tableview/renderer");
const DataTable = require('core/src/data-table');
const Mustache = require('mustache');

const _ = require('core/src/utils/legacy');

const $ = require('jquery');
const dateFormat = require('dateformat');

require('client/libraries/jqwidgets-imports');

/* Inheritance and constructor */

const TableViewRenderer = function() {
	ViewRenderer.call(this);
	this._jqxOptions = {};
	this._options = {};
};
TableViewRenderer.viewType = 'TableView';
TableViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* PROPERTIES */

TableViewRenderer.prototype._context = null;
TableViewRenderer.prototype._table = null;
TableViewRenderer.prototype._dataTable = null;
TableViewRenderer.prototype._data = null;
TableViewRenderer.prototype._columns = null;
TableViewRenderer.prototype._options = null;
TableViewRenderer.prototype._jqxOptions = null;
TableViewRenderer.prototype._toolbar = null;
TableViewRenderer.prototype._clickedRow = null;
TableViewRenderer.prototype._rowDoubleClickTimeoutMs = 300; //ms
/* METHODS */

/* Protected */

TableViewRenderer.prototype.getBatchSelection = function() {
	return this.getSelection();
};

TableViewRenderer.prototype.getSelection = function() {
	return this._table.jqxDataTable('getSelection');
};

TableViewRenderer.prototype.getVisible = function() {
	var self = this;
	var visibleRows = this._table.jqxDataTable('getView');
	var visibleData = [];
	_.forEach(visibleRows, function(row) {
		var dataRow = _.get(self._data, row.uid);
		if(_.def(dataRow)) {
			visibleData.push(dataRow);
		}
	});
	return visibleData;
};

TableViewRenderer.prototype.updateState = function() {
	var selection = this.getSelection();
	var visible = this.getVisible();

	var state = {
		selected: selection,
		visible: visible
	};
	state[Function.VALUE_OBJECT] = true; // make sure entire state gets replaced instead of merged
	return this.requestUpdate({state: state});
};

/**
 *  * Renders table view
 * data = {
 *	 #data = [{key1: val1, key2:val2}, {key1: val3, key2: val4}, ...]
 *	 columns: {
 *		  column_name: {
 *			  values : 'key1'
 *			  label: 'Column label'
 *			  index: 1
 *			  width: 10 //not implemented
 *			  sortable: true,
 *			  hidden: false,
 *			  autoCellHeight: true
 *		  }
 *	 }
 * }
 *
 * @param  {[type]} data [description]
 * @return {[type]}	  [description]
 */
TableViewRenderer.prototype.doRender = function(renderData) {
	var self = this;

	this._contextMenuData = renderData.context;
	var data = renderData.data;
	var columns = renderData.columns;
	var options = renderData.options;
	var autoColumns = renderData.autoColumns;

	this._columns = columns;
	this._row = renderData.row;

	if(!_.isObject(options)) {
		options = {};
	}

	// For simple arrays, convert to table with empty string as column header
	if(_.isArray(data)) {
		_.forEach(data, function(item, i) {
			if(!_.isObject(item)) {
				data[i] = {' ': item};
			}
		});
	}

	this._dataTable = new DataTable(data, columns, autoColumns);

	// Transform data
	var jqxData = this._dataTable.generateTableDataForJqxTable();
	this._data = jqxData;

	// Data formats
	var defs = this._createJqxColumnDefinitions(this._dataTable, _.bind(self._cellrenderer, self));

	// Prepare data source
	var source = {
		localData: this._data,
		dataType: "json",
		datafields: defs.datafields
	};
	var dataAdapter = new $.jqx.dataAdapter(source);
	dataAdapter.dataBind();

	// Create containing div
	var div = $('<div class="flexbox flex-column">');
	div.addClass('TableViewRenderer');

	// Selection mode
	var selectionMode = options.selectionMode || "multipleRows";

	// Render table div
	var tablediv = $('<div class="flex content">');
	div.append(tablediv);

	// Default table options
	this._defaultOptions = {
		source: dataAdapter,
		columns: defs.columns,
		width: '100%',
		height: '100%',
		sortable: true,
		columnsResize: true,
		selectionMode: selectionMode,
		pageable: false,
		enableBrowserSelection: true
	};
	var jqxOptions = _.clone(this._defaultOptions);

	// Overwrite options with user input
	var fixedOptions = ['source', 'columns'];
	for(var i in options) {
		if(fixedOptions.indexOf(i) < 0) { // only non-fixed options can be overwritten
			jqxOptions[i] = options[i];
			delete options[i];
		}
	}

	var table;
	var initialized = false;
	var i = 0;
	while(!initialized && i++ < 500) { // try to set options (with maximum of 500 failed options)
		try {
			this._table = $('<div>'); // create new element, if there was a previous, it has been corrupted
			// Try to set up jqxDataTable with given options
			table = this._table.jqxDataTable(jqxOptions);
			initialized = true;
		}
		catch (err) {
			// If jqx complains, remove responsible option
			var retry = true;
			var match = /invalid property '(.*)'$/.exec(err);
			if(match === null || !_.has(jqxOptions, match[1])) {
				retry = false;
			} else {
				var prop = match[1];
				this._options[prop] = jqxOptions[prop];
				delete(jqxOptions[prop]);
			}

			if(!retry) {
				this._table = $('<div>'); // create new element, previous was corrupted
				table = this._table.jqxDataTable(this._defaultOptions);
				log.error("Error setting jqxDataTable options: ", err);
				initialized = true;
			}
		}
	}
	tablediv.append(this._table);
	this._jqxOptions = jqxOptions;

	this._setupEvents(table);

	this._table = table;

	return div;
};

TableViewRenderer.prototype.doResize = function(width, height) {
	this._table.jqxDataTable('resize');
};

/**
 * @override
 */
TableViewRenderer.prototype.doUpdate = function(model, changes) {
	var self = this;

	if(!('data' in changes || 'columns' in changes)) {
		// nothing to update
		return;
	}

	// Replace data
	this._dataTable = new DataTable(model.data, model.columns);
	var newData = this._dataTable.generateTableDataForJqxTable();
	this._data.length = 0;
	_.forEach(newData, function(row) {
		self._data.push(row);
	});

	// Re-create definitions, adapter and re-render table. Otherwise table would structure with initial defs only and break if initial is empty
	// Data formats
	const defs = this._createJqxColumnDefinitions(this._dataTable, _.bind(self._cellrenderer, self));

	const source = {
		localData: this._data,
		dataType: "json",
		datafields: defs.datafields
	};
	const dataAdapter = new $.jqx.dataAdapter(source);
	dataAdapter.dataBind();

	_.extend(this._defaultOptions, {
		source: dataAdapter,
		columns: defs.columns
	});
	const jqxOptions = _.clone(this._defaultOptions);

	this._table.jqxDataTable(jqxOptions);
};

/* Private */

TableViewRenderer.prototype._getKeyPressed = function(event) {
	// Using === true for explicit boolean (undefined will become 'false')
	return {
		ctrl: _.get(event, 'ctrlKey') === true,
		meta: _.get(event, 'metaKey') === true,
		shift: _.get(event, 'shiftKey') === true
	};
};

TableViewRenderer.prototype._setupEvents = function(table) {
	var self = this;

	var rowClickTimeout = null;
	var lastClickedRowUID = null;

	var setLastClickedRowId = function(rowId) {
		lastClickedRowUID = rowId;
	};

	var isLastClickedRowId = function(rowId) {
		return lastClickedRowUID === rowId;
	};

	var clearRowClickTimeout = function() {
		if (! rowClickTimeout) {
			return false;
		}

		setLastClickedRowId(null);
		clearTimeout(rowClickTimeout);
		rowClickTimeout = null;

		return true;
	};


	// Row click event
	table.on('rowClick', function(event) {
		var eventData = _.clone(event.args.row);
		delete eventData.uid;
		// Left Click, send row data with click event
		if (event.args.originalEvent.button == 0) {
			//If fast clicking between rows normal click must be triggered
			if (! isLastClickedRowId(event.args.row.uid)) {
				clearRowClickTimeout();
			}
			setLastClickedRowId(event.args.row.uid);

			// This event is triggered on mouse down. To prevent rowclick when selecting text, save the clicked row for
			// when the click is completed (jQuery click event).
			self._clickedRow = eventData;
			// Let jqxDataTable first update its selection
			setTimeout(function() {
				self.updateState(); // update selection in model
			},0);
		}
		// Right click, open context menu
		else if (event.args.originalEvent.button == 2) {
			var x = event.args.originalEvent.pageX;
			var y = event.args.originalEvent.pageY;
			self.openContextMenu('row', eventData, x, y);
			event.preventDefault();
		}
	});

	// Row double-click event
	table.on('rowDoubleClick', function(event) {
		var eventData = _.clone(event.args.row);
		delete eventData.uid;
		// Left Click, send row data with click event
		if (event.args.originalEvent.button == 0) {
			self.trigger({
				type: "rowDoubleClick",
				data: eventData,
				keyPressed: self._getKeyPressed(event)
			});
		}
	});

	// The actual left-click (release) event
	table.on('click', 'tbody > tr', function(event) {
		var selection = _.getSelectedText();
		if(_.isString(selection) && selection.length > 0) {
			return;
		}
		// If previous click did not execute (was canceled successfully) it means it is a double-click
		if (clearRowClickTimeout()) {
			return;
		}

		rowClickTimeout = setTimeout(
			function() {
				clearRowClickTimeout();

				if(! _.def(self._clickedRow)) {
					return;
				}
				self.updateState(); // update selection in model
				self.trigger({
					type: "rowClick",
					data: self._clickedRow,
					keyPressed: self._getKeyPressed(event)
				});
				self._clickedRow = null;
			},
			self._rowDoubleClickTimeoutMs
		);

	});

	// Filtering
	table.on('filter', function(event) {
		self.updateState();
	});

	// Disable browser context menu for tableview
	table.on('contextmenu', '.jqx-grid-content > div', function (e) {
		return false;
	});
	table.on('contextmenu', '.context-menu-modal', function (e) {
		return false;
	});
};

TableViewRenderer.prototype._getColumnType = function(columnName) {
	var numeric = true;
	_.find(this._data, function(row) {
		var value = _.get(row, columnName);
		/*
		Numeric strings must:
			- only have numbers
			- not be empty
			- not start with a zero (unless float)
		 */
		numeric = numeric && (
			_.isNumber(value) || (
				_.isNumeric(value)
				&& (""+value) !== parseFloat(value).toString() // 0.5 is fine, 00.5 is not
			)
		);
		return !numeric;
	});
	// Only need distinction between number and string (objects are handled fine when type is string).
	return numeric ? 'number' : 'string';
};

TableViewRenderer.prototype._createJqxColumnDefinitions = function(dataTable, cellsrenderer) {
	var self = this;
	var columns = dataTable.getColumns();
	var orderedColumns = dataTable.getOrderedColumns();

	var colDefs = [];
	var datafields = [];
	_.forEach(orderedColumns, function(columnReference){
		var column = columns[columnReference.identifier];
		var datafield = {name: columnReference.identifier};
		if(_.def(column.type)) {
			datafield.type = column.type;
		} else {
			datafield.type = self._getColumnType(columnReference.identifier);
		}
		datafields.push(datafield);
		var columnDef = {
			text: column.label || self._dataTable.columnLabelFromIdentifier(columnReference.identifier),
			datafield: columnReference.identifier,
			cellsrenderer: cellsrenderer,
			cellClassName: self.getCellClassNameRenderer(columnReference.identifier)
		};

		columnDef = _.extend(columnDef, column);

		colDefs.push(columnDef);

	}, this);

	return {
		columns: colDefs,
		datafields: datafields
	};
};

TableViewRenderer.prototype._getCellClass = function(row, column) {
	var classColumn = _.get(this._columns, column + '.cellClassNameColumn');
	return _.get(this._data, row + '.' + classColumn);
};

TableViewRenderer.prototype._getRowClass = function(row) {
	var classColumn = _.get(this._row, 'rowClassNameColumn');
	return _.get(this._data, row + '.' + classColumn);
};

/**
 * Renders a cell for a jqxDataTable.
 * @param {int} row The row index of the cell.
 * @param {string} column The column name of the cell.
 * @param {mixed} value The value inside the cell.
 * @returns {string} HTML of the rendered cell
 */
TableViewRenderer.prototype._cellrenderer = function(row, column, value) {
	var content = $('<div class="cell-content">');
	var format = _.get(this._columns, 'column.format');

	var rendered;
	var template = _.get(this._columns, [column, 'template']);

	if (_.isString(template)) {
		rendered = Mustache.render(template, {cell: value, row: _.get(this._data, row)});
	}
	else {
		rendered = this._renderValue(value, format);
	}

	content.append(rendered);
	var cellClass = this._getCellClass(row, column);
	if(_.def(cellClass)) {
		content.addClass(""+cellClass);
	}
	var rowClass = this._getRowClass(row);
	if(_.def(rowClass)) {
		content.addClass(""+rowClass);
	}

	return $('<div>').append(content).html();
};

// returns a jqxDataTable column.cellClassName function if cellClassNameColumn is set for a column
TableViewRenderer.prototype.getCellClassNameRenderer = function (columnName) {
	var classColumn = _.get(this._columns, [columnName, 'cellClassNameColumn']);

	if (!classColumn) {
		return undefined;
	}

	return _.bind(this.getCellClassName, this, classColumn);
}

TableViewRenderer.prototype.getCellClassName = function (classColumn, row, column, value, data) {
	var className = _.get(data, classColumn);
	return _.isNil(className) ? '' : className.toString();
}

/**
 * Render a value. If the value is an Object or Array, a table will be rendered.
 * @param {mixed} value
 * @param {string} [format]
 * @returns {mixed} A rendered version of the value.
 */
TableViewRenderer.prototype._renderValue = function(value, format) {
	if(this._isPlainValue(value)) {
		if(_.hasBooleanValue(this._options['escapeHtml'], false)) {
			return value;
		} else {
			var escaped = $("<div>").text(value).html();
			return escaped;
		}
	} else if (value instanceof Date) {
		if(!_.isString(format)) {
			format = dateFormat.masks.isoDateTime;
		}
		return dateFormat(value, format);
	} else {
		return this._renderTable(value);
	}
};

/**
 * Checks if the value can be printed as-is, or if it needs to be displayed inside a table.
 * @param value
 * @returns {boolean}
 * @private
 */
TableViewRenderer.prototype._isPlainValue = function(value) {
	var plainValueObjects = [
			Number
			// add more if necessary
	];
	var isPlainValue = false;
	plainValueObjects.forEach(function(pvo) {
		if(value instanceof pvo) {
			isPlainValue = true;
		}
	});
	return isPlainValue || (
					!_.isObject(value) && !_.isArray(value));
};

/**
 * Render a table of an Object or Array.
 * @param {Object|Array} object
 * @return {jQuery} jQuery object of table.
 */
TableViewRenderer.prototype._renderTable = function(object) {
	var isObject = _.isObject(object) && !_.isArray(object);

	if (_.isCyclic(object)) {
		return '[cyclic object]';
	}

	var table = $('<table>').attr('cellspacing', 0).attr('cellpadding', 0);
	table.addClass('subtable');
	for(var i in object) {
		var row = $('<tr>');

		// Only show keys for object, not arrays
		if(isObject) {
			var keyCell = $('<td>').addClass('key');
			keyCell.html(i);
			row.append(keyCell);
		}

		// Show value
		var valueCell = $('<td>').addClass('value');
		var value = this._renderValue(object[i]);
		valueCell.html(this._renderValue(object[i]));
		row.append(valueCell);

		table.append(row);
	}

	var html = $('<div>').append(table).html(); // get html
	return html;
};

module.exports = TableViewRenderer;
