const _ = require('core/src/utils/legacy');
const $ = require('jquery');
const { isjQuery } = require('client/src/utils/jquery');
const EventInterface = require('core/src/event-interface');
const Function = require('core/src/function');
const log = require('core/src/log').instance("client/ui/renderer");
const Icon = require('client/src/utils/icon').default;

/* Constructor */

const ViewRenderer = function(dependencies) {
	this._contextMenus = {};

	this._eventHandler = new EventInterface();
	this._eventHandler.extend(this);

	this._updateRequests = {};
	this._content = null;
};
ViewRenderer.viewType = undefined;
ViewRenderer.mouse = {
	x: 0,
	y: 0
};

// Prevent browser context menu on context menu modal
$(document).on('contextmenu', '.context-menu', function(e){ return false; });
// Keep track of mouse (for context menu placement)
$(document).on('mousemove', function(e) {
	ViewRenderer.mouse.x = e.pageX;
	ViewRenderer.mouse.y = e.pageY;
});

$(document).on('click', '.togglePassword', function(){
	$(this).toggleClass('show');
	$(this).prev('input:first').attr('type', (_, attr) => attr === 'password' ? 'text' : 'password')
});

/* Statics */

ViewRenderer.Event = {
	TRIGGER: Function.Event.In.TRIGGER,
	TRIGGER_BY_ID: Function.Event.In.TRIGGER_BY_ID
};

/** @deprecated: use Event.TRIGGER instead. */
ViewRenderer.TRIGGER 		= ViewRenderer.Event.TRIGGER; // backward compatibility
/** @deprecated: use Event.TRIGGER instead. */
ViewRenderer.USER_EVENT 	= ViewRenderer.Event.TRIGGER; // backward compatiblity

/* Properties */

ViewRenderer.prototype._isClosed = false;
ViewRenderer.prototype._eventHandler = undefined;
ViewRenderer.prototype._contextMenus = undefined;
ViewRenderer.prototype._disableContextMenu = undefined;
ViewRenderer.prototype._listeners = undefined;
ViewRenderer.prototype._instanceID = undefined;
ViewRenderer.prototype._functionID = undefined;
ViewRenderer.prototype._title = undefined;
ViewRenderer.prototype._viewType = undefined;

/* Methods */

/**
 * For override. Render content.
 * @param {object} renderData
 * @returns {undefined}
 */
ViewRenderer.prototype.doRender = function(renderData) {
	return $('<div>');
};

/**
 * TODO: Make asynchronous, since many renderers use asynchronous libraries
 * Renders content.
 * @param {object} renderData
 * @returns {$}
 */
ViewRenderer.prototype.render = function(renderData) {
	renderData = renderData || {};

	this._title = _.get(renderData.input, 'name');

	var main = $('<div class="prologram-viewrenderer">');
	var content = $('<div class="prologram-viewrenderer-output">');
	main.append(content);

	var rendered = this.doRender(renderData.input);
	content.append(rendered);

	this._disableContextMenu = renderData.disableContextMenu;
	this.createContextMenus(renderData.contextMenus);
	this.renderContextMenus();
	this._content = main;
	this.renderToolbar(renderData.batchTriggers);

	return main;
};

ViewRenderer.prototype.updateCommon = function(updatedRenderData) {
	this.createContextMenus(updatedRenderData.contextMenus);
	this.renderContextMenus();
	this.renderToolbar(updatedRenderData.batchTriggers);
};

ViewRenderer.prototype.createContextMenus = function(contextMenus) {
	this._contextMenus = {};
	this._contextMenuData = contextMenus;
	this.addContextMenus(contextMenus);
}

ViewRenderer.prototype.getContextMenuData = function() {
	return this._contextMenuData;
};

/**
 *
 * @param mutations
 * @param [executeTriggers]
 * @param [sendUpdateEvent]		Ask the Function to send an UpdateEvent, which will call this renderer's update method
 * @return {undefined}
 */
ViewRenderer.prototype.requestUpdate = function(mutations, executeTriggers = true) {
	let id = _.uniqueId('update-');

	return new Promise((resolve, reject) => {
		this._updateRequests[id] = {resolve, reject};

		this.event(Function.Event.In.UPDATE_MODEL, {
			mutations: mutations,
			executeTriggers: executeTriggers,
			updateID: id
		});
	});
};

ViewRenderer.prototype.getTitle = function() {
	return this._title || this._viewType;
};

ViewRenderer.prototype.setViewType = function(type) {
	this._viewType = type;
};

ViewRenderer.prototype.setContainerState = function(property, value) {
	this.requestUpdate({
		container: {
			[property]: value
		}
	}, true);
};

/**
 * For override. Called in update.
 * @param {object} data	 The update data.
 */
ViewRenderer.prototype.doUpdate = function(data, changes, updateID) {
	log.warn("This ViewRenderer has not implemented doUpdate.");
	return true;
};

/**
 * Updates the ViewRenderer with the given data. Calls doUpdate for specific update procedures of subclasses.
 * @param {object} model				 The update data.
 * @param {object} changes
 * @param {object} updateID
 */
ViewRenderer.prototype.update = function(model, changes, updateID) {
	this._title = _.get(model, 'name');

	let updated = false;

	// If there are changes to the model, call renderer's doUpdate
	if(Object.keys(changes).length > 0) {
		this._disableContextMenu = model.disableContextMenu;
		let updated = this.doUpdate(model, changes, updateID);
		if(updated === false) {
			log.warn("Update failed.");
			return false;
		}
	} else {
		log.log("No changes to model. Not re-rendering.");
	}

	// Resolve the update request
	if(updateID in this._updateRequests) {
		this._updateRequests[updateID].resolve({model, changes});
		delete this._updateRequests[updateID];
	}

	return updated;
};

/**
 * Fire event.
 * @param {string} event
 * @param {object} data
 * @returns {undefined}
 */
ViewRenderer.prototype.event = function(event, data) {
	data = _.parsePrimitiveValues(data);
	return this.fire(event, data);
};

/**
 * For override. Finalizes the rendering when its container is ready.
 * @returns {boolean|undefined}
 */
ViewRenderer.prototype.onReady = function() {
	return true;
};

/**
 * Calls the onReady function of the viewrenderer.
 * @returns {undefined}
 */
ViewRenderer.prototype.ready = function() {
	this.onReady();
	// TODO: Should be different type of event (when more types are added and processed by ViewManager and FTI)
	this.trigger({
		type: 'load'
	});
};

/**
 * For override.
 * @returns {boolean}
 */
ViewRenderer.prototype.onClose = function() {
	return true;
};

/**
 * Closes the ViewRenderer and notifies the listeners.
 * @param {string} origin	The origin of the close event.
 */
ViewRenderer.prototype.close = function(origin) {
	if(!this._isClosed) {
		this._isClosed = true;
		this.event(Function.Event.In.CLOSE, origin);
		this.onClose();
		this.removeListeners();

		this.breakDownUI();
	}
};

ViewRenderer.prototype.breakDownUI = function () {
	_.forEach(this._contextMenus, function(menu) {
		menu.close(true);
	});
	this._contextMenus = null;
};

/**
 * Adds a context menu item.
 * @param {string} menu The name of the menu.
 * @param {string} group The name of the group within the menu. Undefined for default.
 * @param {string} action The (human-readable) action to display on the item.
 * @param {function} callback A function that takes the target object as an argument.
 * @returns {undefined}
 */
ViewRenderer.prototype.addContextMenuItem = function(menu, group, action, callback, enable) {
	if(!(this._contextMenus[menu] instanceof ViewRenderer.ContextMenu)) {
		this._contextMenus[menu] = new ViewRenderer.ContextMenu(menu);
	}
	var menuObj = this._contextMenus[menu];
	menuObj.addItem(group, action, callback, enable);
};

ViewRenderer.prototype.addContextMenus = function(menus) {
	if(!_.isObject(menus)) {
		return;
	}
	var self = this;

	_.forEach(menus, function(items, menu) {
		_.forEach(items, function(item) {
			if(_.isObject(item)) {
				self.addContextMenuItem(menu, item.index, item.action, function(target) {
					self.triggerContextMenuItem(item, target);
				}, item.enable);
			} else {
				log.warn("Invalid context menu item.", item);
			}
		});
	});
};

ViewRenderer.prototype.triggerContextMenuItem = function(item, target) {
	this.triggerById(item.id, {
		type: 'context',
		...item,
		target
	});
};

/**
 * Renders the context menus and appends them to the given element.
 * @returns {undefined}
 */
ViewRenderer.prototype.renderContextMenus = function() {
	// Close all current context menus
	_.forEach(this._contextMenus, menu => {
		menu.close();
	});

	for(var i in this._contextMenus) {
		this._contextMenus[i].render();
	}
};

/**
 * Get a context menu by name.
 * @param {string} menu
 * @returns {undefined}
 */
ViewRenderer.prototype.getContextMenu = function(menu) {
	return this._contextMenus[menu];
};

/**
 * Opens the specified context menu, if available.
 * @param {string} menu
 * @param {object} target
 * @param {Number} x If undefined, takes the current mouse X-coordinate on page.
 * @param {Number} y If undefined, takes the current mouse Y-coordinate on page.
 * @returns {Boolean} True if the context menu was available, false if not.
 */
ViewRenderer.prototype.openContextMenu = function(menu, target, x, y) {
	if(_.get(this._disableContextMenu, menu)) return false;

	if(x === undefined) {
		x = ViewRenderer.mouse.x;
	}
	if(y === undefined) {
		y = ViewRenderer.mouse.y;
	}
	if(this._contextMenus[menu] instanceof ViewRenderer.ContextMenu) {
		this._contextMenus[menu].open(target, x, y);
		return true;
	}
	return false;
};

/**
 * Closes the specified context menu, if available and open.
 * @param {string} menu
 * @returns {Boolean} True if the context menu was available and open.
 */
ViewRenderer.prototype.closeContextMenu = function(menu) {
	if(this._contextMenus[menu] instanceof ViewRenderer.ContextMenu) {
		this._contextMenus[menu].close();
		return true;
	}
	return false;
};

/**
 * Renders the toolbar adding buttons for each batch trigger.
 * @param {jQuery} toolbar
 * @param {object} batchTriggers
 * @returns {undefined}
 */
ViewRenderer.prototype.renderToolbar = function(batchTriggers) {
	if (this._toolbar) {
		this._toolbar.remove();
	}

	if(! _.size(batchTriggers)) {
		this._toolbar = null;
		return;
	}

	// Render toolbar
	this._toolbar = $('<div>').addClass('toolbar');
	this.createBatchTriggerButtons(this._toolbar, batchTriggers);
	this._content.prepend(this._toolbar);
};

/**
 * Renders buttons at the top of the View that fire 'batch' triggers.
 * @param {jQuery} toolbar			The element to which to add the batch triggers.
 * @param {array} batchTriggers		Batch triggers definitions.
 */
ViewRenderer.prototype.createBatchTriggerButtons = function(toolbar, batchTriggers) {
	let self = this;

	if(_.isArray(batchTriggers)) {
		let triggers = batchTriggers.sort(function(a, b) {
			return _.compareIndex(a.index, b.index);
		});

		for(let i in triggers) {
			let trigger = triggers[i];
			let $button = $('<button>');
			$button.addClass('batch-action');
			$button.attr('action', trigger.action);

			// Set the button caption
			let caption = trigger.action;

			// If name is specified use that instead of action as the caption
			if (! _.isEmpty(trigger.name)) {
				caption = trigger.name;
			}

			// If icon is specified set it
			let $icon = '';
			if (! _.isEmpty(trigger.icon)) {
				$icon = $('<span>').addClass(Icon.getClass(trigger.icon));
				$button.prepend($icon);
			}

			$button.append(caption);

			// Set Tooltip
			if (trigger.tooltip){
				$button.attr('title', trigger.tooltip);
			}

			// Check if its disabled
			if (_.isBoolean(trigger.enable) && !trigger.enable){
				$button.prop('disabled', true);
			} else {
				// Set style if not disabled
				if(_.isNil(trigger.enable) || trigger.enable) $button.attr('style', trigger.style);
			}

			// Switch style and hover style on hover
			function hoverCallback(button, trigger) {
				return () => button.attr('style', trigger.hoverStyle);
			}
			function hoverOutCallback(button, trigger) {
				return () => button.attr('style', trigger.style);
			}

			$button.hover(hoverCallback($button, trigger), hoverOutCallback($button, trigger));

			(function(trigger){
				$button.on('click', function(){
					self._batchAction(trigger);
				});
			})(trigger);
			toolbar.append($button);
		}
	}
};

/**
 * For override. Should resize the renderered content.
 * @returns {Boolean}
 */
ViewRenderer.prototype.doResize = function(width, height) {
	return true;
};

/**
 * Resizes the rendered content.
 * @returns {Boolean}
 */
ViewRenderer.prototype.resize = function(width, height) {
	return this.doResize(width, height);
};

/**
 * Fire an event that executes triggers from the View.
 * @param data
 */
ViewRenderer.prototype.trigger = function(data) {
	this.event(ViewRenderer.Event.TRIGGER, _.cloneDeep(data));
};

ViewRenderer.prototype.triggerById = function(id, data) {
	this.event(ViewRenderer.Event.TRIGGER_BY_ID, {id, data: _.cloneDeep(data)});
};

/**
 * @deprecated: use .trigger instead
 *
 * Fire Trigger event from renderer
 * @param {object} data
 * @returns {undefined}
 */
ViewRenderer.prototype.userEvent = function(data) {
	this.trigger(data);
};

/**
 * Set the instanceID of the View this ViewRenderer represents.
 * @param instanceID
 * @returns {undefined}
 */
ViewRenderer.prototype.setInstanceID = function(instanceID) {
	this._instanceID = instanceID;
};

/**
 * Set the functionID of the View Function this ViewRenderer represents an instance of.
 * @param functionID
 */
ViewRenderer.prototype.setFunctionID = function(functionID) {
	this._functionID = functionID;
};

/**
 * Get the ID of the Function this ViewRenderer represents an instance of.
 * @returns {undefined|*}
 */
ViewRenderer.prototype.getFunctionID = function() {
	return this._functionID;
};

/**
 * Get the instanceID of the View this ViewRenderer represents.
 * @returns {string}
 */
ViewRenderer.prototype.getInstanceID = function() {
	return this._instanceID;
};

/**	
 * Checks if a path (pathStr) is in the changes object as a key or start of a key
 * @param {object} changesObj Changes object received from Function.update
 * @param {string} pathStr Object path to be checked if it has changed
 * @returns {boolean}
 */
ViewRenderer.prototype.hasChangedIn = _.curry((changesObj, pathStr) => (
		_.has(changesObj, pathStr)
	|| 	_.some(
		changesObj, 
		(val, key) => _.startsWith(key, pathStr)
	)
))

/**
 * Fires the trigger of the batch action specified.
 * @param {object} trigger	Batch trigger object
 * @returns {undefined}
 */
ViewRenderer.prototype._batchAction = function(trigger) {
	if(!_.isObject(trigger)) {
		log.error("Invalid batch trigger. Could not execute.");
		return;
	}

	let event = {};

	// For backwards compatibility with NetworkView and TableView
	if(_.isFunction(this.getBatchSelection)) {
		event.selection = this.getBatchSelection();
	}

	this.triggerById(trigger.id, event);
};

/**************** CONTEXT MENU CLASS ********************/

/**
 *
 * @param {string} name
 * @constructor
 */
ViewRenderer.ContextMenu = function(name) {
	this.name = name;
	this.items = {};
	this.target = null;
};

/* Statics */
ViewRenderer.ContextMenu.GROUP_DEFAULT = 'default';

/* Properties */

ViewRenderer.ContextMenu.prototype.name = undefined;
ViewRenderer.ContextMenu.prototype.items = undefined;
ViewRenderer.ContextMenu.prototype.target = undefined;
ViewRenderer.ContextMenu.prototype.element = undefined;
ViewRenderer.ContextMenu.prototype.modal = undefined;

/* Methods */

ViewRenderer.ContextMenu.prototype.getItem = function(group, label) {
	return this.items[group][label];
};

/**
 *
 * @param group	 The name of the group in which this item will be displayed. Can be used as ordering index.
 * @param label
 * @param callback
 * @returns {ViewRenderer.ContextMenu.Item}
 */
ViewRenderer.ContextMenu.prototype.addItem = function(group, label, callback, enable) {
	var self = this;

	var check = _.validate({
		label: [label, "isString"],
		group: [group, _.isStringOrNumber(group), {default: ViewRenderer.ContextMenu.GROUP_DEFAULT, warn: group !== undefined}]
	}, "Could not add context item.");
	if(!check.isValid()) return null;
	const valid = check.getValue();

	// Create an Item
	var item = new ViewRenderer.ContextMenu.Item(valid.label, callback, enable);
	item.element.on('click', function(){
		if (!item.enable) return;
		item.callback(self.target);
	});

	// Register item
	if(!_.isObject(this.items[valid.group])) {
		this.items[valid.group] = {};
	}
	this.items[valid.group][valid.label] = item;

	return item;
};

/**
 * Displays the context menu with a given target as its subject.
 * @param {object} target
 * @returns {undefined|boolean}
 */
ViewRenderer.ContextMenu.prototype.open = function(target, x, y) {
	if(!isjQuery(this.element)) {
		log.warn("Context menu '" + this.name + "' has not been (properly) rendered yet.");
		return false;
	}

	var self = this;

	if(x === undefined) {
		x = 0;
	}
	if(y === undefined) {
		y = 0;
	}

	this.element.css({
		left: x + 'px',
		top: y + 'px',
		position: 'absolute',
		'z-index': 99999
	});

	var closeMenuOnClick = function(ev) {
		// Close only on left click. Firefox triggers click event for rightclicks too.
		if (ev.button !== 0 || ev.target.classList.value.includes('disabled')) return;
		$(document).off('click', closeMenuOnClick);
		self.close();
	};

	$(document).on('click', closeMenuOnClick);

	$(document).trigger('prologram-context-menu-open');
	$(document).one('prologram-context-menu-open', function() {
		self.close();
	});

	this.target = target;
	$('body').append(this.element);
};

/**
 * Hides the context menu.
 * @param {boolean} [permanent]		If true, the context menu will be destroyed.
 * @returns {undefined|boolean}
 */
ViewRenderer.ContextMenu.prototype.close = function(permanent) {
	if(!isjQuery(this.element)) {
		return false; // nothing to close
	}
	this.target = null;
	if(permanent === true) {
		this.element.remove();
	} else {
		this.element.detach();
	}
};

ViewRenderer.ContextMenu.prototype.render = function() {
	var element = $('<ul>');
	element.addClass('context-menu');
	element.addClass('context-menu-' + this.name);
	element.css('position', 'absolute');

	element.empty();

	var groups = Object.keys(this.items).sort(_.compareIndex);

	// Add all the items to the menu
	for(var i in groups) {
		var group = groups[i];
		for(var action in this.items[group]) {
			element.append(this.items[group][action].element);
		}
	}

	this.element = element;
	return element;
};

/* CONTEXT MENU ITEM CLASS */

ViewRenderer.ContextMenu.Item = function(label, callback, enable = true) {
	this.label = label;
	this.enable = enable;
	this.callback = callback;
	this.element = this.render();
};

/* Properties */

ViewRenderer.ContextMenu.Item.prototype.label = undefined;
ViewRenderer.ContextMenu.Item.prototype.callback = undefined;
ViewRenderer.ContextMenu.Item.prototype.element = undefined;
ViewRenderer.ContextMenu.Item.prototype.enable = undefined;

/* Methods */

ViewRenderer.ContextMenu.Item.prototype.render = function() {
	var element = $('<li>');
	element.addClass('context-menu-item');
	element.html(this.label);
	if (_.isBoolean(this.enable) && !this.enable) element.addClass('disabled');
	return element;
};

module.exports = ViewRenderer;
