exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-grid.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-alert.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-form-control.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-input-group.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-list-group.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-modal.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-table.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!./bootstrap-badge.css"), "");

// Module
exports.push([module.id, ".btn-info {\n    color: #fff;\n    background-color: #17a2b8;\n    border-color: #17a2b8;\n}\n.btn-warning {\n    color: #212529;\n    background-color: #ffc107;\n    border-color: #ffc107;\n}\n.btn-primary {\n\tcolor: #fff;\n    background-color: #007bff;\n    border-color: #007bff;\n}\n.btn-danger {\n\tcolor: #fff;\n    background-color: #dc3545;\n    border-color: #dc3545;\n}\n.btn-success {\n\tcolor: #fff;\n    background-color: #28a745;\n    border-color: #28a745;\n}\n", ""]);

