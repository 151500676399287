'use strict';

const Function = require('core/src/function');
const ViewRenderer = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/networkview/renderer");
const Session = require('client/src/session');
const NetworkView = require('core/src/functions/views/network-view');
const D3Graph = require('client/src/graph/d3-graph');
const GraphStyles = require('core/src/graph/graph-styles').default;
const Registry = require('core/src/registry').default;
const { GraphRegistry, isGraph } = require('core/src/graph/i-graph');
const IGraphileon = require('core/src/i-graphileon').default;
const IAPI = require('core/src/api-client-abstract').default;
const ViewManager = require('client/src/view-manager');
const CodeEvaluator = require('core/src/code-evaluator');
const {GraphFilters} = require('client/src/graph/graph-filters');
const GraphForceParameters = require('client/src/renderers/network-view-renderer/graph-force-parameters');
const Language = require('core/src/language').default;

const { hasChangesInChildren } = require('core/src/utils/changes');
const { isNonEmptyString } = require('core/src/utils');

const _ = require('core/src/utils/legacy');
const {isTrue} = require('core/src/utils/validation');

/* Inheritance and constructor */

const NetworkViewRenderer = function (dependencies) {
	ViewRenderer.call(this);
	this.diagramName = '';

	this.styles = dependencies.get(GraphStyles);
	this.registry = dependencies.get(Registry);
	this.graphileon = dependencies.get(IGraphileon);
	this.api = dependencies.get(IAPI);
	this.viewmanager = dependencies.get(ViewManager);
	this.codeEvaluator = dependencies.get(CodeEvaluator);
	this.language = dependencies.get(Language);

	this.registry.require(GraphRegistry, isGraph);

	this._selection = {
		nodes: [],
		relations: []
	};

	this._filterListener = this.filter.bind(this);
};
NetworkViewRenderer.viewType = 'NetworkView';
NetworkViewRenderer.prototype = Object.create(ViewRenderer.prototype);

NetworkViewRenderer.prototype._store = undefined;
NetworkViewRenderer.prototype._filterListener = undefined;
NetworkViewRenderer.prototype._autoCompleteStatus = true;

NetworkViewRenderer.prototype._selectionPromise = null;

NetworkViewRenderer.prototype.styles = undefined;

NetworkViewRenderer.prototype.setStyles = function(styles) {
	this.graph.resetGraphNodeStyles(styles.node);
	this.graph.resetGraphRelationStyles(styles.relation);
	this.graph.applyGraphStyles();
	this.graph.updateGraph();
};

NetworkViewRenderer.prototype._cleanRelationData = function (relation) {
	var clean = _.cloneDeep(relation);
	delete clean.relationsList;
	return clean;
};

NetworkViewRenderer.prototype.getLayout = function (forceCreate) {
	if (!this.layout || forceCreate) {
		this.layout = $(
			`<div class="network-view-global-container flexbox flex-column">
				<div class="network-view-toolbar"></div>
				<div class="network-view-container flex">
					<div class="network-view-tool-window network-view-filter-window"></div>
					<div class="network-view-tool-window network-view-force-settings-window"></div>
					<div class="svg-container"></div>
					<div class="network-view-zoom-buttons">
						<button data-zoom-factor="1.2"> + </button>
						<button data-zoom-factor="0.8"> - </button>
					</div>
				</div>
			</div>`);

		$('body').append(this.layout);
	}

	return this.layout;
};

NetworkViewRenderer.prototype.getBatchSelection = function() {
	return this.getSelectedNodes();
};

/**
 * @override
 * @param data
 */
NetworkViewRenderer.prototype.trigger = function (data) {
	data._localData = _.cloneDeep(this.graph.data); // add local data to event (such as x,y positions of nodes)
	ViewRenderer.prototype.trigger.call(this, data);
};

NetworkViewRenderer.prototype.triggerById = function(id, data) {
	data._localData = _.cloneDeep(this.graph.data); // add local data to event (such as x,y positions of nodes)
	ViewRenderer.prototype.triggerById.call(this, id, data);
}

NetworkViewRenderer.prototype.getToolbarContainer = function (forceUpdate) {
	if (!this.toolbarContainer || forceUpdate) {
		this.toolbarContainer = this.getLayout().find('.network-view-toolbar');
	}

	if (!this.toolbarContainer.length) {
		console.error('NetworkViewRenderer.getSVGContainer: could not find toolbar container');
		return false;
	}

	return this.toolbarContainer;
};

NetworkViewRenderer.prototype.getZoomButtonContainer = function(forceUpdate) {
	if (!this.zoomButtonContainer || forceUpdate) {
		this.zoomButtonContainer = this.getLayout().find('.network-view-zoom-buttons');
	}

	if (!this.zoomButtonContainer.length) {
		console.error('NetworkViewRenderer.getZoomButtonContainer: could not find zoom button container');
		return false;
	}

	return this.zoomButtonContainer;
}

NetworkViewRenderer.prototype.getFiltersContainer = function (forceUpdate) {
	if (!this.filtersContainer || forceUpdate) {
		this.filtersContainer = this.getLayout().find('.network-view-filter-window');
		//Add close event
		this.filtersContainer.on('click', '[action=close]', _.bind(function () {
			this.hideSubWindows();
		}, this));
	}

	if (!this.filtersContainer.length) {
		console.error('NetworkViewRenderer.getFiltersContainer: could not find filters container');
		return false;
	}

	return this.filtersContainer;
};


NetworkViewRenderer.prototype.getSVGContainer = function (forceUpdate) {
	if (!this.svgContainer || forceUpdate) {
		this.svgContainer = this.getLayout().find('.svg-container');
	}

	if (!this.svgContainer.length) {
		log.error('NetworkViewRenderer.getSVGContainer: could not find svg container');
		return false;
	}

	return this.svgContainer;
};

NetworkViewRenderer.prototype.getNodes = function() {
	return fromGraphId(this.graph.getAllNodes());
};

NetworkViewRenderer.prototype.getRelations = function() {
	return fromGraphId(this.graph.getAllRelationsInternal());
};

NetworkViewRenderer.prototype.getVisibleNodes = function () {
	return fromGraphId(this.graph.getVisibleNodes());
};

NetworkViewRenderer.prototype.getVisibleRelations = function () {
	return fromGraphId(this.graph.getVisibleRelations());
};

NetworkViewRenderer.prototype.getSelectedNodes = function () {
	return fromGraphId(this.graph.getSelectedNodes());
};

NetworkViewRenderer.prototype.getSelectedRelations = function () {
	return fromGraphId(this.graph.getSelectedRelations());
};

NetworkViewRenderer.prototype.createContextMenus = function (contextMenus) {
	ViewRenderer.prototype.createContextMenus.call(this, contextMenus);

	var self = this;

	this.addContextMenuItem('canvas', 100, this.language.translate('Remove selected nodes from view'), function () {
		var selectedNodes = self.getSelectedNodes();
		self.requestUpdate({"_update.remove": {nodes: selectedNodes}});
	});

	this.addContextMenuItem('canvas', 200, this.language.translate('Insert selected nodes'), function () {
		return self.includeAllSelectedNodes();
	});

	this.addContextMenuItem('node', 100, this.language.translate('Remove from view'), function (node) {
		self.requestUpdate( {"_update.remove": {nodes: [node]}});
	});

	this.addContextMenuItem('relation', 100, this.language.translate('Remove from view'), function (relation) {
		self.requestUpdate( {"_update.remove": {relations: [relation]}});
	});
};

NetworkViewRenderer.prototype.includeAllSelectedNodes = function (status) {
	var self = this;
	var allSelectedNodes = [];
	_.forEach(this.registry.get(GraphRegistry), function (graph) {
		var selectedNodes = graph.getSelectedNodes();
		var sn = _.cloneDeep(selectedNodes);
		allSelectedNodes = _.union(allSelectedNodes, sn);
	}, this);

	this.requestUpdate( {
		"_update.add": {
			nodes: self.graph.cleanNodeObjects(allSelectedNodes)
		}
	});
};

NetworkViewRenderer.prototype.autoLayout = function (status) {
	this.graph.switchAutoLayout(status);
	this.setAutoLayoutButtonStatus();

	return true;
};

NetworkViewRenderer.prototype.hideSubWindows = function () {
	this.getFiltersContainer().hide();
	this.getForceParametersContainer().hide();
};

NetworkViewRenderer.prototype.showFilters = function () {
	this.getFiltersContainer().show();
	this.filters.updateFiltersTable();
};

NetworkViewRenderer.prototype.isFiltersVisible = function () {
	if (this.getFiltersContainer().is(':visible')) {
		return true;
	}
	return false;
};

NetworkViewRenderer.prototype.createFilters = function () {
	var container = this.getFiltersContainer(true);
	this.filters = new GraphFilters({
		container: container,
		graph: {
			getNodes: this.getNodes.bind(this),
			getFilteredNodes: this.getVisibleNodes.bind(this),
			getRelations: this.getRelations.bind(this),
			getFilteredRelations: this.getVisibleRelations.bind(this)
		}
	});

	this.filters.on(GraphFilters.Event.FILTER, this._filterListener);
	this.filters.on(GraphFilters.Event.FILTER_RENDERER, this._filterListener);

	if (this.initData.filters) {
		if (_.isString(this.initData.filters)) {
			this.filters.fromJSON(this.initData.filters);
		} else {
			this.filters.setFilters(this.initData.filters);
		}
	}
};

NetworkViewRenderer.prototype.filter = function (visible) {
	const filterModel = this.filters.toSimpleList();
	filterModel[Function.VALUE_OBJECT] = true; // replace all filters instead of merging

	this.graph.updateVisibilities({
		nodes: toGraphId(visible.nodes),
		relations: toGraphId(visible.relations)
	});
	this.requestUpdate({
		filters: filterModel,
		'state.visible': {
			nodes: this.graph.cleanNodeObjects(visible.nodes),
			relations: this.graph.cleanRelationObjects(visible.relations)
		}
	}).catch(e => log.error("Filter update failed.", e));
};

NetworkViewRenderer.prototype.explore = function (nodes) {
	this.event(NetworkView.Event.In.EXPLORE, nodes);
};

NetworkViewRenderer.prototype.createZoomButtons = function() {
	let container = this.getZoomButtonContainer();
	if (!container) {
		return false;
	}

	let self = this;
	container.on('click', 'button[data-zoom-factor]', function(ev) {
		self.graph.zoomTo(self.graph.scale * $(this).attr('data-zoom-factor'));
	})

	if (_.hasBooleanValue(this.initData.viewZoomButtons, true)) {
		container.show();
	} else {
		container.hide();
	}
}

NetworkViewRenderer.prototype.createToolbar = function () {
	var self = this;
	var container = this.getToolbarContainer(true);
	if (!container) {
		return false;
	}

	var addSeparator = false;

	if (_.hasBooleanValue(this.initData.canSwitchZoomToFitStatus, true)) {
		container.append('<button class="network-view-zoom-to-fit radio" title="Zoom to fit"><span class="fa fa-expand-arrows-alt"></span></button>');
		this.setZoomToFitButtonStatus();
		addSeparator = true;
	}

	if (_.hasBooleanValue(this.initData.canSwitchAutoLayoutStatus, true)) {
		container.append('<button class="network-view-auto-layout radio" title="Fix node layout"><span class="fa fa-thumbtack"></span></button>');
		this.setAutoLayoutButtonStatus();
		addSeparator = true;
	}

	if (_.hasBooleanValue(this.initData.canSwitchAutoCompleteStatus, true)) {
		container.append('<button class="network-view-autocomplete radio" title="Auto-complete relations"><span class="fa fa-asterisk"></span></button>');
		this.setAutoCompleteButtonStatus();
		addSeparator = true;
	}

	if (addSeparator) {
		container.append('<span class="button-separator">|</span>');
		addSeparator = false;
	}


	if (_.hasBooleanValue(this.initData.viewFilter, true)) {
		container.append('<button class="network-view-filter" title="Filters"><span class="fa fa-filter"></span></button>');
		addSeparator = true;
	}

	if (addSeparator) {
		container.append('<span class="button-separator">|</span>');
	}

	if (_.hasBooleanValue(this.initData.canSetStyles, true)) {
		container.append('<button class="network-view-style-manager" title="Style"><span class="fa fa-paint-brush"></span></button>');
	}

	if (_.hasBooleanValue(this.initData.canSetForceParameters, true)) {
		container.append('<button class="network-view-force-settings" title="Force layout settings"><span class="fa fa-wrench"></span></button>');
	}

	if (_.hasBooleanValue(this.initData.canDownloadSVG, true)) {
		container.append('<button class="network-view-download-svg" title="Download as SVG"><span class="fa fa-download"></span></button>');
	}

	if (_.hasBooleanValue(this.initData.canSave, true)) {
		container.append('<button class="network-view-save-diagram" title="Save diagram"><span class="fa fa-save"></span></button>');
	}

	if (_.hasBooleanValue(this.initData.viewFilter, true)) {
		container.on('click', '.network-view-filter', function () {
			var status = self.isFiltersVisible();
			self.hideSubWindows();
			if (!status) {
				self.showFilters();
			}
		});
	}

	container.on('click', '.network-view-zoom-to-fit', function () {
		self.graph.autoZoomToFit(!self.graph.getAutoZoomToFitStatus());
	});


	container.on('click', '.network-view-auto-layout', function () {
		let status = !self.graph.getAutoLayoutStatus();
		return self.autoLayout(status);
	});

	container.on('click', '.network-view-autocomplete', function () {
		self.requestUpdate({autoCompleteStatus: !self._autoCompleteStatus});
	});

	container.on('click', '.network-view-save-diagram', function () {
		return self.saveDiagram();
	});

	container.on('click', '.network-view-download-svg', function () {
		return self.saveSVG();
	});

	container.on('click', '.network-view-force-settings', function () {
		if (self.getForceParametersContainer().is(':visible')) {
			self.hideSubWindows();
			return;
		}

		return self.showForceParameters();
	});

	container.on('click', '.network-view-style-manager', () => {
		this.graphileon.executeFunction({
			_functionName: 'NetworkStylesView',
			'$container.height': 1000,
			'$container.width': 500,
			'$container.id': 'myStyling',
			'name': 'My styles'
		});
	});

};

NetworkViewRenderer.prototype.setAutoLayoutButtonStatus = function () {
	var toolbarContainer = this.getToolbarContainer();

	if (toolbarContainer.length < 1) {
		return _.withError('NetworkViewRenderer.setAutoLayoutButtonStatus: cannot find toolbar container');
	}

	var $button = toolbarContainer.find('.network-view-auto-layout');
	if (!$button.length) {
		return false;
	}

	$button.removeClass('active');

	if (!this.graph) {
		return;
	}

	if (!this.graph.getAutoLayoutStatus()) {
		$button.addClass('active');
	}
};

NetworkViewRenderer.prototype.isValidEventDataForGraph = function (eventData) {
	var validNodeDataStructure = {
		nodes: [],
		relations: []
	};

	return _.hasStructure(eventData, validNodeDataStructure);
};

NetworkViewRenderer.prototype.setZoomToFit = function(status) {
	this.graph.autoZoomToFit(isTrue(status));
	this.setZoomToFitButtonStatus(status);
};

NetworkViewRenderer.prototype.setZoomToFitButtonStatus = function (status) {
	status = _.resolve(status, this.graph.getAutoZoomToFitStatus());
	var toolbarContainer = this.getToolbarContainer();

	if (!toolbarContainer.length) {
		return _.withError('NetworkViewRenderer.setZoomToFitButtonStatus: cannot find toolbar container');
	}

	var $zoomToFitButton = toolbarContainer.find('.network-view-zoom-to-fit');

	if (!$zoomToFitButton.length) {
		return false;
	}

	$zoomToFitButton.removeClass('active');

	if (status) {
		$zoomToFitButton.addClass('active');
	}
};

NetworkViewRenderer.prototype.setAutoLayoutButtonStatus = function () {
	var toolbarContainer = this.getToolbarContainer();

	if (toolbarContainer.length < 1) {
		return _.withError('NetworkViewRenderer.setAutoLayoutButtonStatus: cannot find toolbar container');
	}

	var $button = toolbarContainer.find('.network-view-auto-layout');
	if (!$button.length) {
		return false;
	}

	$button.removeClass('active');

	if (!this.graph) {
		return;
	}

	if (!this.graph.getAutoLayoutStatus()) {
		$button.addClass('active');
	}
};

NetworkViewRenderer.prototype.setAutoCompleteButtonStatus = function (status) {
	status = _.resolve(status, this._autoCompleteStatus);
	var toolbarContainer = this.getToolbarContainer();

	if (!toolbarContainer.length) {
		return _.withError('NetworkViewRenderer.setAutoCompleteButtonStatus: cannot find toolbar container');
	}

	var $autoCompleteButton = toolbarContainer.find('.network-view-autocomplete');

	if (!$autoCompleteButton.length) {
		return false;
	}

	$autoCompleteButton.removeClass('active');

	if (status) {
		$autoCompleteButton.addClass('active');
	}
};

NetworkViewRenderer.prototype.saveDiagram = function () {
	var self = this;

	var user;

	if (this.initData.linkDiagramToUser) {
		user = Session.getUserData();
	}

	var hasIdAndStore = function (object) {
		return object.hasOwnProperty('id') && _.isSimpleValue(object.id)
			&& isNonEmptyString(_.get(object, 'meta.store'));
	};

	var toDiagramNode = function (object) {
		return {
			id: _.get(object, 'id'),
			x: _.get(object, 'x'),
			y: _.get(object, 'y'),
			store: _.get(object, 'meta.store'),
		};
	};

	var diagramNodes = _.map(
		_.filter(this.getNodes(), hasIdAndStore),
		toDiagramNode
	);

	var diagramRelations = _.map(
		_.filter(this.getRelations(), hasIdAndStore),
		toDiagramNode
	);

	var parameters = {
		nodes: diagramNodes,
		relations: diagramRelations,
		filters: this.filters.export(),
		userId: _.get(user, 'id'),
		name: this.initData.diagramName
	};

	var storeToSaveTo = _.get(this.initData, 'diagramStore') || _.get(this.initData, 'store') || 'application';
	if (storeToSaveTo) {
		parameters['store'] = storeToSaveTo;
	}

	this.graphileon.executeFunction({
		_functionName: 'SaveDiagram',
		'diagram': parameters,
		'title': 'Save Diagram'
	});
};

NetworkViewRenderer.prototype.saveSVG = function () {

	var getSVGFileName = function () {
		var date = new Date();

		var fileName = 'Graphileon at ' + location.hostname + ' - ' + date.toUTCString() + '.svg';

		return fileName.replace(/\,/, '').replace(/\s/gi, '-');
	};

	var $svg = this.getSVGContainer().find('svg');

	if (!$svg['length']) {
		return _.withError('NetworkViewRenderer: could not find SVG element of network view');
	}

	_.getSVGFileSourceFromElement($svg[0]).done(function (sourceSVG) {
		_.downloadAsFile(getSVGFileName(), sourceSVG);
	});

};

NetworkViewRenderer.prototype.updateStyles = function () {
	var graph = this.graph;
	graph.styles = this.styles;
	graph.applyGraphStyles();
	graph.updateGraph();
};

NetworkViewRenderer.prototype.loadStylesFromNode = function (node) {
	this.styles = this.styles.new();
	this.styles.loadFromStylesNode(node);
	this.updateStyles();
};

NetworkViewRenderer.prototype.loadStylesFromEvent = function (eventData) {
	var self = this;
	var deferred = new $.Deferred();

	eventData = eventData || {};
	let stylesID = eventData.stylesID;
	if(_.isNil(stylesID) && eventData.styles) {
		log.warn("NetworkView: 'styles' parameter is deprecated. Use 'stylesID' instead.");
		stylesID = eventData.styles;
	}
	var check = _.validate({
		stylesID: [
			stylesID,
			_.isStringOrNumber(stylesID),
			"Invalid ID.",
			{
				default: undefined,
				warn: _.def(stylesID)
			}
		]
	}, "Could not load styles.");
	const valid = check.getValue();

	if (!check.isValid() || !_.def(valid.stylesID)) {
		deferred.reject();
		return deferred.promise();
	}

	// Request style from db
	var request = this.api.requestStyleNode(valid.stylesID);
	request.done(function (response) {
		self.loadStylesFromNode(response);
		deferred.resolve(response);
	});
	request.fail(function (response) {
		deferred.reject(response);
	});

	return deferred.promise();
};

NetworkViewRenderer.prototype.maybeTriggerLink = function (node) {
	if (!d3.event.ctrlKey && !d3.event.metaKey) {
		return false;
	}

	var selectedNodes = this.getSelectedNodes();

	if (!_.isArray(selectedNodes) || selectedNodes.length != 1) {
		return false;
	}

	var sourceNode = selectedNodes[0];
	var self = this;

	// Reselect source node, simple click on target node will deselect it
	setTimeout(function () {
		self.graph.selectNodeElement(self.graph.getNodeElement(sourceNode));
	}, 100);

	this.userEvent({
		type: 'link',
		from: sourceNode,
		to: node
	});

	return true;
};


NetworkViewRenderer.prototype.createForceParameters = function () {
	var self = this;

	var container = this.getForceParametersContainer(true);
	var forceParameterValues = {};
	if (this.graph) {
		forceParameterValues = this.graph.getForceParameters();
	}

	if (this['forceParameters']) {
		this.forceParameters.setContainer(container, forceParameterValues);
		return;
	}


	if (this.initData['forceParameters'] && _.isObject(this.initData.forceParameters)) {
		forceParameterValues = _.extend({}, forceParameterValues, this.initData.forceParameters);
	}

	this.graph.setForceParameters(forceParameterValues);

	this.forceParameters = new GraphForceParameters({
		container: container,
		graph: this.graph,
		forceParameters: forceParameterValues,
		onParameterChange: function (parameter, value) {
			var forceParameters = {};
			forceParameters[parameter] = parseFloat(value);
			self.graph.setForceParameters(forceParameters);
		},
		onWindowClose: function () {
			self.hideSubWindows();
		}
	});
};


NetworkViewRenderer.prototype.getForceParametersContainer = function (forceUpdate) {
	if (!this.forceParametersContainer || forceUpdate) {
		this.forceParametersContainer = this.getLayout().find('.network-view-force-settings-window');
	}

	if (!this.forceParametersContainer.length) {
		console.error('NetworkViewRenderer.getforceParametersContainer: could not find force settings container');
		return false;
	}

	return this.forceParametersContainer;
};

NetworkViewRenderer.prototype.showForceParameters = function () {
	this.getForceParametersContainer().show();
};

/**
	 * If selection was changed, an update is sent to the model with the new selection.
	 * This function returns a promise that resolves when the model change was completed, or no update is in progress
	 * @return {*}
	 */
NetworkViewRenderer.prototype.awaitSelectionUpdate = function() {
	if(this._selectionPromise !== null) {
		return this._selectionPromise;
	}
	return Promise.resolve(undefined);
};

NetworkViewRenderer.prototype.initGraph = function () {
	var self = this;

	var graph = new D3Graph({
		container: this.getSVGContainer(),
		styles: this.styles,
		codeEvaluator: this.codeEvaluator,
		hooks: {
			onNodeClick: async function (nodeData) {
				nodeData = fromGraphId(nodeData);
				self.maybeTriggerLink(nodeData);
			},
			onNodeClicked: async function (nodeData) { // use 'clicked' because then the selection will already be updated
				nodeData = fromGraphId(nodeData);

				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				self.userEvent({
					type: 'nodeClick',
					data: nodeData,
					keyPressed: self._getKeyPressed(),
					mouse: ViewRenderer.mouse
				});
			},
			onNodeDoubleClicked: async function (nodeData) {
				nodeData = fromGraphId(nodeData);

				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				if (_.hasBooleanValue(self.initData.explorable, true)) {
					self.explore([nodeData]);
				}
				self.userEvent({
					type: 'nodeDoubleClick',
					data: nodeData,
					keyPressed: self._getKeyPressed(),
					mouse: ViewRenderer.mouse
				});

				try {
					await self.requestUpdate({
						"_update.change": {
							nodes: [{id: nodeData.id, px: nodeData.px, py: nodeData.py, fixed: true}]
						}
					});
				} catch (e) {
					log.warn(e);
				}
			},
			onNodeRightClicked: async function (nodeData, nodeElement) {
				nodeData = fromGraphId(nodeData);

				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				if (!self.openContextMenu('node', nodeData)) {
					self.userEvent({
						type: 'nodeRightClick',
						data: nodeData,
						keyPressed: self._getKeyPressed(),
						mouse: ViewRenderer.mouse
					});
				}
			},
			onRelationClicked: async function (relationData) {
				relationData = fromGraphId(relationData);

				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				self.userEvent({
					type: 'relationClick',
					data: self._cleanRelationData(relationData),
					keyPressed: self._getKeyPressed(),
					mouse: ViewRenderer.mouse
				});

			},
			onRelationDoubleClicked: async function (relationData) {
				relationData = fromGraphId(relationData);

				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				self.userEvent({
					type: 'relationDoubleClick',
					data: self._cleanRelationData(relationData),
					keyPressed: self._getKeyPressed(),
					mouse: ViewRenderer.mouse
				});
			},
			onRelationRightClicked: async function (relationData) {
				relationData = fromGraphId(relationData);

				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				var data = self._cleanRelationData(relationData);
				if (!self.openContextMenu('relation', data)) {
					self.userEvent({
						type: 'relationRightClick',
						data: data,
						keyPressed: self._getKeyPressed(),
						mouse: ViewRenderer.mouse
					});
				}
			},
			onCanvasClicked: async function (clickPosition, d3event) {
				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				self.userEvent({
					type: 'canvasClick',
					position: clickPosition,
					keyPressed: self._getKeyPressed(d3event),
					mouse: ViewRenderer.mouse
				});
			},
			onCanvasDoubleClicked: async function (clickPosition, d3event) {
				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				self.userEvent({
					type: 'canvasDoubleClick',
					position: clickPosition,
					keyPressed: self._getKeyPressed(d3event),
					mouse: ViewRenderer.mouse
				});
			},
			onCanvasRightClicked: async function (clickPosition, d3event) {
				// If selection changed, wait for changes to reach model
				await self.awaitSelectionUpdate();

				if (!self.openContextMenu('canvas', clickPosition)) {
					self.userEvent({
						type: 'canvasRightClick',
						data: self.data,
						position: clickPosition,
						keyPressed: self._getKeyPressed(d3event),
						mouse: ViewRenderer.mouse
					});
				}
			},
			onAutoZoomToFitChanged: async function (currentStatus) {
				try {
					await self.updatingZoomToFit;
					self.updatingZoomToFit = self.requestUpdate({zoomToFitStatus: currentStatus});
				} catch (e) {
					log.error("Could not update Zoom To Fit");
				}
			},
			onSelectionChanged: async function () {
				let selectedNodes = self.getSelectedNodes();
				let selectedRelations = self.getSelectedRelations();
				self._selectionPromise = self.requestUpdate({
					state: {
						selected: {
							nodes: self.graph.cleanNodeObjects(selectedNodes),
							relations: self.graph.cleanRelationObjects(selectedRelations)
						}
					}
				});
				await self._selectionPromise;
				self._selectionPromise = null;
			},
			onNodesFixed: function (nodes, fixed) {
				nodes = fromGraphId(nodes);

				let cleanNodes = _.map(nodes, node => {
					let cleanNode = self.graph.cleanNodeObject(node);
					if(fixed) {
						cleanNode.px = node.px;
						cleanNode.py = node.py;
					}
					return cleanNode;
				});
				self.requestUpdate({
					autoLayoutStatus: undefined,
					// just update the px,py values, keep other properties set on the nodes (visible, selected)
					'_update.merge.nodes': cleanNodes
				});
			},
			onItemsLoaded: function (items) {

			},
			onItemsAdded: function (items) {

			},
			onItemsUpdated: function (items) {

			},
			onItemsRemoved: function (items) {

			},
			onCanvasPanned: function () {
				self.graph.autoZoomToFit(false);
			}
		}
	});

	return graph;
};

NetworkViewRenderer.prototype.createGraph = function () {
	this.graph = this.initGraph();

	if (!this.graph.isOk()) {
		return _.withError('NetworkViewRenderer: graph not initialized properly');
	}

	this.graph.autoZoomToFit(this.initData.zoomToFitStatus);
};

NetworkViewRenderer.prototype.setupUI = function () {
	this.createToolbar();
	this.createFilters();
	this.createForceParameters();
	this.createZoomButtons();
};

NetworkViewRenderer.prototype.recycleGraph = function (eventData) {
	changeModelToGraphId(eventData);

	this.initData = eventData;

	this.graph.deselectAll();

	this.graph.updateGraphData({
		nodes: eventData.nodes,
		relations: eventData.relations
	});

	if (_.has(eventData, 'autoLayoutStatus')) {
		this.graph.switchAutoLayout(eventData.autoLayoutStatus);
	}

	this.graph.applyGraphStyles();
	this.graph.updateGraph();
};

NetworkViewRenderer.prototype.onClose = function () {
	this.registry.remove(GraphRegistry, this);
};

NetworkViewRenderer.prototype.validateRenderData = function (eventData) {
	var check = _.validate("NetworkViewRenderer renderdata", {
		nodes: [eventData.nodes, 'isArray', {default: [], warn: _.def(eventData.nodes)}],
		relations: [eventData.relations, 'isArray', {default: [], warn: _.def(eventData.relations)}],
		store: [eventData.store, 'isString', {default: undefined, warn: _.def(eventData.store)}],
		styles: [eventData.styles, _.isStringOrNumber, {
			default: undefined,
			warn: _.def(eventData.styles)
		}],
		filters: [eventData.filters, 'isObject', {
			default: undefined,
			warn: _.def(eventData.filters)
		}],
		state: [eventData.state, 'isObject'],
		autoCompleteStatus: [eventData.autoCompleteStatus, 'isBooleanParsable', {
			default: true,
			warn: _.def(eventData.autoCompleteStatus)
		}]
	}, "Invalid NetworkView render data.");
	if(!check.isValid()) return false;

	return check.getValue();
};

/**
	 * This method initializes the Graph and everything that will be fixed for the lifetime of the NetworkViewRenderer.
	 * @param eventData
	 */
NetworkViewRenderer.prototype.doRender = function (eventData) {
	var validEvent = this.validateRenderData(eventData);
	if (!validEvent) return;

	// Overwrite eventData with valid values
	for (var prop in validEvent) {
		eventData[prop] = validEvent[prop];
	}

	// Set store
	this._store = eventData.store;

	this.initData = eventData;

	if (!(this.graph instanceof D3Graph)) {
		// Graph was not created before
		this.createGraph(eventData);
	} else {
		// In case of recycling, re-use
		var oldSVGContainer = this.getSVGContainer();
		oldSVGContainer.detach();
		this.getLayout(true);
		var newSVGContainer = this.getSVGContainer(true);
		newSVGContainer.replaceWith(oldSVGContainer);
		this.getSVGContainer(true); // update SVG container

		this.recycleGraph(eventData);
	}

	this.registry.add(GraphRegistry, this);

	this.setupUI();
	this.loadStylesFromEvent(eventData);

	this.doUpdate(eventData);

	return this.getLayout();
};

/**
	 * This methods sets data, filters and other variables that can change over the lifetime of the NetworkViewRenderer.
	 * @param model
	 * @param changes
	 * @returns {*}
	 */
NetworkViewRenderer.prototype.doUpdate = function (model, changes) {
	var validModel = this.validateRenderData(model);
	if (!validModel) {
		return _.withError('doUpdate: model data not valid', model);
	}

	this.autoLayout(model.autoLayoutStatus);
	this._autoCompleteStatus = validModel.autoCompleteStatus;
	this.setAutoCompleteButtonStatus();
	this.setZoomToFit(model.zoomToFitStatus);

	changeModelToGraphId(validModel);

	this.graph.updateGraphData({
		nodes: validModel.nodes,
		relations: validModel.relations
	});

	if(hasChangesInChildren(changes, 'filters')) {
		this.filters.loadFromSimpleList(validModel.filters);
	}

	if(hasChangesInChildren(changes, 'state.visible')) {
		this.graph.updateVisibilities(validModel.state.visible);
	}

	this.filters.updateAutoFilters();
	this.filters.updateInterface();

	if (this.filters.filterGraph()) {
		// If items are filtered in graph a new NetworkViewRenderer.doUpdate will be issued
		// through the NetworkViewRenderer.filter method and we will revisit the rest later
		return;
	}

	this.graph.updateGraph();

	// This comes after applyGraphStyles because it changes the .style property of the nodes, and would be overwritten
	// by applyGraphStyles otherwise.
	if(hasChangesInChildren(changes, 'state.selected') || hasChangesInChildren(changes, 'state.visible')) {
		this.updateSelection(validModel.state.selected);
	}

	this.registry.notifyChange(GraphRegistry, this);
};

NetworkViewRenderer.prototype.updateSelection = function (selected) {
	var self = this;
	this.graph.deselectAll();

	var check = _.validate({
		selected: [selected, 'isObject']
	});
	if (!check) return false;
	const valid = check.getValue();

	if (_.isArray(valid.selected.nodes)) {
		_.forEach(valid.selected.nodes, function (node) {
			var nodeElement = self.graph.getNodeElement(node);
			if (nodeElement === undefined) {
				log.warn("Selected node could not be found.", node);
				return;
			}
			self.graph.selectNodeElement(nodeElement);
		});
	}
	if (_.isArray(valid.selected.relations)) {
		_.forEach(valid.selected.relations, function (rel) {
			var relElement = self.graph.getRelationElement(rel);
			if (relElement === undefined) {
				log.warn("Selected relation could not be found.", rel);
				return;
			}
			self.graph.selectRelation(relElement);
		});
	}
};

NetworkViewRenderer.prototype._getKeyPressed = function (d3event) {
	if (!_.def(d3event)) d3event = d3.event;

	// Using === true for explicit boolean (undefined will become 'false')
	return {
		ctrl: _.get(d3event, 'ctrlKey') === true,
		meta: _.get(d3event, 'metaKey') === true,
		shift: _.get(d3event, 'shiftKey') === true
	};
};

function changeModelToGraphId(model) {
	model.nodes = toGraphId(model.nodes);
	model.relations = toGraphId(model.relations);
	model.state.selected.nodes = toGraphId(model.state.selected.nodes);
	model.state.selected.relations = toGraphId(model.state.selected.relations);
	model.state.visible.nodes = toGraphId(model.state.visible.nodes);
	model.state.visible.relations = toGraphId(model.state.visible.relations);
}

/**
 * Disambiguates entities with the same ID from different stores by concatenating store and id into a new id.
 */
function toGraphId(entity) {
	if(_.isArray(entity)) {
		return _.map(entity, toGraphId);
	}

	/*
	Concatenate store and id into a new ID, only to be used in D3Graph.
	Store the original values in the `meta` object (which is included in D3Graph's `toSimpleNode`/`toSimpleRelation`
	methods, so that we can retrieve the original IDs later in fromGraphId.
	 */

	const converted = {...entity};
	if(!('meta' in converted)) {
		converted.meta = {}
	};

	if('source' in converted) {
		converted.meta._source = converted.source;
		converted.source = _.get(converted, 'meta.store') + '_' + converted.source;
	}
	if('target' in converted) {
		converted.meta._target = converted.target;
		converted.target = _.get(converted, 'meta.store') + '_' + converted.target;
	}
	converted.meta._id = converted.id;
	converted.id = _.get(converted, 'meta.store') + '_' + converted.id;

	return converted;
}

/**
 * Converts disambiguated entities back to their original state
 */
function fromGraphId(entity) {
	if(_.isArray(entity)) {
		return _.map(entity, fromGraphId);
	}

	const converted = {...entity, meta: {...entity.meta}};
	if(_.has(converted, 'meta._source')) {
		converted.source = entity.meta._source;
		delete converted.meta._source;
	}
	if(_.has(entity, 'meta._target')) {
		converted.target = entity.meta._target;
		delete converted.meta._target;
	}
	converted.id = converted.meta._id;
	delete converted.meta._id;

	return converted;
}

module.exports = NetworkViewRenderer;
