const VueRenderer = require('client/src/renderers/vue-renderer');
const ChartJs = require('./chartjs.vue').default;

'use strict';

const ChartJsViewRenderer = function (dependencies) {
	VueRenderer.call(this, dependencies);
};
ChartJsViewRenderer.viewType = 'ChartJsView';
ChartJsViewRenderer.prototype = Object.create(VueRenderer.prototype);

ChartJsViewRenderer.prototype.getVueComponent = function() {
	return ChartJs;
};

module.exports = ChartJsViewRenderer;