
import VueRendererComponent from "client/src/renderers/vue-components/vue-renderer-component.vue";
import "smart-webcomponents/source/modules/smart.dockinglayout.js";
import "smart-webcomponents/source/styles/smart.default.css";

window.Smart.License = "8414516F-15A2-4D84-A7AF-A9A72400DB02";

export default {
  name: "layout",
  mixins: [VueRendererComponent],
  data() {
    return {
      actions: null,
      docking: null,
      layout: null,
      options: null,
      layoutId:  _.uniqueId('lw')
    }
  },
  methods: {
    parseLayout(layout) {
      let result = _.cloneDeep(layout);
      const iterate = (obj) => {
        Object.keys(obj).forEach(key => {

          if (key === 'items') {
            obj.items = _.map(obj.items, (value, key) => {
              _.set(value, 'id', key);
              return value;
            });
          }

          if (_.isObject(obj[key]) && !_.isNil(obj[key])) {
              iterate(obj[key])
          }
        })

        const area = _.get(obj, 'area')

        if (area && !document.getElementById(area)) {
          const div = this.createWrapper(area)
          obj.content = div;
        }
      }

      iterate(result);
      result = _.map(result);

      return result;
    },
    actionUpdateTab(action) {
      const panels = this.docking.items;
      const panel = _.filter(panels, (panel) => {
        return panel.getAttribute('id') == action.element;
      })
      if (!panel[0]) {
        return;
      }
      let triggerType;
      _.map(action.data.tabs, (value, key) => {
        const tab = this.docking.querySelector(`smart-tab-item[id=${key}]`)
        const data = _.cloneDeep(value);
        data.id = key;
        if (!tab) {
          // CREATE TAB
          // prepare content
          if (!_.isNil(data.area)) {
            const div = this.createWrapper(key)
            data.content = div;
          }

          // insert tab
          panel[0].$tabsElement.element.insert(999, data);
          // set new tabs id
          const tabs = panel[0].$tabsElement.element.getTabs();
          _.map(tabs, (tab) => {
            if (tab.getAttribute('label') === data.label) {
              tab.setAttribute('id', data.id);
            }
          })
          // select last added tab
          if (data.selected) {
            panel[0].select(999);
          }
          triggerType = 'tabCreated';
        } else {
          // UPDATE TAB
          const tabsContainer = tab.closest("smart-tabs");
          const tabs = tabsContainer.getTabs();
          _.map(tabs, (tab) => {
            if (tab.getAttribute('id') === data.id) {
              const index = tab.getAttribute('index');
              tabsContainer.update(index, data.label);
              if (data.selected) {
                tabsContainer.select(index);
              }
            }
          })
          triggerType = 'tabUpdated';
        }

        this.updateLayout({
          type: 'updateTab',
          id: key,
          panel: action.element,
          value,
        });

        this.trigger({
          type: triggerType,
          data: {
            panel: action.element,
            value
          }
        });
      });
    },
    updateLayout(data) {
      const update = (layout, updateType) => {
        let result;
        const iterate = (obj) => {
          Object.keys(obj).forEach(key => {
  
            if (updateType === 'updateTab') {
              if (key === data.panel) {
                const final = _.merge(obj[key].items[data.id], data.value)
                _.set(obj[key].items, data.id, final)
                result = final;
              }
            }

            if (updateType === 'removeTab') {
              if (key === data.id) {
                result = obj[key];
                _.unset(obj, data.id)
              }
            }
  
            if (_.isObject(obj[key]) && !_.isNil(obj[key])) {
                iterate(obj[key])
            }
          })
        }

        iterate(layout);
        return result;
      }
      
      const result = update(this.layout, data.type)
      this.requestUpdate({layout: this.layout});

      return result;
    },
    triggerTabSelected(tabContainer, index) {
      const tabs = tabContainer.getTabs();
      const tab = tabs[index];
      const id = tab.getAttribute('id');
      const panel = tabContainer.closest("smart-tabs-window").getAttribute('id');

      const getSelectedItem = (id) => {
        let result;
        const iterate = (obj) => {
          Object.keys(obj).forEach(key => {

            if (key === id) {
              result = obj[key];
            }

            if (_.isObject(obj[key]) && !_.isNil(obj[key])) {
                iterate(obj[key])
            }
          })
        }

        iterate(this.layout);
        return result;
      }


      const selectedItem = getSelectedItem(id);
      if (selectedItem) {
        this.trigger({
          type: 'tabSelected',
          data: {
            panel,
            value: selectedItem
          }
        });
      }
    },
    createWrapper(id) {
      let div = document.createElement('div');
      div.classList.add("smart-prologram-container-wrapper")
      div.setAttribute("id", id);
      div.setAttribute("role", "view-area")
      this.resizeContent(div);

      return div;
    },
    resizeContent(div) {
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList") {
            const prologramContainer = div.getElementsByClassName("prologram-container");
            if (prologramContainer[0]) {
              const height = _.get(prologramContainer[0].style, 'height');
              const width = _.get(prologramContainer[0].style, 'width');
              
              if (!height && height !== 'inherit') {
                prologramContainer[0].style.height = 'inherit';
              }
              if (!width && width !== 'inherit') {
                prologramContainer[0].style.width = 'inherit';
              }
            }
          }
        }
      }

      const observer = new MutationObserver(callback);
      observer.observe(div, {childList: true});
    },
    applyDefaults() {
      this.docking.animation = 'none';
      this.docking.style.overflow = 'hidden';
    },
    applyOptions() {
      if (!this.options) {
        return;
      }

      if (this.options.hideSplitterBars) {
        this.docking.hideSplitterBars = true;
      }

      if (this.options.hideLayoutBorder) {
        this.docking.style.border = 'none';
      }

      if (this.options.hideTabBorders) {
        const windows = this.docking.querySelectorAll('smart-tabs-window');
        _.forEach(windows, (window) => {
          window.style.border = 'none';
        })
      }

    }
  },
  watch: {
    'actions': {
      deep: true,
      handler: function (actions) {
        if (_.isNil(actions)) {
          return;
        }

        _.map(actions, (action) => {
          if (action.type === 'update') {
            this.actionUpdateTab(action);
          }
        })

        this.requestUpdate({actions: null})
      }
    }
  },
  mounted() {
    const docking = document.querySelector(`smart-docking-layout[layoutId=${this.layoutId}]`);
    this.docking = docking;
    const parsedLayout = this.parseLayout(this.layout);
    this.docking.layout = parsedLayout;

    this.applyDefaults();
    this.applyOptions();

    const tabContainers = this.docking.querySelectorAll("smart-tabs");
    let closingTabs;
    let currentSelectedIndex;

    _.map(tabContainers, (tabContainer) => {
      tabContainer.addEventListener('closing', async (event) => {
        closingTabs = tabContainer.getTabs();
        _.map(closingTabs, (tab) => {
          if (tab.hasAttribute('selected')) {
            currentSelectedIndex = tab.getAttribute('index');
          }
        })
      })

      tabContainer.addEventListener('close', async (event) => {
        event.preventDefault();
        let closedIndex = event.detail.index;
        const id = closingTabs[closedIndex].getAttribute('id');
        let indexToBeSelected;
        
        const removedItem = this.updateLayout({type: 'removeTab', id});
        const panel = tabContainer.closest("smart-tabs-window")
        const panelID = panel.getAttribute('id');

        let triggerTabSelected;
        if (currentSelectedIndex == closedIndex) {
          indexToBeSelected = closedIndex == 0 ? 0 : closedIndex - 1;
          triggerTabSelected = true;
        } else if (currentSelectedIndex > closedIndex) {
          indexToBeSelected = currentSelectedIndex - 1;
          triggerTabSelected = false;
        } else {
          indexToBeSelected = currentSelectedIndex;
          triggerTabSelected = false;
        }

        this.trigger({
          type: 'tabClosed',
          data: {
            panel: panelID,
            value: removedItem
          }
        });

        const isLastTab = tabContainer.getTabs().length === 0 ? true : false;

        if (isLastTab) {
          this.trigger({
            type: 'lastTabClosed',
            data: {
              panel: panelID,
              value: removedItem
            }
          });
        }

        if (!isLastTab) {
          panel.select(indexToBeSelected);
          if (triggerTabSelected) {
            this.triggerTabSelected(tabContainer, indexToBeSelected)
          }
        }

      })

      tabContainer.addEventListener('change', async (event) => {
        const index = event.detail.index;
        this.triggerTabSelected(tabContainer, index);
      })
    })
  },
};
