const ViewRenderer = require('client/src/view-renderer');
const DataTable = require('core/src/data-table');
const log = require('core/src/log').instance("function/chatviewrenderer");

const _ = require('core/src/utils/legacy');
const $ = require('jquery');

/**
 * ChartViewRenderer
 * @constructor
 */
const ChartViewRenderer = function() {
	ViewRenderer.call(this);
};
ChartViewRenderer.viewType = 'ChartView';
ChartViewRenderer.prototype = Object.create(ViewRenderer.prototype);


/* Statics */

ChartViewRenderer._packages = {
	AnnotationChart: 'annotationchart',
	Calendar: 'calendar',
	GeoMap: 'geomap',
	GeoChart: 'geochart',
	Gantt: 'gantt',
	Gauge: 'gauge',
	Map: 'map',
	OrgChart: 'orgchart',
	// Sankey: 'sankey', // does not play nice with D3
	Table: 'table',
	Timeline: 'timeline',
	TreeMap: 'treemap',
	WordTree: 'wordtree'
};
ChartViewRenderer.getPackage = function(chart) {
	if(chart in ChartViewRenderer._packages) {
		return ChartViewRenderer._packages[chart];
	} else {
		return 'corechart';
	}
};

ChartViewRenderer._lock = false;
ChartViewRenderer.apiLoaded = false;
ChartViewRenderer.onLoadCallbacks = [];
ChartViewRenderer.loadAPI = function(callback) {
	var CVR = ChartViewRenderer;

	// Get required packages
	var packages = [];
	for (var key in ChartViewRenderer._packages) {
		packages.push(ChartViewRenderer._packages[key]);
	}

	// If library already loaded, callback
	if(CVR.apiLoaded === true) {
		return callback(google.visualization);
	}

	// Add callback to onLoadCallbacks
	CVR.onLoadCallbacks.push(callback);

	// If not loaded, load packages (only one instance may do so).
	if(!this._lock) {
		this._lock = true;

		google.charts.load("visualization", "1", {packages:packages, callback: function() {
			CVR.apiLoaded = true;

			// Loaded, execute registered callbacks
			_.forEach(CVR.onLoadCallbacks, function(callback) {
				callback(google.visualization);
			});
		}});
	}
};

/* Properties */

ChartViewRenderer.prototype._data = null;
ChartViewRenderer.prototype._options = null;
ChartViewRenderer.prototype._chart = null;
ChartViewRenderer.prototype._chartData = null;
ChartViewRenderer.prototype._dataArray = null;
ChartViewRenderer.prototype._hovering = null;

/* Methods */

ChartViewRenderer.prototype.getChart = function() {
	return this._chart;
};

/**
 * Fired when user selects an element in the chart. Triggers UserEvent.
 * @returns {undefined}
 */
/**
 * Fired when user selects an element in the chart. Triggers UserEvent.
 * @returns {undefined}
 */
ChartViewRenderer.prototype.onSelect = function() {
	let selection = this._chart.getSelection();

	let selectedItem;

	// Some charts have a single selected object (e.g. WordTree); others have an array.
	if(_.isArray(selection)) {
		let row = selection[0].row;
		selectedItem = this._data[row];
	} else {
		selectedItem = selection;
	}

	this.trigger({
		type: 'select',
		data: selectedItem
	});
};

/**
 * Fired when user hovers the cursor over an element in the chart. Used to determine
 * the right-clicked element.
 * @param {type} e
 * @returns {undefined}
 */
ChartViewRenderer.prototype.onMouseOver = function(e) {
	var row = e.row;
	var selectedItem = this._data[row];
	this._hovering = selectedItem;
};

/**
 * Prepares the values of the data array, to match the chart's requirements.
 * @param {array} data Two-dimensional array.
 * @returns {array}
 */
ChartViewRenderer.prototype.cleanData = function(data) {
	// First row are column definitions
	if(_.isArray(data[0])) {
		for(var c in data[0]) {
			var label = data[0][c];
			if(_.isObject(label)) {
				var col = {};
				_.forEach(['label', 'type', 'role'], function(prop) {
					if(_.isString(label[prop])) {
						col[prop] = label[prop];
					}
				});
				data[0][c] = col;
			}
		}
	}
	// First column are row labels, must be strings (except first row with column definitions)
	for(var r = 1; r < data.length; r++) {
		if(data[r][0] !== undefined) {
			data[r][0] = data[r][0]+"";
		}
	}

	// Convert Numbers to numbers
	for(var r in data) {
		for(var c in data[r]) {
			if(data[r][c] instanceof Number) {
				data[r][c] = data[r][c].valueOf();
			}
		}
	}
	return data;
};

ChartViewRenderer.prototype.doRender = function(renderData) {
	var self = this;

	this._data = renderData.data;
	var columns = renderData.column;
	this._options = renderData.options;
	var chartType = renderData.chartType;
	// For backward compatibility
	if (!chartType) chartType = renderData.type;

	var div = $('<div>').addClass('ChartView').addClass(chartType);
	var element = div.get()[0];

	if(window.google === undefined) {
		div.html('Google Charts not loaded.');
		return div;
	}

	var datatable = new DataTable(this._data, columns);
	this._dataArray = datatable.generateTableArray();

	this.cleanData(this._dataArray);

	// Load API
	ChartViewRenderer.loadAPI(function() {
		// Check if chart is available
		if(!google.visualization[chartType]) {
			log.error("Chart type not found.", chartType);
			return;
		}

		// Create Google Chart
		self._chartData = google.visualization.arrayToDataTable(self._dataArray);
		self._chart = new google.visualization[chartType](element);

		// Listen for events
		google.visualization.events.addListener(self._chart, 'error', function(error) {
			log.error("Chart error:", error);
		});
		google.visualization.events.addListener(self._chart, 'select', function() {
			self.onSelect();
		});
		google.visualization.events.addListener(self._chart, 'onmouseover', function(e) {
			self.onMouseOver(e);
		});
		google.visualization.events.addListener(self._chart, 'onmouseout', function(e) {
			self._hovering = null;
		});
		div.on('contextmenu', function() {
			if(self._hovering !== null) {
				self.openContextMenu('chart', self._hovering);
			}
			return false;
		});

		// Draw the chart
		self._chart.draw(
			self._chartData, 
			_.extend(
				{
					width: div.width(),
					height: div.height()
				},
				self._options
			)
		);
	});

	return div;
};

ChartViewRenderer.prototype.doResize = function(width, height) {
	if (! this._chart) {
		return;
	}

	this._chart.draw(
		this._chartData, 
		_.extend({width, height}, this._options)
	);
};

module.exports = ChartViewRenderer;


