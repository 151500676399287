'use strict';

/**
	TODO:
		- init and resize when container = SVG
		- remove node and rel styling from basic graph (updateGraph)
		- add the auto zooming feature that cancels on user zoom from prologram.old

		- rewrite all error returns to be like "return _.withError([errorMessage, var])"
		- rename D3Graph.data into D3Graph.nodes and D3Graph.relations
		- use "relations" instead of "links"
		- D3Graph.visible[] should not exist and should not be used
		- filteredOut flag should not exist and should not be used
		- change node elements classes from node-group to node
		- move style applying from basic D3Graph to ExtendedD3Graph (updateGraph function)
		- remove clippath and empty image structure from initial node structure
		- refactor renderNode and renderRelation
		- add getElementD3Data function (use in selectNodeElement for example)
		- refactor getRelationsWithNodesPointers
		- there are problems with ensureD3selection and isD3Selection (might be empty) and ensured3Element
		- fix use of node and nodeElement use in code
		- refactor selectNodeElement function

	Create a D3 visualization of a neo4j query result

	settings = {
		container,
		SVGElement,
		source, // {nodes:[{id, labels, properties},..], relations:[{id, properties, types, source: {node}, target: {node}}]}
		id, // int with the id graph must have in prologram environment
		autoLayout, // lays nodes automatically
		styles, // {node: [{[selector]: [JSON style definition]}, ..], rel: [{[selector]: [JSON style definition]}]} (for nodes and relations)
		event functions... // onNodeClick, onNodeRightClick, onRelationClick, onCanvasRightClick ...

	}
*/
const d3 = require('d3');
const _ = require('core/src/utils/legacy');
const GraphSelector = require('core/src/graph/graph-selector');
const GraphStyles = require('core/src/graph/graph-styles').default;
const addHooksTrait = require('core/src/hooks');
const Icon = require('client/src/utils/icon').default;

const DOUBLE_CLICK_TIMEOUT_MS = 250;
const PREVENT_DEFAULT_CONTEXT_MENUS = true;
const DO_GRAPH_UPDATE = true;
const NO_GRAPH_UPDATE = false;
const DO_NODES_CHECK = true;
const NO_NODES_CHECK = false;
const DO_RELATIONS_CHECK = true;
const NO_RELATIONS_CHECK = false;
const AUTO_ZOOM_TO_FIT_INTERVAL = 1000; //ms
const DO_UNHIDE_NODES = true;
const NO_UNHIDE_RELATIONS = false;
const DO_UNHIDE_RELATIONS = true;
const NO_NEIGHBOURS_UNHIDE = false;
const NODE_SELECTION_EXTRA_LINEWIDTH = 10;
const REVERSED = true;
const CURVE_FACTOR = [0, 9, 3, 2, 1.5, 1.2, 1, 0.88, 0.8, 0.73, 0.68, 0.64, 0.61, 0.59, 0.57, 0.555, 0.54];
const CURVE_MULTIPLIER = 0.6;

function D3Graph(settings) {
	this.isZoomedToFit = false;
	var result = this.init(settings);

	if (!result) {
		console.error('D3Graph: Error initializing graph');
		this.ok = false;
	}

	this._selection = {
		nodes: [],
		relations: []
	};

	if(_.isFunction(settings.overrideNodeStyle)) {
		this._overrideNodeStyle = settings.overrideNodeStyle;
	}

	this.graphID = _.uniqueId();
	this._destroyed = false;
}

D3Graph.prototype._overrideNodeStyle = undefined;
D3Graph.prototype._selection = undefined;

D3Graph.prototype.settings = {
	autoLayout: true,
	autoZoomToFit: false,
	hooks: {},
	container: null,
	canZoom: true,
	maxZoomScale: 3,
	// NetworkView has its own force parameter defaults, overriding these
	defaultForceParameters: {
		linkDistance: 250,
		linkStrength: 0.7,
		friction: 0.9,
		charge: -1000,
		chargeDistance: 1000,
		theta: 0.8,
		gravity: 0.05
	}
};

D3Graph.prototype.isOk = function() {
	return this.ok;
};

D3Graph.prototype.isValidSettings = function(settings) {
	if (!_.isPlainObject(settings) ||
			(_.has(settings, 'id') && !_.isSimpleValue(settings.id)) ||
			!_.has(settings, 'container')) {
		return false;
	}

	return true;
};

D3Graph.prototype.init = function(settings) {
	if (!this.isValidSettings(settings)) {
		console.error('D3Graph.init: settings are invalid', settings);
		return false;
	}

	this.ok = true;
	this.scale = 1;

	this.settings = _.extend({}, this.settings, settings);

	// All nodes and relations
	this.data = {
		nodes: [],
		relations: []
	};

	this.styles = settings.styles || new GraphStyles();

	this.selectors = {
		node: {},
		relation: {}
	};

	this.hooks = {};

	this.setId(settings.id);

	if (!this.initContainer(settings.container)) {
		return false;
	}

	if (!this.initForce()) {
		console.error('D3Graph.init: Error initializing force');
		return false;
	}

	this.setUpHooks(this.settings.hooks);

	this.autoZoomToFit(this.settings.autoZoomToFit);

	return true;
};

D3Graph.prototype.breakDown = function() {
	var self = this;
	this.data = [];
	this.empty();
	this.updateGraph();

	this.nodeGroups = null;
	this.linkGroups = null;

	this._destroyed = true;
};

D3Graph.prototype.getEventPosition = function(d3Event) {
	var position = {
		clientX: d3Event.clientX,
		clientY: d3Event.clientY,

		layerX: d3Event.layerX,
		layerY: d3Event.layerY,

		screenX: d3Event.screenX,
		screenY: d3Event.screenY,
	};

	var graphClickPosition = this.screenPositionToGraphPosition({
		x: position.clientX,
		y: position.clientY
	});
	position.x = !isNaN(graphClickPosition.x) ? graphClickPosition.x : 0;
	position.y = !isNaN(graphClickPosition.y) ? graphClickPosition.y : 0;

	// backward compatibility
	position.graphX = position.x;
	position.graphY = position.y;

	return position;
};

D3Graph.prototype.initContainer = function(container) {
	var self = this;
	// Check or create main container
	this.container = $(container);

	if (!container.length) {
		console.error('D3Graph.init: could not find container for the graph', container);
		return false;
	}

	// Create SVG container
	this.SVGElement = d3.select(this.container.toArray()[0])
		.append('svg')
		.attr('id', 'svg-' + this.graphID)
		.attr('width', "100%")
		.attr('height', "100%");

	// Set graph clickable background
	this.SVGElement.append('rect')
		.attr('width', '100%')
		.attr('height', '100%')
		.attr('class', 'graph-background')
		.on('click', function(d) {
			var position = self.getEventPosition(d3.event);

			// Don't click if panning, just end panning
			if(self._isPanning) {
				self._isPanning = false;
				return;
			}

			if (self._singleClickTimeout) {
				clearTimeout(self._singleClickTimeout);
			}
			var d3event = d3.event;
			self._singleClickTimeout = setTimeout(function() {
				self.executeEvent('onCanvasClick', 'onCanvasClicked', self, [position, d3event, this]);
				self._singleClickTimeout = undefined;
			}, DOUBLE_CLICK_TIMEOUT_MS);
		})
		.on('dblclick', function(d) {
			if (self._singleClickTimeout) {
				clearTimeout(self._singleClickTimeout);
			}
			var position = self.getEventPosition(d3.event);
			self.executeEvent('onCanvasDoubleClick', 'onCanvasDoubleClicked', self,  [position, d3.event, this]);
		})
		.on('contextmenu', function(d) {
			var position = self.getEventPosition(d3.event);

			d3EventPreventDefault();
			self.executeEvent('onCanvasRightClick', 'onCanvasRightClicked', self, [position, d3.event, this]);
		});

	// This contains all nodes and all relations and is used for zooming and panning the graph
	this.graphContainer = this.SVGElement.append('g').attr('class', 'graph-main-container');

	// Add  link and nodes container (in this order because nodes must be over relations in z-order)
	this.relationsContainer = this.graphContainer.append('g').attr('class', 'graph-relations-container');
	this.nodesContainer = this.graphContainer.append('g').attr('class', 'graph-nodes-container');


	// TODO: this should be moved to another object
	// Initializing vars used in tick, they should be empty selects
	this.nodeGroups = this.nodesContainer.selectAll('g.node-group');
	this.linkTextPaths = this.relationsContainer.selectAll('.link-group .textpath');
	this.relationPaths = this.relationsContainer.selectAll('.link');
	this.relationBorderPaths = this.relationsContainer.selectAll('.link-border');

	this.zoomListener = d3.behavior.zoom()
		.scaleExtent([0.05, 3])
		.on("zoom", function() {
			// if mouse event and right click is pressed prevent zoom event
			if (d3.event.sourceEvent instanceof MouseEvent && (d3.event.sourceEvent.button === 2)) {
				return;
			}
			self.zoomHandler();
		});


	if (this.settings.canZoom) {
		this.enableZoom();
	}

	// Create and set node drag events
	this.nodeDrag = d3.behavior.drag()
		.origin(function(d) { return d; })
		.on("dragstart", function(d) {
			self.dragStart(d);
		})
		.on("drag", function(d) {
			self.drag(d);
		})
		.on("dragend", function(d) {
			self.dragEnd(d);
		});

	return true;
};

D3Graph.prototype.enableZoom = function() {
	// attach zoomHandler to graph
	this.SVGElement.call(this.zoomListener)
		.on("dblclick.zoom", null); // disable double-click zoom
};

D3Graph.prototype.initForce = function() {
	var self = this;
	// Setup the dragging force
	this.force = d3.layout.force().size([this.container.width(), this.container.height()]);

	// Set the tick function
	this.force.on('tick', function() {
		self.tick();
	});

	this.setUpForce();

	return true;
};

D3Graph.prototype.setUpForce = function() {
	this.setForceParameters(this.settings.defaultForceParameters);
};

var validForceParameters = ['linkDistance', 'linkStrength', 'friction', 'charge', 'chargeDistance', 'theta', 'gravity'];

D3Graph.prototype.isValidForceParameter = function(parameter) {

	if (_.includes(validForceParameters, parameter)) {
		return true;
	}

	return false;
};

D3Graph.prototype.setForceParameters = function(params) {
	var self = this;
	/* default force params
			{
				linkDistance: 20,
				linkStrength: 1,
				friction: 0.9,
				charge: -30,
				chargeDistance: Infinity,
				theta: 0.8,
				gravity: 0.1,
			}
		*/

	if (!_.isPlainObject(params)) {
		console.error('D3Graph.setForceParameters: params should be plain object', params);
		return false;
	}

	_.forEach(params, function(value, param) {
		if (self.isValidForceParameter(param)) {
			self.force[param](value);
		}
	}, this);

	this.force.start();
};

D3Graph.prototype.getForceParameters = function() {
	var result = {};
	var self = this;
	_.forEach(validForceParameters, function(param) {
		result[param] = self.force[param]();
	});

	return result;
};

D3Graph.prototype.getId = function() {
	if (!this.id) {
		this.setId();
	}
	return this.id;
};

D3Graph.prototype.setId = function(id) {
	if (id === undefined) {
		id = _.uniqueId();
	}
	this.id = id;

	return this;
};


D3Graph.prototype.empty = function() {
	this.data = {
		nodes: [],
		relations: []
	};
};

D3Graph.prototype.validateItems = function(items) {
	var self = this;
	items = items || {};

	var nodes = [];
	var relations = [];
	if(_.isArray(items.nodes)) {
		_.forEach(items.nodes, function(n) {
			var node = _.clone(n);
			if(!self.isValidNode(node)) {
				return _.withError('Invalid node.');
			}
			if(!_.isObject(node.properties)) {
				node.properties = {};
			}
			nodes.push(node);
		});
		_.forEach(items.relations, function(l) {
			var link = _.clone(l);
			if(!self.isValidRelation(link)) {
				return _.withError('Invalid relation.');
			}
			if(!_.isObject(link.properties)) {
				link.properties = {};
			}
			relations.push(link);
		});
	}
	return {
		nodes: nodes,
		relations: relations
	};
};

// Empties graph and loads items = {nodes: [], relations: []}
D3Graph.prototype.loadItems = function(items) {
	if (this.executeHook('onItemsLoad', [items]) === false) {
		return false;
	}

	var validatedItems = this.validateItems(items);

	validatedItems.relations = getRelationsWithNodesPointers(validatedItems);

	// Empty graph
	this.empty();

	// Set graph data
	this.data = validatedItems;

	this.executeHook('onItemsLoaded', [validatedItems]);

	return;
};

D3Graph.prototype.loadNeo4jQueryResult = function (result) {
	if (!this.isValidNeo4jResult(result)) {
		console.error('D3Graph.loadNeo4jQueryResult: argument is not valid Neo4j query result', result);
		return false;
	}

	return this.loadItems(result.processed);
};

D3Graph.prototype.loadGraph = D3Graph.prototype.loadNeo4jQueryResult;

D3Graph.prototype.updateGraphData = function (newData) {
	var self = this;
	var nodes = _.get(newData, 'nodes');
	var relations = _.get(newData, 'relations');
	if (!_.isArray(nodes)) {
		return _.withError('D3Graph.updateGraphData: newData.nodes is not an array.');
	}
	if (!_.isArray(relations)) {
		return _.withError('D3Graph.updateGraphData: newData.relations is not an array.');
	}

	// Remove items
	var nodeIds = _.map(nodes, 'id');
	var removeNodes = [];
	_.forEach(this.data.nodes, function (node) {
		if (!self.isValidNode(node)) {
			return;
		}
		if (!_.includesLoose(nodeIds, node.id)) {
			removeNodes.push(node);
		}
	});
	var linkIds = _.map(relations, 'id');
	var removeLinks = [];
	_.forEach(this.data.relations, function (link) {
		if (!self.isValidRelation(link)) {
			return;
		}
		if (!_.includesLoose(linkIds, link.id)) {
			removeLinks.push(link);
		}
	});
	this.removeItems({
		nodes: removeNodes,
		relations: removeLinks
	}, false);

	// Add/update items
	this.addItems({
		nodes: nodes,
		relations: relations
	}, false);

	// Items should never be without 'style' property.
	this.applyGraphStyles();
	this.executeHook('onItemsLoaded', [{
		nodes:nodes,
		relations:relations
	}]);
};

D3Graph.prototype.updateVisibilities = function(visible) {
	var self = this;
	var nodes = visible.nodes;
	var relations = visible.relations;

	this.hideNodes(this.getAllNodes(), NO_GRAPH_UPDATE);
	this.hideRelations(this.getAllRelations(), NO_GRAPH_UPDATE);

	_.forEach(nodes, function(node) {
		self.unhideNode(node, NO_GRAPH_UPDATE, NO_UNHIDE_RELATIONS);
	});
	_.forEach(relations, function(relation) {
		self.unhideRelation(relation, NO_GRAPH_UPDATE, false);
	});
	this.updateGraph();
};

D3Graph.prototype.updateNode = function(existingNode, newNodeData) {
	if (! _.isPlainObject(existingNode)) {
		return _.withError('D3Graph.updateNode: existingNode is invalid', existingNode);
	}

	if (! _.isPlainObject(newNodeData)) {
		return _.withError('D3Graph.updateNode: newNodeData is invalid', newNodeData);
	}

	// If not fixed, remove location from newNodeData
	if(!newNodeData.fixed) {
		delete newNodeData.x;
		delete newNodeData.y;
		delete newNodeData.px;
		delete newNodeData.py;
	} else {
		// If fixed, positions cannot be undefined.
		_.forEach(['x', 'y', 'px', 'py'], function(p) {
			if(!_.isNumber(newNodeData[p])) delete newNodeData[p];
		});
	}

	_.forOwn(newNodeData, function(value, key) {
		existingNode[key] = value;
	}, this);

	// Override existing location (if available)
	if(!_.isNumber(newNodeData.px) && _.isNumber(newNodeData.x)) {
		existingNode.px = newNodeData.x;
	}
	if(!_.isNumber(newNodeData.py) && _.isNumber(newNodeData.y)) {
		existingNode.py = newNodeData.y;
	}

	return existingNode;
};

D3Graph.prototype.updateRelation = function(existingRelation, newRelationData) {
	var self = this;
	if (! _.isPlainObject(existingRelation)) {
		return _.withError('D3Graph.updateRelation: existingRelation is invalid', existingRelation);
	}

	if (! _.isPlainObject(newRelationData)) {
		return _.withError('D3Graph.updateRelation: newRelationData is invalid', newRelationData);
	}

	_.forOwn(newRelationData, function(value, key) {
		if (_.includes(['source', 'target'], key) &&_.isNumeric(value)) {
			existingRelation[key] = self.getNodeById(value);
			return;
		}

		existingRelation[key] = value;
	}, this);

	return existingRelation;
};

D3Graph.prototype.toValidNode = function(node) {
	node.x = _.parseNumber(node.x);
	node.y = _.parseNumber(node.y);
	node.px = _.parseNumber(node.px);
	node.py = _.parseNumber(node.py);
	node.properties = _.isPlainObject(node.properties) ? node.properties : {};
	node.labels = _.isArray(node.labels) ? node.labels : [];

	return node;
};

D3Graph.prototype.addNodes = function(nodes) {
	var self = this;
	nodes = _.ensureIsArray(nodes);

	if (this.executeHook('onNodesAdd', [nodes]) === false) {
		return false;
	}

	var nodeIds = _.map(this.data.nodes, 'id');
	var addedNodes = [];

	_.forEach(nodes, function(node) {
		if (!self.isValidNode(node)) {
			return;
		}

		node = self.toValidNode(node);

		if (_.includesLoose(nodeIds, node.id)) {
			var existingNode = self.getNodeById(node.id);
			self.updateNode(existingNode, node);
			self.unhideNodes(existingNode, NO_GRAPH_UPDATE, NO_UNHIDE_RELATIONS);
			return;
		}

		self.data.nodes.push(node);
		addedNodes.push(node);
	}, this);

	this.executeHook('onNodesAdded', [addedNodes]);
};

D3Graph.prototype.addRelations = function(relations) {
	var self = this;
	relations =_.ensureIsArray(relations);

	if (this.executeHook('onRelationsAdd', [relations]) === false) {
		return false;
	}
	relations = getRelationsWithNodesPointers({
		relations: relations,
		nodes: this.data.nodes
	});

	var relationIds = _.map(this.data.relations, 'id');
	var addedRelations = [];

	_.forEach(relations, function(relation) {
		if (!self.isValidRelation(relation)) {
			return;
		}
		if(!_.isObject(relation.properties)) {
			relation.properties = {};
		}
		if (_.includesLoose(relationIds, relation.id)) {
			var existingRelation = self.getRelationById(relation.id);
			self.updateRelation(existingRelation, relation);
			self.unhideRelation(existingRelation, NO_GRAPH_UPDATE, DO_UNHIDE_NODES);
		} else {
			self.data.relations.push(relation);
			addedRelations.push(relation);
		}
	}, this);

	this.executeHook('onRelationsAdded', [addedRelations]);

	return true;
};

D3Graph.prototype.addItems = function(data, executeHooks) {
	if (!_.isPlainObject(data)) {
		return false;
	}

	executeHooks =_.resolve(executeHooks, true);

	if (_.has(data, 'nodes')) {
		this.addNodes(data.nodes, false);
	}

	if (_.has(data, 'relations')) {
		this.addRelations(data.relations, false);
	}

	if(executeHooks) {
		this.executeHook('onItemsAdded', [data]);
	}
	return true;
};

D3Graph.prototype.addData = D3Graph.prototype.addItems;

D3Graph.prototype.updateNodes = function(nodes) {
	var self = this;
	nodes =_.ensureIsArray(nodes);

	if (this.executeHook('onNodesUpdate', [nodes]) === false) {
		return false;
	}

	var nodeIds = _.map(this.data.nodes, 'id');
	var updatedNodes = [];

	_.forEach(nodes, function(node) {
		if (!self.isValidNode(node)) {
			return;
		}
		if (_.includesLoose(nodeIds, node.id)) {
			var existingNode = self.getNodeById(node.id);
			if (self.updateNode(existingNode, node)) {
				updatedNodes.push(existingNode);
			}
		}
	}, this);

	this.executeHook('onNodesUpdated', [updatedNodes]);
};

D3Graph.prototype.updateRelations = function(relations) {
	var self = this;
	relations =_.ensureIsArray(relations);

	if (this.executeHook('onRelationsUpdate', [relations]) === false) {
		return false;
	}
	relations = getRelationsWithNodesPointers({
		relations: relations,
		nodes: this.data.nodes
	});

	var relationIds = _.map(this.data.relations, 'id');
	var updatedRelations = [];

	_.forEach(relations, function(relation) {
		if (!self.isValidRelation(relation)) {
			return  _.withError(['D3Graph.updateRelations: relation is not valid', relation]);
		}
		if (_.includesLoose(relationIds, relation.id)) {
			var existingRelation = self.getRelationById(relation.id);
			if (self.updateRelation(existingRelation, relation)) {
				updatedRelations.push(existingRelation);
			}
		}
	}, this);

	this.executeHook('onRelationsUpdated', [updatedRelations]);

	return true;
};

D3Graph.prototype.updateItems = function(data, executeHooks) {
	if (!_.isPlainObject(data)) {
		return false;
	}
	if (_.has(data, 'nodes')) {
		this.updateNodes(data.nodes, false);
	}

	executeHooks =_.resolve(executeHooks, true);

	if (_.has(data, 'link')) {
		this.updateRelations(data.links, false);
	}

	if (_.has(data, 'relations')) {
		this.updateRelations(data.relations, false);
	}

	if(executeHooks) {
		this.executeHook('onItemsUpdated', [data]);
	}
	return true;
};

D3Graph.prototype.getNodeIndex = function(node) {
	if (!this.isValidNode(node)) {
		return false;
	}

	return _.indexOf(this.data.nodes, node);
};

D3Graph.prototype.getNodeIds = function(nodes) {
	nodes =_.ensureIsArray(nodes);

	return _.map(nodes, 'id');
};

D3Graph.prototype.getAllNodes = function() {
	return this.data.nodes;
};

D3Graph.prototype.getAllRelationsInternal = function() {
	return this.data.relations;
};

D3Graph.prototype.getAllVisibleNodes = function() {
	var self = this;
	var nodes = this.getAllNodes();

	var visibleNodes = _.filter(nodes, function(node) {
		return self.isVisibleNode(node);
	}, this);

	return visibleNodes;
};

D3Graph.prototype.getAllVisibleRelations = function() {
	var self = this;
	var relations = this.getAllRelationsInternal();

	var visibleRelations = _.filter(relations, function(relation) {
		return self.isVisibleRelation(relation);
	}, this);

	return _.map(visibleRelations, relation => this.toSimpleRelation(relation));
};

D3Graph.prototype.getAllVisibleRelationsInternal = function() {
	var self = this;
	var relations = this.getAllRelationsInternal();

	var visibleRelations = _.filter(relations, function(relation) {
		return self.isVisibleRelation(relation);
	}, this);

	return visibleRelations;
};

D3Graph.prototype.isVisibleNode = function(node) {
	return !this.isHiddenNode(node);
};

D3Graph.prototype.isVisibleRelation = function(relation) {
	return !this.isHiddenRelation(relation);
};

D3Graph.prototype.toSimpleRelation = function(internalRelation) {
	var expRelation = {
		id: internalRelation.id,
		type: internalRelation.type,
		properties: internalRelation.properties,
		source: internalRelation.source.id,
		target: internalRelation.target.id,
		meta: internalRelation.meta
	};

	return expRelation;
};

D3Graph.prototype.getAllRelations = function() {
	var self = this;
	var relations = this.getAllRelationsInternal();
	var result = [];
	_.forEach(relations, function(relation) {
		result.push(self.toSimpleRelation(relation));
	}, this);

	return result;
};

D3Graph.prototype.isValidNodes = function(nodes) {
	var self = this;
	nodes =_.ensureIsArray(nodes);
	var result = true;
	_.forEach(nodes, function(node) {
		result = self.isValidNode(node);
		return result;
	}, this);

	return result;
};

D3Graph.prototype.isValidRelations = function(relations) {
	var self = this;
	relations =_.ensureIsArray(relations);
	var result = true;
	_.forEach(relations, function(relation) {
		result = self.isValidRelation(relation);
		return result;
	}, this);

	return result;
};

D3Graph.prototype.getRelationsByNodeIds = function(nodeIds) {
	nodeIds =_.ensureIsArray(nodeIds);

	var relations = [];

	_.forEach(this.getAllRelationsInternal(), function(relation) {
		if (_.includesLoose(nodeIds, relation.source.id) || (relation.source.id != relation.target.id && includesLoose(nodeIds, relation.target.id))) {
			relations.push(relation);
		}
	}, this);

	return relations;
};

D3Graph.prototype.refreshRelations = function() {
	var nodeIds = _.map(this.getAllNodes(), 'id');

	var remainingRelations = [];

	_.forEach(this.getAllRelationsInternal(), function(relation) {
		if ((!_.includesLoose(nodeIds, relation.source.id)) || (!_.includesLoose(nodeIds, relation.target.id))) {
			return;
		}
		remainingRelations.push(relation);
	}, this);

	this.loadRelations(remainingRelations, NO_GRAPH_UPDATE);
};

D3Graph.prototype.loadNodes = function(nodes, checkNodes) {
	if (_.resolve(checkNodes, DO_NODES_CHECK)) {
		if (!this.isValidNodes(nodes)) {
			console.error('D3Graph.loadNodes: nodes are not valid', nodes);
		}
	}

	if (this.executeHook('onNodesLoad', [nodes]) === false) {
		return false;
	}

	this.data.nodes = nodes;

	this.refreshRelations(NO_GRAPH_UPDATE);

	this.executeHook('onNodesLoaded', [nodes]);

	return true;
};


D3Graph.prototype.loadRelations = function(relations, checkRelations) {
	if (_.resolve(checkRelations, DO_RELATIONS_CHECK)) {
		if (!this.isValidRelations(relations)) {
			console.error('D3Graph.loadRelations: relations are not valid', relations);
		}
	}

	if (this.executeHook('onRelationsLoad', [relations]) === false) {
		return false;
	}

	this.data.relations = relations;

	this.executeHook('onRelationsLoaded', [relations]);

	return true;
};

D3Graph.prototype.removeNodesById = function(nodeIds) {
	nodeIds =_.ensureIsArray(nodeIds);

	var remainingNodes = [];
	_.forEach(this.getAllNodes(), function(node) {
		if (_.includesLoose(nodeIds, node.id)) {
			return;
		}

		remainingNodes.push(node);
	}, this);

	return this.loadNodes(remainingNodes, NO_NODES_CHECK);
};

D3Graph.prototype.removeNodes = function(nodes) {
	nodes =_.ensureIsArray(nodes);

	var nodeIds = _.map(nodes, 'id');

	return this.removeNodesById(nodeIds);
};

D3Graph.prototype.getRelationIndex = function(relation) {
	if (!this.isValidRelation(relation)) {
		return false;
	}

	return _.indexOf(this.getAllRelationsInternal(), relation);
};

D3Graph.prototype.removeRelationsById = function(relationIds) {
	relationIds =_.ensureIsArray(relationIds);

	var remainingRelations = [];

	_.forEach(this.getAllRelationsInternal(), function(relation) {
		if (_.includesLoose(relationIds, relation.id)) {
			return;
		}

		remainingRelations.push(relation);
	}, this);

	return this.loadRelations(remainingRelations, NO_NODES_CHECK);
};

D3Graph.prototype.removeRelations = function(relations) {
	relations =_.ensureIsArray(relations);

	var relationIds = _.map(relations, 'id');

	return this.removeRelationsById(relationIds);
};

D3Graph.prototype.removeItems = function(items, executeHooks) {
	if (!_.isObject(items)) {
		return false;
	}

	executeHooks =_.resolve(executeHooks, true);

	var result = true;

	if (_.has(items, 'nodes')) {
		result = this.removeNodes(items.nodes);
	}

	if (result && _.has(items, 'links')) {
		result = this.removeRelations(items.links);
	}

	if (result && _.has(items, 'relations')) {
		result = this.removeRelations(items.relations);
	}

	if(executeHooks) {
		this.executeHook('onItemsRemoved', [items]);
	}

	return result;
};

D3Graph.prototype.removeData = D3Graph.prototype.removeItems;

D3Graph.prototype.isValidNeo4jResult = function(result) {
	if(!_.isObject(result)) {
		return false;
	}

	if (!_.has(result, 'processed')) {
		return false;
	}

	if (!_.isObject(result.processed)) {
		return false;
	}

	if (!_.has(result.processed, 'nodes')) {
		return false;
	}

	if (!_.isArray(result.processed.nodes)) {
		return false;
	}

	if (_.has(result.processed, 'relations') && !_.isArray(result.processed.relations)) {
		return false;
	}
	return true;
};

// Check if entity(node or link) is not visible or filtered out
D3Graph.prototype.isHiddenNode = function(node) {
	if (!this.isValidNode(node)) {
		return _.withError(['D3Graph.isHiddenNode: node is not valid', node]);
	}

	if (_.has(node, 'visible') && (node.visible == false)) {
		return true;
	}

	return false;
};

D3Graph.prototype.isHiddenRelation = function(relation) {
	if (!this.isValidRelation(relation)) {
		return _.withError(['D3Graph.isHiddenRelation: relation is not valid', relation]);
	}

	if (_.has(relation, 'visible') && (relation.visible == false)) {
		return true;
	}

	return false;
};

// Add/update the visible nodes and relations to the d3js force
D3Graph.prototype.assignNodesAndLinksToForce = function() {
	var self = this;
	this.visible = {
		nodes: [],
		relations: []
	};

	// Select visible nodes and add them to this.visible.nodes
	_.forEach(this.data.nodes, function(node, index) {
		self.data.nodes[index].linkCount = 0;
		if (!self.isHiddenNode(node)) {
			//this.visible.nodes.push(this.data.nodes[index]);
			self.visible.nodes.push(node);
		}
	}, this);

	// Select visible relations and add them to this.visible.relations and update node.relationCount for each relation
	_.forEach(this.data.relations, function(relation, index) {
		if (!self.isHiddenRelation(relation)) {
			self.visible.relations.push(self.data.relations[index]);
			self.data.relations[index].source.linkCount++;
			self.data.relations[index].target.linkCount++;
		}
	}, this);

	this.force
		.nodes(this.visible.nodes)
		.links(this.visible.relations)
		.start(); // Actually initializez nodes and relations
};

//Update D3Graph graphical structure based on data assigned to force
D3Graph.prototype.updateGraph = function(){
	var self = this;

	if (this.executeHook('onGraphUpdate') === false) {
		return false;
	}

	this.assignNodesAndLinksToForce();

	// Set data for node groups
	this.nodeGroups = this.nodesContainer
		.selectAll('.node-group')
		.data(this.visible.nodes, function(d, i){
			return d.id;
		});
	// Remove deleted node groups
	this.nodeGroups
		.exit()
		.remove();
	// Add new empty nodes (groups)
	var newNodes = this.nodeGroups.enter()
		.append('g')
		.attr('class', 'node-group')
		.attr('transform', 'translate(0,0)');
		// Create node structure for each new node
	newNodes.each(function(d) {
		self.createSVGNode(this, d);
		self.setNodeEvents(this);
	});

	// Get the relations grouped by node pairs
	//groupedLinks = groupLinks(this.visible.relations);

	// Set multiple rels between 2 nodes as curved relations
	this.visible.relations = setRelationsCurves(this.visible.relations);

	// Update data for all relations in graph
	this.linkGroups = this.relationsContainer.selectAll('.link-group').data(this.visible.relations, function(d, i){
		return d.id;
	});
	// Remove deleted relations
	this.linkGroups
		.exit()
		.remove();

	var newRelations = this.linkGroups
		.enter()
		.append('g')
		.attr('class', 'link-group');
	newRelations.each(function(d) {
		self.createSVGRelation(this, d);
		self.setRelationEvents(this);
	});

	this.renderNodes(this.nodeGroups);
	this.renderRelations(this.linkGroups);


	this.linkTextPaths = this.relationsContainer.selectAll('.link-group .textpath');
	this.relationPaths = this.relationsContainer.selectAll('.link');
	this.relationPathsReversed = this.relationsContainer.selectAll('.link-reversed');
	this.relationBorderPaths = this.relationsContainer.selectAll('.link-border');
	this.tick();

	this.executeHook('onGraphUpdated');
};

D3Graph.prototype.renderNodes = function(nodeElements) {
	var self = this;

	nodeElements = ensureD3Selection(nodeElements);

	// Update style for all relations
	nodeElements.each(function(d){
		self.renderNode(this);
		self.updateNodeLabel(d);
	});
};

D3Graph.prototype.renderRelations = function(relationElements) {
	var self = this;

	relationElements = ensureD3Selection(relationElements);

	// Update style for all relations
	relationElements.each(function(d){
		self.renderRelation(this, d.style, d);
		self.updateRelationLabel(this);
	});
};

// Called by force when updating each frame
D3Graph.prototype.tick = function() {
	if(this._destroyed) return;

	var self = this;
	// Position nodes
	this.nodeGroups.attr("transform", function(d) {
		return "translate(" + d.x + "," + d.y + ")";
	});

	// Determine textpath for relation labels (to always be readable)
	this.linkTextPaths.attr('xlink:href', function(d){
		var element = d3.select(this);
		var base_id = element.attr('base-href');
		var reversed_id =  element.attr('reversed-href');
		if (d.source.x > d.target.x) {
			return reversed_id;
		}
		return base_id;
	});

	// Position relations, reversed relations and link borders
	this.relationBorderPaths.attr("d", function(d) {
		return self.getRelationShapeAndPosition(d);
	});
	this.relationPaths.attr("d", function(d) {
		return self.getRelationShapeAndPosition(d);
	});
	if (!this.relationPathsReversed) {
		return;
	}
	this.relationPathsReversed.attr("d", function (d) {
		return self.getRelationShapeAndPosition(d, REVERSED);
	});
};

D3Graph.prototype.createSVGNode = function(container, nodeData) {
	container = ensureD3Element(container);

	if (this.executeHook('onSVGNodeCreate', [nodeData, container]) === false) {
		return;
	}

	var clipPathUID = 'clippath-' + _.uniqueId();

	container
		.append('path')
		.attr('class', 'node')
		.attr('d', 'M 0 0 m -20, 0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0');

	container
		.append('clipPath')
		.attr('class', 'node-image-clippath')
		.attr('id',  clipPathUID)
		.append('path')
		.attr('class', 'node-image-clippath-path')
		.attr('d', 'M 0 0 m -20, 0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0');

	container
		.append('image')
		.attr('class', 'node-image')
		.attr('clip-path', htmlReferenceById(clipPathUID));
	container
		.append('g')
		.attr('class', 'node-icon-container')
		.append('text')
		.attr('class', 'node-icon')
		.attr('text-anchor', 'middle');
	container
		.append('g')
		.attr('class', 'node-label-container')
		.append('text')
		.attr('class','node-label')
		.attr('text-anchor', 'middle');

	this.executeHook('onSVGNodeCreated', [nodeData, container]);
};

D3Graph.prototype.createSVGRelation = function(container, relationData) {
	container = ensureD3Element(container);

	if (this.executeHook('onSVGRelationCreate', [relationData, container]) === false) {
		return;
	}

	var relationUID = _.uniqueId();
	var markerId = 'marker-' + relationUID;
	var pathId = 'path-' + relationUID;
	var reversedPathId = 'reversed-path-' + relationUID;

	container.append('defs')
		.append('svg:marker')
		.attr('id', markerId)
		.attr('markerUnits', 'userSpaceOnUse')
		.attr('orient', 'auto')
		.append('svg:path');

	container
		.append('path')
		.attr('class', 'link')
		.attr('d', 'M -100 0 l 200, 0')
		.attr('fill', 'none')
		.attr('marker-end', htmlReferenceById(markerId))
		.attr('id', pathId);
	container
		.append('path')
		.attr('class', 'link-reversed')
		.attr('d', 'M -100 0 l 200, 0')
		.attr('fill', 'none')
		.attr('id', reversedPathId);
	container
		.append('text')
		.attr('class',"link-label")
		.attr('text-anchor', 'middle')
		.append('textPath')
		.attr('class', 'textpath')
		.attr('startOffset', '50%')
		.attr('base-href', '#' + pathId)
		.attr('reversed-href', '#' + reversedPathId)
		.attr('xlink:href', '#' + pathId);
	container
		.append('path')
		.attr('class', 'link-border')
		.attr('d', 'M -100 0 l 200, 0');

	this.executeHook('onSVGRelationCreated', [relationData, container]);
};

// Generates d attribute for relation's svg:path, used in tick()
D3Graph.prototype.getRelationShapeAndPosition = function(relData, reversed) {
	reversed =_.resolve(reversed, false);

	var source;
	var target;
	var x, y, hr, r, w, draw, dx, dy, dr;

	if (reversed) {
		source = relData.target;
		target = relData.source;
	}
	else {
		source = relData.source;
		target = relData.target;
	}

	if (source === target) {
		const selfRelationMultiplyFactor = relData.selfRelationMultiplyFactor || 1;
		x = source.x;
		y = source.y;
		hr = source.style.width / 4;
		r = source.style.width / 2;
		w = source.style.width * selfRelationMultiplyFactor;

		draw = "M " + x + "," + y
					+ " a " + r + "," + hr + " 0 0,1  " + w + ",0"
					+ " a " + r + "," + hr + " 0 0,1 -" + w + ",0";
		return draw;
	}

	// If part of multiple relations
	if (_.has(relData, '__curve') && _.isNumber(relData.__curve)) {
		dx = target.x - source.x,
		dy = target.y - source.y,
		dr = Math.sqrt(dx * dx + dy * dy) * getCurveFactor(relData.__curve);
		var sign = (relData.__curve > 0 ? 0 : 1);
		if (reversed) {
			sign = 1 - sign;
		}
		draw = "M" + source.x + "," + source.y
				+ "A" + dr + "," + dr + " 0 0," + sign + ' ' + target.x + "," + target.y;

		return draw;
	}

	return "M " + source.x + "," + source.y
			+ " L " + target.x + "," + target.y;
};

// Set/unset all nodes with fixed positions
D3Graph.prototype.switchAutoLayout = function(enabled){
	if (! _.isBoolean(enabled)) return;

	enabled = _.hasBooleanValue(enabled, true);
	if(enabled === this.settings.autoLayout) return; // nothing to change

	this.settings.autoLayout = enabled;
	this.setNodesFixed(this.getAllNodes(), !enabled);

	this.force.start();
};

D3Graph.prototype.setNodesFixed = function(nodes, fixed) {
	if(nodes === undefined) {
		return;
	}
	if(!_.isArray(nodes)) {
		nodes = [nodes];
	}
	_.forEach(nodes, function(node, index) {
		node.fixed = fixed;
	}, this);

	this.executeHook('onNodesFixed', [nodes, fixed]);
};

D3Graph.prototype.getAutoLayoutStatus = function() {
	return this.settings.autoLayout;
};

D3Graph.prototype.zoom = function(scaleFactor) {
	this.zoomTo(this.scale * scaleFactor);
};

D3Graph.prototype.zoomTo = function(scale) {
	var self = this;

	var svg = this.SVGElement;
	var SVGBox = svg.node().getBoundingClientRect();
	var t = this.zoomListener.translate(); //current translate
	var c = [SVGBox.width / 2, SVGBox.height / 2]; // svg center

	if (scale > this.settings.maxZoomScale) {
		scale = this.settings.maxZoomScale;
	}

	var tx = c[0] + (t[0] - c[0]) / this.scale * scale;
	var ty = c[1] + (t[1] - c[1]) / this.scale * scale;

	this.autoZoomToFit(false);

	this.zoomListener
		.scale(scale)
		.translate([tx, ty])
		.event(
			this.SVGElement.transition()
				.duration(200)
				.each('end', function(){
					self.isZoomedToFit = false;
				})
		);
};

D3Graph.prototype.zoomToFit = function(keepScale) {
	keepScale =_.resolve(keepScale, false);

	if (! this.isOk()) {
		return _.withError('D3Graph.zoomToFit: graph not initialized properly');
	}

	if (! this.isVisible()){
		return;
	}

	if (!(this.getAllNodes().length > 0)) {
		return false;
	}

	var self = this;
	var svg = this.SVGElement;
	var SVGBox = svg.node().getBoundingClientRect();

	try {
		var graphBox = this.graphContainer.node().getBBox();
	}
	catch (exception) {
		console.error('Exception: ', exception);
		return false;
	}

	var scale = this.scale;

	if (! keepScale) {
		scale = Math.min(SVGBox.width / graphBox.width, SVGBox.height/graphBox.height) * 0.97;
		scale = this.toValidScale(scale);
		scale = Math.min(scale, this.settings.maxZoomScale);
	}

	var widthToCenter = (SVGBox.width - (graphBox.width * scale)) / 2;
	var heightToCenter = (SVGBox.height - (graphBox.height * scale)) / 2;


	var translate = [
		(0 - graphBox.x) * scale + widthToCenter,
		(0 - graphBox.y) * scale + heightToCenter
	];

	translate = this.toValidTranslate(translate);

	this.zoomListener.translate(translate) .scale(scale);
	this.zoomListener.event(
		svg.transition()
			.duration(200)
			.each('end', function(){
				self.isZoomedToFit = true;
			})
	);
};

D3Graph.prototype.centerGraph = function() {
	this.zoomToFit(true);
};

D3Graph.prototype.autoZoomToFit = function(statusOn) {
	var currentStatus = this.getAutoZoomToFitStatus();

	clearInterval(this.autoZoomToFitHandle);
	this.autoZoomToFitHandle = null;

	statusOn =_.resolve(statusOn, true);
	if (statusOn != currentStatus) {
		this.executeHook('onAutoZoomToFitChanged', [statusOn]);
		this.settings.autoZoomToFit = statusOn;
	}

	if (! statusOn) {
		return true;
	}
	var self = this;
	this.autoZoomToFitHandle = setInterval(function() {
		self.zoomToFit();
	}, AUTO_ZOOM_TO_FIT_INTERVAL);

	return true;
};

D3Graph.prototype.getAutoZoomToFitStatus = function() {
	return this.settings.autoZoomToFit;
};

D3Graph.prototype.toValidTranslate = function(translate) {
	var translateParams = translate;

	if (! _.isArray(translateParams)) {
		translateParams = translateParams.toString().split(',');
	}

	if (translateParams.length > 2) {
		translateParams = translateParams.slice(0, 2);
	}

	if (!_.isNumeric(translateParams[0])) {
		//An invalid translate parameter is sign of problems so uncomment this line to see where is came from
		//console.error('D3Graph.toValidTranslate: translate param is not numeric', translateParams[0], translate);
		translateParams[0] = 0;
	}

	if (!_.isNumeric(translateParams[1])) {
		//An invalid translate parameter is sign of problems so uncomment this line to see where is came from
		//console.error('D3Graph.toValidTranslate: translate param is not numeric', translateParams[1], translate);
		translateParams[1] = 0;
	}

	return translateParams;
};

D3Graph.prototype.toValidScale = function(scale) {
	if (!_.isNumeric(scale) || scale === Infinity) {
		//An invalid scale parameter is sign of problems so uncomment this line to see where is came from
		//console.error('D3Graph.toValidScale: scale is not numeric', scale);
		scale = 1;
	}

	return scale;
};

// Function for handling zoom event
D3Graph.prototype.zoomHandler = function() {
	//self.SVGElement.select('g').attr("transform", "translate(" + d3.event.translate + ") scale(" + d3.event.scale + ")");
	var eventType;
	var EVENT_PAN = 1;
	var EVENT_ZOOM = 2;

	if (d3.event.sourceEvent instanceof MouseEvent && (d3.event.sourceEvent.buttons == 1)) {
		eventType = EVENT_PAN;
	}

	if (d3.event.sourceEvent instanceof WheelEvent) {
		eventType = EVENT_ZOOM;
	}

	if (eventType === EVENT_PAN) {
		if (this.executeHook('onCanvasPan', [d3.event]) === false) {
			return;
		}
	}
	else if (eventType === EVENT_ZOOM) {
		if (this.executeHook('onZoom', [d3.event]) === false) {
			return;
		}
	}

	var translate = this.toValidTranslate(d3.event.translate);
	var scale = this.toValidScale(d3.event['scale']);

	this.graphContainer.attr("transform", "translate(" + translate + ") scale(" + scale + ")");
	this.scale = scale;

	if (d3.event.sourceEvent instanceof WheelEvent) {
		this.autoZoomToFit(false);
	}

	//when zooming or panning lose the zoomToFit property
	this.isZoomedToFit = false;

	if (eventType === EVENT_PAN) {
		this.executeHook('onCanvasPanned', [d3.event]);
		this._isPanning = true;
	}
	else if (eventType === EVENT_ZOOM) {
		this.executeHook('onZoomed', [d3.event]);
	}
};

// Called when started dragging a node
D3Graph.prototype.dragStart = function(d){
	if (this.executeHook('onNodeDrag', [d], this) == false) {
		return;
	}
	d3.event.sourceEvent.stopPropagation();

	this._dragStartPosition = {
		x: d.x,
		y: d.y
	};
	this.force.stop();
};

// Update node position while dragging
D3Graph.prototype.drag = function(d){
	d.px += d3.event.dx;
	d.py += d3.event.dy;
	d.x += d3.event.dx;
	d.y += d3.event.dy;
	this.tick();
};

// Called when dragging a node ends
D3Graph.prototype.dragEnd = function(d){
	if(this._dragStartPosition === null) return;
	var distance = Math.sqrt(
		Math.pow(d.x-this._dragStartPosition.x, 2) + Math.pow(d.y-this._dragStartPosition.y, 2)
	);
	this._dragStartPosition = null;
	if(distance < 5) { // threshold to call it a drag
		return;
	}
	this.setNodesFixed([d], true);
	this.tick();
	this.force.resume();
	this.executeHook('onNodeDragged', [d], this);
};

// Resize graph
D3Graph.prototype.resize = function(width, height, preventError) {
	preventError =_.resolve(preventError, false);

	if (this.executeHook('onGraphResize', [width, height]) === false) {
		return;
	}

	if (! preventError) {
		console.error('Resizing SVGElement, this might cause some problems so CHECK WHT IS resize or resizeGraph called', graph);
	}

	// Resize the svg
	if (width !== undefined) {
		   this.SVGElement.attr('width', width);
	}

	if (height !== undefined) {
		this.SVGElement.attr('height', height);
	}

	// Resize the force
	//this.force.size([width, height]);
	//this.force.start();

	// Zoom to fit, timeout to wait for animations to end
	setTimeout(
		function (){
			if (this.isZoomedToFit)
			{
				this.zoomToFit();
			}
		}
		, 500
	);

	this.executeHook('onGraphResized', [width, height]);
};

D3Graph.prototype.resizeGraph = D3Graph.prototype.resize;

D3Graph.prototype.getSVG = function() {
	if (! this['$SVG']) {
		this['$SVG'] = $(this.SVGElement.node());
	}

	return this['$SVG'];
};

D3Graph.prototype.getGraphSize = function() {
	if (!this.isOk()) {
		return false;
	}

	var svg = this.getSVG();

	if ( !this.isVisible()) {
		return false;
	}

	var size = {
		width: svg.width(),
		height: svg.height()
	};

	return validateSize(size);
};

D3Graph.prototype.getNodeBorderWidth = function(node) {
	if ( ! this.isValidNode(node)) {
		return _.withError(['D3Graph.getNodeBorderWidth: node is not valid', node]);
	}

	if ( ! this.isValidNodeStyle(node.style)) {
		return _.withError(['D3Graph.getNodeBorderWidth: node style is not valid', node]);
	}

	if ( ! _.has(node.style, 'lineWidth')) {
		return false;
	}

	var borderWidth = parseFloat(node.style.lineWidth);

	if ( !_.isNumeric(borderWidth)) {
		return false;
	}

	return borderWidth;
};

D3Graph.prototype.getNodeSize = function(node) {
	if ( ! this.isValidNode(node)) {
		return _.withError(['D3Graph.getNodeSize: node is not valid', node]);
	}

	if ( ! this.isValidNodeStyle(node.style)) {
		return _.withError(['D3Graph.getNodeSize: node style is not valid', node]);
	}

	var element = this.getNodeElement(node);
	return element.getBBox();

	// DOES NOT ALWAYS GIVE THE RIGHT SIZE
	/*var size = {
			width: parseFloat(node.style.width),
			height: parseFloat(node.style.width) // height is the same as width for triangle, circle and square
		};

		if (node.style.display === 'rectangle') {
			size.height = parseFloat(node.style.height);
		}

		size = validateSize(size);

		var nodeBorderWidth = this.getNodeBorderWidth(node);

		size.width += nodeBorderWidth / 2;
		size.height += nodeBorderWidth / 2;

		return size; */
};

D3Graph.prototype.getContainer = function() {
	if (!this.container) {
		return _.withError(['D3Graph.getContainer: container not initialized']);
	}

	return this.container;
};

// Calculate node's label based on style
D3Graph.prototype.getNodeLabel = function(d){
	if(!_.isNil(d.style.label)) return d.style.label;

	if(d.labels.length > 0) {
		return d.labels[0];
	}

	return '(node)';
};

// Calculate relation's label based on style
D3Graph.prototype.getRelationLabel = function(d){
	if(!_.isNil(d.style.label)) return d.style.label;

	if(_.isStringOrNumber(d.type)) {
		return d.type;
	}
	return '';
};

D3Graph.prototype._getScaleToFit = function(bbox, width, height, maxScale) {
	var scale = Math.min(width / bbox.width, height / bbox.height);
	scale = this.toValidScale(scale);

	if (maxScale) {
		scale = Math.min(maxScale, scale);
	}
	return scale;
};

D3Graph.prototype.updateNodeLabel = function(node, label) {
	var self = this;
	var maxScale = 1;
	if (!this.isValidLabel(label)) {
		console.error('D3Graph.updateNodeLabel: label must be string', label, node);
		return false;
	}

	var style = _.cloneDeep(node.style);
	if(_.isFunction(this._overrideNodeStyle)) {
		this._overrideNodeStyle(style);
	}

	var nodeElement = this.getNodeElement(node);

	if (! nodeElement) {
		return _.withError('D3Graph.updateNodeLabel: could not find node element for node', node);
	}

	var nodeLabel = d3.select(nodeElement).select('.node-label');
	var nodeLabelContainer = d3.select(nodeElement).select('.node-label-container');
	var nodeIcon = d3.select(nodeElement).select('.node-icon');
	var nodeIconContainer = d3.select(nodeElement).select('.node-icon-container');
	var rowIncrement = parseInt(style.labelStyle.font);

	// get node height
	var nodeWidth = style.width;
	var nodeHeight = style.height;

	var adjustedNodeHeight = nodeHeight;
	var adjustedNodeWidth = nodeWidth;
	if(style.display === 'rectangle') {
		adjustedNodeHeight = nodeHeight * .8;
		adjustedNodeWidth = nodeWidth * .8;
	} else if (style.display === 'square') {
		adjustedNodeHeight = nodeWidth * .8;
		adjustedNodeWidth = nodeWidth * .8;
		nodeHeight = nodeWidth;
	} else if (style.display === 'triangle') {
		nodeHeight = nodeWidth;
		adjustedNodeHeight = nodeHeight * 0.45;
		adjustedNodeWidth = nodeWidth * 0.45;
	}
	else if (style.display === 'circle') {
		nodeHeight = nodeWidth;
		adjustedNodeHeight = nodeHeight * 0.60;
		adjustedNodeWidth = nodeWidth * 0.60;
	}

	// Clear label
	nodeLabel.selectAll('tspan').remove();
	nodeIcon.selectAll('tspan').remove();

	var aspectRatio = 1;

	if (style.display == 'rectangle') {
		aspectRatio = nodeWidth / nodeHeight;
	}

	if (_.has(style, 'iconClass') && _.isString(style.iconClass)) {
		var iconBBox;
		try {
			iconBBox = nodeIcon.node().getBBox();
		} catch (exception) {
			console.error(exception);
			return false;
		}

		const iconClass = Icon.getClass(style.iconClass);
		var text = getBeforeContentForCSSClass(iconClass);
		this._addNodeIcon(nodeIcon, iconClass, text, adjustedNodeHeight, adjustedNodeWidth);
	}

	var   nodeLabelCaption = (label || this.getNodeLabel(node))
		, nodeLabelRows = splitStringToMultipleRows(nodeLabelCaption, aspectRatio);
	var singleLine = nodeLabelRows.length < 2;
	var first = true;
	for (var rowIndex in nodeLabelRows) {
		var tspan = nodeLabel.append('tspan')
			.text(nodeLabelRows[rowIndex])
			.attr('x', 0)
			.attr('dominant-baseline', 'central');
		if(first) {
			tspan.attr('y', 0); first = false;
		} else {
			tspan.attr('dy', '1em');
		}
	}

	// Scale node label to fit the node
	var nodeLabelBBox, scale, heightTranslate;

	try {
		nodeLabelBBox = nodeLabel.node().getBBox();
	}
	catch (exception) {
		return false;
	}

	scale = this._getScaleToFit(nodeLabelBBox, adjustedNodeWidth, adjustedNodeHeight, 1);

	var margin = 10;
	var radiusX = nodeWidth / 2 + margin;
	var radiusY = nodeHeight / 2 + margin;
	var centerY = 0;

	var dy = (-(nodeLabelRows.length-1)/2);
	var labelPositions = {
		top: 	{x: 0, y: -radiusY - nodeLabelBBox.height/2*scale, baseline: 'ideographic', dy: dy, anchor: 'middle'},
		left: 	{x: -radiusX, y: centerY, baseline: 'central', dy: dy, anchor: 'end'},
		bottom: {x: 0, y: radiusY, baseline: 'hanging', dy: 0, anchor: 'middle'},
		right: 	{x: radiusX, y: centerY, baseline: 'central', dy: dy, anchor: 'start'},
		center: {x: 0, y: centerY, baseline: 'central', dy: dy, anchor: 'middle'}
	};
	var labelPosition = labelPositions['center'];
	if(labelPositions[style.labelPosition] !== undefined) {
		labelPosition = labelPositions[style.labelPosition];
	}
	heightTranslate = this.toValidTranslate([labelPosition.x, labelPosition.y]);

	nodeLabelContainer.attr('transform', 'translate(' + heightTranslate + ')');
	nodeLabel.attr('text-anchor', labelPosition.anchor);
	nodeLabel.selectAll('tspan').attr('dominant-baseline', labelPosition.baseline);
	nodeLabel.attr('transform', 'scale(' + scale + ')');
	nodeLabel.attr('dy', labelPosition.dy + "em");
};

D3Graph.prototype._addNodeIcon = function(container, klass, text, height, width) {
	container
		.append('tspan')
		.classed(klass, true)
		.text(text)
		.attr('dominant-baseline', 'central')
		.attr('dy', 0)
		.attr('x', 0);

	try {
		var bbox = container.node().getBBox();
		var scale = this._getScaleToFit(bbox, height, width);
		container.attr('transform', 'scale(' + scale + ')');
	}
	catch (e) {
		console.error(e);
	}
};

D3Graph.prototype.updateRelationLabel = function(relation, label) {
	if (!this.isValidLabel(label)) {
		return _.withError(['D3Graph.updateRelationLabel: label must be string', label, relation]);
	}

	relation = ensureD3Element(relation);
	var d = relation.data()[0];
	if (!_.isObject(d)) {
		return;
	}

	var relLabel = relation.select('.link-label');

	var  relLabelCaption = (label || this.getRelationLabel(d));

	// Update label text
	relLabel
		.selectAll('.textpath')
		.text(relLabelCaption);
};

// Node must be svg element or d3 selection
D3Graph.prototype.renderNode = function (node) {
	// Check if node is valid
	if (!(node instanceof d3.selection)) {
		node = d3.select(node);
	}
	if (node.length < 1) {
		console.error('Node does not exist in current page', node);
		return false;
	}

	// Get node data
	const nodeData = node.data()[0];

	this.ensureNodeHasStyleSet(nodeData);

	// Evaluate style for this node
	let style = nodeData.style;

	// Size should be values in px
	style.width = parseInt(style.width, 10);
	style.height = parseInt(style.height, 10);
	style.lineWidth = parseInt(style.lineWidth, 10);

	if(_.isFunction(this._overrideNodeStyle)) {
		this._overrideNodeStyle(style);
	}

	// Generate path d attribute according to style.display
	let draw = drawShape(style.display, style.width, style.height);

	// Set node style
	node.select('.node')
		.attr('d', draw)
		.style('fill', style.fillColor)
		.style('opacity', style.opacity)
		.style('stroke', style.lineColor)
		.style('stroke-width', style.lineWidth)
		.style('stroke-opacity', style.lineOpacity);

	node.select('.node-label')
		.attr('text-anchor', 'middle')
		.style('font', style.labelStyle.font)
		.style('font-weight', style.labelStyle.fontWeight)
		.style('fill', style.labelStyle.color);

	node.select('.node-icon')
		.style('fill', style.iconColor)
		.style('fill-opacity', style.iconOpacity)
		// Hide node icon if image exists
		.style('display', style.image && style.image !== "NO_IMAGE" ? 'none' : 'block');

	// Set node image
	let imageWidth, imageHeight;
	let nodeImageUrl = _.get(nodeData, 'style.image');

	// Set image properties and clippath (if image is available)
	if (style.image && style.image !== "NO_IMAGE") { // TODO: NO_IMAGE hack can be removed if D3Graph.ensureNodeHasStyleSet no longer uses defaultsDeep
		node.select('.node-image-clippath-path')
			.attr('d', draw);

		if (style.display === 'rectangle') {
			imageHeight = style.height;
			imageWidth = style.width;
		} else {
			imageWidth = imageHeight = style.width;
		}

		node.select('.node-image')
			.attr('xlink:href', nodeImageUrl)
			.attr('width', imageWidth)
			.attr('height', imageHeight)
			.attr('x', 0 - (imageWidth / 2))
			.attr('y', 0 - (imageHeight / 2));
	}
	// Reset image
	else {
		node.select('.image-clippath .path')
			.attr('d', '');

		node.select('.node-image')
			.attr('xlink:href', '')
			.attr('height', 0)
			.attr('width', 0);
	}
};

// rel must be a d3 selection or SVG element
D3Graph.prototype.renderRelation = function(rel, style) {
	style = _.cloneDeep(style); // prevent overwrites

	if (!(rel instanceof d3.selection)) {
		rel = d3.select(rel);
	}

	if (rel.length < 1) {
		console.error('Node does not exist in current page', rel);
		return false;
	}

	var relData = rel.data()[0];

	if (style === undefined && _.isObject(relData)) {
		style = _.result(relData, 'style', undefined);
	}

	if (!style || !(_.isObject(style))) {
		console.error('Incorrect style.', style);
		return false;
	}

	GraphStyles.applyDefaults(style, 'rel');

	const markerWidth = parseInt(style.marker.width, 10);
	const markerLineWidth = parseInt(style.marker.lineWidth, 10);

	// size should be values in px
	style.width = parseInt(style.width, 10);
	style.marker.width = _.isFinite(markerWidth) ? markerWidth : defaults.marker.width;
	style.marker.lineWidth = _.isFinite(markerLineWidth) ? markerLineWidth : defaults.marker.lineWidth;

	// Set path description according to marker shape
	var markerD = '';
	if (style.marker.display == 'arrow') {
		markerD = "M 0,0 L w, hw L 0,w Z"
			.replace(/hw/g, (style.marker.width / 2))
			.replace(/w/g, (style.marker.width));
	}
	else if (style.marker.display == 'square') {
		markerD = 'M0 0 l 0,w l w,0 l0,-w l -w,0 '.replace(/w/gi, style.marker.width);
	}
	else if (style.marker.display == 'circle') {
		markerD = "M 0,0 m lw,d a r,r 0 1,0 w,0 a r,r 0 1,0 -w,0"
			.replace(/lw/g, style.marker.lineWidth)
			.replace(/d/g, style.marker.width / 2)
			.replace(/r/g, style.marker.width / 2 - style.marker.lineWidth)
			.replace(/w/g, style.marker.width - (2*style.marker.lineWidth));
	}

	//console.log('relData', relData);

	// calculate marker offset from the end of the line/link
	var refX = 0,
		refY = 0;
	if (_.isObject(relData) && _.has(relData, 'source') && _.isObject(relData.source) && _.has(relData, 'target') && _.isObject(relData.target)) {
		const selfRelationMultiplyFactor = relData.selfRelationMultiplyFactor || 1;
		if (relData.source == relData.target) {
			refY = -(relData.source.style.width * selfRelationMultiplyFactor - relData.style.marker.width / 2);
		}
		else {
			if (relData.target.style.display == 'circle') {
				refX =  relData.target.style.width / 2 + style.marker.width;
			}
			else if (relData.target.style.display == 'square') {
				refX =  relData.target.style.width / 1.5 + style.marker.width;
			}
			else if (relData.target.style.display == 'triangle') {
				refX =  relData.target.style.width / 2 + style.marker.width;
			}
			else if (relData.target.style.display == 'rectangle') {
				refX =  Math.max(relData.target.style.width, relData.target.style.height) / 2 + style.marker.width;
			}
			refX += style.marker.lineWidth;
			refY = style.marker.width / 2;
		}
	}

	// Update marker style
	rel.select('marker')
		.attr('viewBox', '-lw -lw w w'.replace(/lw/g, (style.marker.lineWidth)).replace(/w/g,style.marker.width + 2 * style.marker.lineWidth))
		.attr('refX', refX)
		.attr('refY', refY) // - this.arrow_size / 4)
		.attr('markerWidth', style.marker.width + 2 * style.marker.lineWidth)
		.attr('markerHeight',  style.marker.width + 2 * style.marker.lineWidth)
		.select('path')
		.attr('d', markerD)
		.attr('stroke-width', style.marker.lineWidth)
		.attr('stroke', style.marker.color)
		.attr('fill', style.marker.fillColor);

	//console.log('link', rel.select('.link'));
	//console.log('style', style);
	// Update link style
	rel.select('.link')
		.style('stroke', style.fillColor)
		.style('stroke-width', style.width)
		.attr('pathLength', 100);

	// Update link label style
	rel.select('text')
		.attr('dy', '-3')
		.style('font', style.labelStyle.font)
		.style('font-weight', style.labelStyle.fontWeight)
		.style('fill', style.labelStyle.color)
		.style('stroke', style.labelStyle.stroke)
		.style('stroke-width', style.labelStyle.strokeWidth);

	var linkBorderWidth = Math.max(style.marker.width, style.marker.lineWidth, style.width) + 10;
	rel.select('.link-border')
		.style('stroke-width', linkBorderWidth);
};

D3Graph.prototype.setNodeStyle = function(node, style) {
	if (!this.isValidNode(node)) {
		console.error('D3Graph.setNodeStyle: node is not valid', node);
		return false;
	}

	if (!this.isValidNodeStyle(style)) {
		console.error('D3Graph.setNodeStyle: style is not a valid node style', style);
		return false;
	}

	node.style = _.extend({}, style);
	return false;
};

D3Graph.prototype.setRelationStyle = function(relation, style) {
	if (!this.isValidRelation(relation)) {
		console.error('D3Graph.setRelationStyle: relation is not valid', relation);
		return false;
	}

	if (!this.isValidRelationStyle(style)) {
		console.error('D3Graph.setRelationStyle: style is not a valid relation style', style);
		return false;
	}

	relation.style = _.extend({}, style);
	return true;
};

D3Graph.prototype.ensureNodeHasStyleSet = function(node) {
	if (!this.isValidNode(node)) {
		console.error('D3Graph.ensureNodeHasStyleSet: node is not valid', node);
		return false;
	}

	if(!_.isPlainObject(node.style)) {
		node.style = {};
	}
	GraphStyles.applyDefaults(node.style, 'node');

	return true;
};

D3Graph.prototype.ensureRelationHasStyleSet = function(relation) {
	if (!this.isValidRelation(relation)) {
		console.error('D3Graph.ensureRelationHasStyleSet: relation is not valid', relation);
		return false;
	}

	if(!_.isPlainObject(relation.style)) {
		relation.style = {};
	}
	GraphStyles.applyDefaults(relation.style, 'rel');

	return true;
};

D3Graph.prototype.applyGraphStylesToNode = function(node) {
	if (!this.isValidNode(node)) {
		console.error('D3Graph.applyGraphStylesToNode: node is invalid', node);
		return false;
	}
	delete node.style;
	const style = this.styles.computeStyles(node, 'node');
	style.defaultLineWidth = parseFloat(style.lineWidth);
	this.setNodeStyle(node, style);

	return style;
};

D3Graph.prototype.applyGraphStylesToRelation = function(relation) {
	var self = this;
	if (!this.isValidRelation(relation)) {
		console.error('D3Graph.applyGraphStylesToRelation: relation is invalid', node);
		return false;
	}

	delete relation.style;
	const style = this.styles.computeStyles(relation, 'rel');
	this.setRelationStyle(relation, style);

	return style;
};

D3Graph.prototype.applyGraphStylesToAllNodes = function() {
	var self = this;
	_.forEach(this.getAllNodes(), function(node) {
		self.applyGraphStylesToNode(node);
	}, this);

	return true;
};

D3Graph.prototype.applyGraphStylesToAllRelations = function() {
	var self = this;
	_.forEach(this.getAllRelationsInternal(), function(relation) {
		self.applyGraphStylesToRelation(relation);
	}, this);

	return true;
};

D3Graph.prototype.applyGraphStyles = function() {
	this.applyGraphStylesToAllNodes();
	this.applyGraphStylesToAllRelations();
	return true;
};

D3Graph.prototype.findNode = function(nodeSpecification) {
	if(!_.isArray(this.data.nodes)) {
		return undefined;
	}

	// For string or number, assume it's an id
	if(_.isString(nodeSpecification) || _.isNumber(nodeSpecification)) {
		nodeSpecification = {id: nodeSpecification};

		// Try to find the node directly
	} else {
		var idx = this.data.nodes.indexOf(nodeSpecification);
		if(idx >= 0) {
			return this.data.nodes[idx];
		}
	}

	// Try to find the node by its id
	return _.find(this.data.nodes, {id: nodeSpecification.id});
};

D3Graph.prototype.findRelation = function(relSpecification) {
	if(!_.isArray(this.data.relations)) {
		return undefined;
	}

	// For string or number, assume it's an id
	if(_.isString(relSpecification) || _.isNumber(relSpecification)) {
		relSpecification = {id: relSpecification};

		// Try to find the relation directly
	} else {
		var idx = this.data.relations.indexOf(relSpecification);
		if(idx >= 0) {
			return this.data.relations[idx];
		}
	}

	// Try to find the node by its id
	return _.find(this.data.relations, {id: relSpecification.id});
};

D3Graph.prototype.hideNode = function(nodeSpecs) {
	var self = this;
	var node = this.findNode(nodeSpecs);
	if(node === undefined) {
		console.error('D3Graph.hideNode: node not found.', nodeSpecs);
		return false;
	}

	if (this.isSelectedNode(node)) {
		this.deselectNodeElement(node);
	}

	node.visible = false;

	var relations = this.getNodesRelations(node);
	_.forEach(relations, function(rel) {
		rel.hiddenBecauseOfNeighbours = rel.visible || rel.hiddenBecauseOfNeighbours !== false;
		self.hideRelation(rel, NO_GRAPH_UPDATE);
	});

	return true;
};

D3Graph.prototype.hideNodes = function(nodes) {
	var self = this;
	nodes =_.ensureIsArray(nodes);
	_.forEach(nodes, function(node) {
		self.hideNode(node, false);
	}, this);

	return true;
};

D3Graph.prototype.unhideNode = function(nodeSpecs, unhideNodeRelations) {
	var self = this;
	var node = this.findNode(nodeSpecs);
	if(node === undefined) {
		console.error('D3Graph.unhideNode: node not found.', nodeSpecs);
		return false;
	}

	node.visible = true;

	if (_.resolve(unhideNodeRelations, true)) {
		var relations = this.getNodesRelations(node);
		_.forEach(relations, function(rel) {
			if(rel.hiddenBecauseOfNeighbours === true) {
				self.unhideRelation(rel, NO_GRAPH_UPDATE, NO_NEIGHBOURS_UNHIDE);
			}
		});
	}

	return true;
};

D3Graph.prototype.unhideNodes = function(nodes, unhideNodeRelations) {
	var self = this;
	nodes =_.ensureIsArray(nodes);

	if (this.executeHook('onNodesUnhide', [nodes]) === false) {
		return false;
	}

	_.forEach(nodes, function(node) {
		self.unhideNode(node, unhideNodeRelations);
	}, this);

	this.executeHook('onNodesUnhiddened', [nodes]);

	return true;
};

D3Graph.prototype.hideRelation = function(relSpecs) {
	var relation = this.findRelation(relSpecs);
	if(relation === undefined) {
		console.error('D3Graph.unhideRelation: relation not found.', relSpecs);
		return false;
	}

	relation.visible = false;

	return true;
};

D3Graph.prototype.hideRelations = function(relations) {
	var self = this;
	relations =_.ensureIsArray(relations);

	_.forEach(relations, function(relation) {
		self.hideRelation(relation, false);
	}, this);

	return true;
};

D3Graph.prototype.unhideRelation = function(relSpecs, unhideRelationNodes) {
	var relation = this.findRelation(relSpecs);
	if(relation === undefined) {
		console.error('D3Graph.unhideRelation: relation not found.', relSpecs);
		return false;
	}

	relation.hiddenBecauseOfNeighbours = false;
	if (this.isHiddenNode(relation.source) || this.isHiddenNode(relation.target)) {
		relation.hiddenBecauseOfNeighbours = true;
		if (!_.resolve(unhideRelationNodes, false)) {
			return false;
		}
		this.unhideNodes([relation.source, relation.target], NO_GRAPH_UPDATE);
	}

	relation.visible = true;

	return true;
};

D3Graph.prototype.unhideRelations = function(relations, unhideRelationNodes) {
	var self = this;
	relations =_.ensureIsArray(relations);

	_.forEach(relations, function(relation) {
		self.unhideRelation(relation, NO_GRAPH_UPDATE, unhideRelationNodes);
	}, this);

	return true;
};

D3Graph.prototype.isValidLabel = function(label) {
	if (label !== undefined) {
		if (!_.isString(label)) {
			return false;
		}
	}
	return true;
};

D3Graph.prototype.isValidNode = function(node) {
	if (! _.isObject(node) ||
			! _.has(node, 'id') ||
			!_.isSimpleValue(node.id)) {

		return false;
	}

	return true;
};

D3Graph.prototype.isValidRelation = function(rel) {
	if (!_.isObject(rel) || rel.id === undefined) {
		return false;
	}

	return true;
};

D3Graph.prototype.isValidNodeStyle = function(nodeStyle) {
	if (!_.isPlainObject(nodeStyle)) {
		return false;
	}

	return true;
};

D3Graph.prototype.isValidRelationStyle = function(relStyle) {
	if (!_.isPlainObject(relStyle)) {
		return false;
	}

	return true;
};

D3Graph.prototype.hasEvent = function(eventName) {
	if (!_.isFunction(this[eventName])) {
		return false;
	}

	return true;
};

D3Graph.prototype.executeEvent = function(eventName, eventDoneName, thisObject, args) {
	var result = true;
	result = this.executeHook(eventName, args, thisObject);

	if (result === false) {
		return result;
	}

	if (this.hasEvent(eventName)) {
		result = this[eventName].apply(thisObject, args);
	}
	if (result === false) {
		return result;
	}

	this.executeHook(eventDoneName, args, thisObject);

	return result;
};

D3Graph.prototype.setNodeEvents = function(nodeElement) {
	var self = this;
	d3.select(nodeElement)
		.call(this.nodeDrag)
		.on('mouseover', function(d) {
			self.executeEvent('onNodeMouseOver', 'onNodeMouseOvered', self,[d, this, {mouseX: d3.event.x, mouseY: d3.event.y}]);
		})
		.on('mouseout', function(d) {
			self.executeEvent('onNodeMouseOut', 'onNodeMouseOuted', self,[d, this]);
		})
		.on('click', function(d) {
			var event = d3.event;

			if (self._singleClickTimeout) {
				clearTimeout(self._singleClickTimeout);
			}

			var element = this;

			// Preselect
			self.preSelectNodeElement(element);
			self._singleClickTimeout = setTimeout(function() {
				self.dePreSelectNodeElement(element);
				d3.event = event; //restore d3.event to the status it had when the event was initialy triggered
				self.executeEvent('onNodeClick', 'onNodeClicked', self,[d, element]);
				self._singleClickTimeout = undefined;
			}, DOUBLE_CLICK_TIMEOUT_MS);
		})
		.on('contextmenu', function(d) {
			d3EventPreventDefault();
			self.executeEvent('onNodeRightClick', 'onNodeRightClicked', self,[d, this]);
		})
		.on('dblclick', function(d) {
			if (self._singleClickTimeout) {
				clearTimeout(self._singleClickTimeout);
			}

			self.executeEvent('onNodeDoubleClick', 'onNodeDoubleClicked', self,[d, this]);
		}, true);
};

D3Graph.prototype.setRelationEvents = function(relationElement) {
	var self = this;


	d3.select(relationElement)
		.on('mouseover',  function(d) {
			self.executeEvent('onRelationMouseOver', 'onRelationMouseOvered', self,[d, this, {mouseX: d3.event.x, mouseY: d3.event.y}]);
		})
		.on('mouseout',  function(d) {
			self.executeEvent('onRelationMouseOut', 'onRelationMouseOuted', self,[d, this]);
		})
		.on('click',  function(d) {
			var event = d3.event;
			if (this._singleClickTimeout) {
				clearTimeout(this._singleClickTimeout);
			}
			var element = this;
			self.preSelectRelation(element);
			self._singleClickTimeout = setTimeout(function() {
				self.dePreSelectRelation(element);
				d3.event = event;
				self.executeEvent('onRelationClick', 'onRelationClicked', self,[d, element]);
				self._singleClickTimeout = undefined;
			}, DOUBLE_CLICK_TIMEOUT_MS);
		})
		.on('contextmenu',  function(d) {
			d3EventPreventDefault();
			self.executeEvent('onRelationRightClick', 'onRelationRightClicked', self,[d, this]);
		})
		.on('dblclick',  function(d) {
			if (self._singleClickTimeout) {
				clearTimeout(this._singleClickTimeout);
			}

			self.executeEvent('onRelationDoubleClick', 'onRelationDoubleClicked', self,[d, this]);
		}, true);
};

D3Graph.prototype.onCanvasClick = function(){
	this.deselectAll();
	this.executeHook('onSelectionChanged', null);
};

D3Graph.prototype.onNodeClick = function(node, nodeElement) {
	// If ctrl is pressed then do not deselect
	if (!d3.event.shiftKey) {
		this.deselectAll();
	}

	if (this.isSelectedNode(node)) {
		this.deselectNodeElement(nodeElement);
		this.executeHook('onSelectionChanged', [node], this);
		return;
	}

	this.selectNodeElement(nodeElement);
	this.executeHook('onSelectionChanged', [node], this);
};

D3Graph.prototype.onNodeRightClick = function(node, nodeElement) {
	this.deselectAll();
	this.selectNodeElement(nodeElement);
	this.executeHook('onSelectionChanged', [node], this);
};

D3Graph.prototype.onNodeMouseOver = function(node, nodeElement) {
	this.highlightNode(node, nodeElement);
};

D3Graph.prototype.onNodeMouseOut = function(node, nodeElement) {
	this.unhighlightNode(node, nodeElement);
};

D3Graph.prototype.onRelationMouseOver = function(relation, relationElement) {
	this.highlightRelation(relation, relationElement);
};

D3Graph.prototype.onRelationMouseOut = function(relation, relationElement) {
	this.unhighlightRelation(relation, relationElement);
};


D3Graph.prototype.onRelationClick = function(relation, relationElement) {
	// If ctrl is pressed then do not deselect
	if (!d3.event.shiftKey) {
		this.deselectAll();
	}
	this.selectRelation(relationElement);
	this.executeHook('onSelectionChanged', [relation], this);
};

D3Graph.prototype.onRelationRightClick = function(relation, relationElement) {
	this.deselectAll();
	this.selectRelation(relationElement);
	this.executeHook('onSelectionChanged', [relation], this);
};

D3Graph.prototype.getAllNodeElements = function() {
	return this.SVGElement.selectAll('.node-group');
};

D3Graph.prototype.getAllRelationElements = function() {
	return this.SVGElement.selectAll('.link-group');
};

D3Graph.prototype.getNodeElement = function(nodeData) {
	var element;

	this.getAllNodeElements().each(function(d) {
		if (d == nodeData || (nodeData.id !== undefined && d.id === nodeData.id)) {
			element = this;
			return;
		}
	});

	return element;
};

D3Graph.prototype.getRelationElement = function(relData) {
	var element;

	this.getAllRelationElements().each(function(d) {
		if (d == relData || (relData.id !== undefined && d.id === relData.id)) {
			element = this;
		}
	});

	return element;
};

D3Graph.prototype.isSelectedNode = function(node) {
	if (!this.isValidNode(node)) {
		return _.withError(['D3Graph.isSelectedNode: node is not a valid', node]);
	}

	return _.result(node, 'selected', false);
	 };

D3Graph.prototype.isSelectedRelation = function(relation) {
	if (!this.isValidRelation(relation)) {
		return _.withError(['D3Graph.isSelectedRelation: relation is not a valid', relation]);
	}

	return _.result(relation, 'selected', false);
	 };

// Update visual appearance of a pre-selected node
D3Graph.prototype.preSelectNodeElement = function(node) {
	node = ensureD3Element(node);

	var d = node.data()[0];

	if (!_.isObject(d)) {
		console.error('D3Graph.selectNodeElement: Node has no data attached', node);
		return false;
	}

	var preSelected = _.result(d, 'pre-selected', undefined);
	if (!preSelected) {
		node.classed('pre-selected', true);
		d.style.lineWidth = d.style.defaultLineWidth + NODE_SELECTION_EXTRA_LINEWIDTH;
		this.renderNode(node);
	}
};

// Update visual appearance of a selected node
D3Graph.prototype.dePreSelectNodeElement = function(node) {
	node = ensureD3Element(node);

	var d = node.data()[0];

	if (!_.isObject(d)) {
		return false;
	}

	var selected = _.result(d, 'pre-selected', undefined);

	if (selected) {
		node.classed('pre-selected', false);
		d.style.lineWidth = d.style.defaultLineWidth;
		this.renderNode(node);
	}
};

// Update visual appearance of a selected node
D3Graph.prototype.selectNodeElement = function(node) {
	node = ensureD3Element(node);

	var d = node.data()[0];

	if (!_.isObject(d)) {
		console.error('D3Graph.selectNodeElement: Node has no data attached', node);
		return false;
	}

	if (!node.classed('selected')) {
		// Add node to selection
		this._selection.nodes.push(d);

		node.classed('selected', true);
		d.style.lineWidth = d.style.defaultLineWidth + NODE_SELECTION_EXTRA_LINEWIDTH;
		d.selected = true;
		this.renderNode(node);
	}
};

// Update visual appearance of a selected node
D3Graph.prototype.deselectNodeElement = function(node) {
	node = ensureD3Element(node);

	var d = node.data()[0];

	if (!_.isObject(d)) {
		return false;
	}

	if (node.classed('selected')) {
		// Remove node from selection
		_.remove(this._selection.nodes, function(item) {
			return item === d || (item.id !== undefined && item.id === d.id);}
		);

		node.classed('selected', false);
		d.style.lineWidth = d.style.defaultLineWidth;
		d.selected = false;
		this.renderNode(node);
	}
};

D3Graph.prototype.preSelectRelation = function(rel) {
	rel = ensureD3Element(rel);
	var d = rel.data()[0];

	if (!_.isObject(d)) {
		return false;
	}

	// Add relation to selection
	rel.classed('pre-selected', true);
};

D3Graph.prototype.dePreSelectRelation = function(rel) {
	rel = ensureD3Element(rel);

	var d = rel.data()[0];

	if (!_.isObject(d)) {
		return false;
	}

	rel.classed('pre-selected', false);
};

D3Graph.prototype.selectRelation = function(rel) {
	rel = ensureD3Element(rel);
	var d = rel.data()[0];

	if (!_.isObject(d)) {
		return false;
	}

	if (!rel.classed('selected')) {
		// Add relation to selection
		this._selection.relations.push(d);
		rel.classed('selected', true);
		d.selected = true;
	}
};

D3Graph.prototype.deselectRelation = function(rel) {
	rel = ensureD3Element(rel);

	var d = rel.data()[0];

	if (!_.isObject(d)) {
		return false;
	}

	if (rel.classed('selected')) {
		// Remove relation from selection
		_.remove(this._selection.relations, function(item) {
			return item === d || (item.id !== undefined && item.id === d.id);}
		);

		rel.classed('selected', false);
		d.selected = false;
	}
};

D3Graph.prototype.deselectAll = function() {
	var selectedNodes = this.SVGElement.selectAll('.node-group.selected');
	var self = this;

	this._selection.nodes = [];
	this._selection.relations = [];
	selectedNodes.each(function() {
		self.deselectNodeElement(this);
	});

	var selectedRelations = this.SVGElement.selectAll('.link-group.selected');
	selectedRelations.each(function() {
		self.deselectRelation(this);
	});
};

D3Graph.prototype.getVisibleNodes = function() {
	return this.getAllVisibleNodes();
};

D3Graph.prototype.getVisibleRelations = function() {
	return this.getAllVisibleRelations();
};

D3Graph.prototype.getSelectedNodes = function() {
	return this._selection.nodes;
};

D3Graph.prototype.getSelectedRelations = function() {
	return this._selection.relations;
};

D3Graph.prototype.getHiddenNodes = function() {
	return _.filter(this.data.nodes, {visible: false});
};

D3Graph.prototype.getNodesRelations = function(node) {
	return _.filter(this.data.relations, function(link) {
		if ((link.source.id == node.id) || (link.target.id == node.id)) {
			return true;
		}
	}, this);
};

D3Graph.prototype.highlightNode = function(node, nodeElement) {
	nodeElement = ensureD3Element(nodeElement);

	nodeElement.classed('hover', true).select('.node').style('stroke-width', (parseInt(node.style.lineWidth, 10) + 10) + 'px');

	this.relationBorderPaths.classed('hover', function(d) {
		if ((d.source.id == node.id) || (d.target.id == node.id)) {
			return true;
		}
		return false;
	});
};

D3Graph.prototype.unhighlightNode = function(node, nodeElement) {
	nodeElement = ensureD3Element(nodeElement);

	nodeElement.classed('hover', false).select('.node').style('stroke-width', (parseInt(node.style.lineWidth, 10)) + 'px');

	this.relationBorderPaths.classed('hover', false);
};

D3Graph.prototype.highlightRelation = function(relation, relationElement) {
	relationElement = ensureD3Element(relationElement);

	relationElement.classed('hover', true);

	this.nodeGroups.classed('hover', function(d) {
		if ((d.id == relation.source.id) || (d.id == relation.target.id)) {
			d3.select(this).select('.node').style('stroke-width', (parseInt(d.style.lineWidth, 10) + 10) + 'px');
			return true;
		}
		return false;
	});
};

D3Graph.prototype.unhighlightRelation = function(relation, relationElement) {
	relationElement = ensureD3Element(relationElement);

	relationElement.classed('hover', false);

	this.nodeGroups.classed('hover', false).select('.node').style('stroke-width', function(d) {
		return (parseInt(d.style.lineWidth, 10)) + 'px';
	});
};


D3Graph.prototype.isValidId = function(id) {
	if (!_.isSimpleValue(id)) {
		return false;
	}
	return true;
};

D3Graph.prototype.isValidIds = function(ids) {
	var self = this;
	if (!_.isArray(ids)) {
		return false;
	}

	var result = true;
	_.forEach(ids, function(id) {
		if (!self.isValidId(id)) {
			result = false;
			return false;
		}
	}, this);

	return result;
};

D3Graph.prototype.getNodesById = function(ids) {
	ids =_.ensureIsArray(ids);

	if (!this.isValidIds(ids)) {
		return false;
	}

	ids = _.sortBy(ids);

	var result = _.filter(this.getAllNodes(), function(node) {

		if (_.includesLoose(ids, node.id)) {
			return true;
		}

	}, this);

	return result;
};


D3Graph.prototype.getRelationsById = function(ids) {
	ids =_.ensureIsArray(ids);

	if (!this.isValidIds(ids)) {
		return false;
	}

	ids = _.sortBy(ids);

	var result = _.filter(this.getAllRelationsInternal(), function(relation) {
		if (_.includesLoose(ids, relation.id)) {
			return true;
		}
	}, this);

	return result;
};

D3Graph.prototype.getNodeById = function(id) {
	var result = this.getNodesById(id);

	if (_.isEmpty(result)) {
		return false;
	}

	return result[0];
};

D3Graph.prototype.getRelationById = function(id) {
	var result = this.getRelationsById(id);

	if (_.isEmpty(result)) {
		return false;
	}

	return result[0];
};

D3Graph.prototype.resetGraphNodeStyles = function(newNodeStyles) {
	this.styles.setDefinitions('node', newNodeStyles);
	return true;
};

D3Graph.prototype.resetGraphRelationStyles = function(newRelationStyles) {
	this.styles.setDefinitions('rel', newRelationStyles);
	return true;
};

D3Graph.prototype.isValidRelationStyle = function(style) {
	if (!_.isPlainObject(style)) {
		return false;
	}

	return true;
};

D3Graph.prototype.nodeHasLabel = function(node, label) {
	if (!this.isValidNode(node)) {
		console.error('D3Graph.nodeHasLabel: node is not valid', node);
		return false;
	}

	if (!_.isString(label)) {
		console.error('D3Graph.nodeHasLabel: label must be string', label);
		return false;
	}

	if (!_.has(node, 'labels') || !_.isArray(node.labels)) {
		return false;
	}

	if (_.includes(node.labels, label)) {
		return true;
	}

	return false;
};

D3Graph.prototype.addNodeLabel = function(node, label) {

	if (!this.isValidNode(node)) {
		console.error('D3Graph.nodeHasLabel: node is not valid', node);
		return false;
	}

	if (!_.isString(label)) {
		console.error('D3Graph.nodeHasLabel: label must be string', label);
		return false;
	}

	if (!_.has(node, 'labels') || !_.isArray(node.labels)) {
		return false;
	}

	node.labels.push(label);

	this.applyGraphStylesToNode(node);

	return true;
};

D3Graph.prototype.addLabelToNodes = function(nodes, label) {
	var self = this;
	if (!_.isString(label)) {
		console.error('D3Graph.addLabelToNodes: label should be string', label);
		return false;
	}

	nodes =_.ensureIsArray(nodes);

	_.forEach(nodes, function(node) {
		if (!self.isValidNode(node)) {
			console.error('D3Graph.addLabelToNodes: node is not valid', node);
			return;
		}

		this.addNodeLabel(node, label);
	}, this);
};


D3Graph.prototype.removeLabelFromNodes = function(nodes, label) {
	var self = this;
	if (!_.isString(label)) {
		console.error('D3Graph.removeLabelFromNodes: label should be string', label);
		return false;
	}

	nodes =_.ensureIsArray(nodes);

	_.forEach(nodes, function(node) {
		if (!self.isValidNode(node)) {
			console.error('D3Graph.removeLabelFromNodes: node is not valid', node);
			return;
		}

		this.removeNodeLabel(node, label);
	}, this);
};

D3Graph.prototype.removeNodeLabel = function(node, label) {
	if (!this.isValidNode(node)) {
		console.error('D3Graph.removeNodeLabel: node is not valid', node);
		return false;
	}

	if (!_.isString(label)) {
		console.error('D3Graph.removeNodeLabel: label must be string', label);
		return false;
	}

	if (!_.has(node, 'labels') || !_.isArray(node.labels)) {
		return false;
	}

	_.pull(node.labels, label);

	this.applyGraphStylesToNode(node);

	return true;
};

D3Graph.prototype.getNodesBySelector = function(selector) {
	var self = this;
	if (_.isString(selector)) {
		selector = GraphSelector.expandSelector(selector);
	}

	var allNodes = this.getAllNodes();
	var selectedNodes = [];
	_.forEach(allNodes, function(node) {
		if (!GraphSelector.matchNodeSelector(node, selector)) {
			return;
		}

		selectedNodes.push(node);
	}, this);

	return selectedNodes;
};

D3Graph.prototype.isVisible = function() {
	var rect = this.getSVG()[0].getBoundingClientRect();

	var result =  (
		rect.width > 0 &&
			rect.height > 0
	);

	return result;
};

D3Graph.prototype.isOnScreen = function() {
	var rect = this.getSVG()[0].getBoundingClientRect();

	var result =  (
		rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
			rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
			rect.bottom > 0 &&
			rect.right > 0
	);

	return result;
};

D3Graph.prototype.getUniqueNodeId = function() {
	var nodes = this.getAllNodes();

	do {
		var idIsUnique = true;
		var id = _.uniqueId();

		if (_.find(nodes, {id: id})) {
			idIsUnique = false;
		}

	} while ( ! idIsUnique);

	return id;
};


D3Graph.prototype.getUniqueRelationId = function() {
	var relations = this.getAllRelationsInternal();

	do {
		var idIsUnique = true;
		var id = _.uniqueId();

		if (_.find(relations, {id: id})) {
			idIsUnique = false;
		}

	} while ( ! idIsUnique);

	return id;
};

D3Graph.prototype.getNewNode = function(someNodeProperties) {
	var node = {
		id: this.getUniqueNodeId(),
		labels: [],
		properties: {}
	};

	node = _.extend({}, node, someNodeProperties);

	return node;
};

D3Graph.prototype.getNewRelation = function(someRelationProperties) {
	var relation = {
		id: this.getUniqueRelationId(),
		type: "",
		properties: {},
		source: null,
		target: null
	};

	relation = _.extend({}, relation, someRelationProperties);

	return relation;
};

D3Graph.prototype.cleanNodeObject = function(nodeObject) {
	let cleaned = _.clone(nodeObject);
	delete cleaned.style;
	delete cleaned.selected;
	delete cleaned.fixed;
	delete cleaned.x;
	delete cleaned.y;
	delete cleaned.px;
	delete cleaned.py;
	delete cleaned.visible;

	return cleaned;
};

D3Graph.prototype.cleanNodeObjects = function(nodeObjects) {
	return _.map(nodeObjects, node => this.cleanNodeObject(node));
};

D3Graph.prototype.cleanRelationObject = function(relationObject) {
	let cleaned = _.clone(relationObject);

	delete cleaned.style;
	delete cleaned.selected;
	delete cleaned.visible;
	delete cleaned.index;
	delete cleaned.weight;
	delete cleaned.linkCount;

	cleaned.source = this.cleanNodeObject(cleaned.source);
	cleaned.target = this.cleanNodeObject(cleaned.target);

	return cleaned;
};

D3Graph.prototype.cleanRelationObjects = function(relationObjects) {
	return _.map(relationObjects, node => this.cleanRelationObject(node));
};

D3Graph.prototype.getMainTransform = function() {
	var transform = this.graphContainer.attr('transform');
	return getTransformFromString(transform);
};

D3Graph.prototype.screenPositionToGraphPosition = function(screenPosition) {
	var graphPosition = {
		x: 0,
		y: 0
	};

	try {
		var graphBox = this.graphContainer.node().getBBox();
		var screenBox = this.graphContainer.node().getBoundingClientRect();
	}
	catch (exception) {
		console.error('Exception: ', exception);
		return graphPosition;
	}

	graphPosition.x = ((screenPosition.x - screenBox.left) * graphBox.width / screenBox.width) + graphBox.x;
	graphPosition.y = ((screenPosition.y - screenBox.top) * graphBox.height / screenBox.height) + graphBox.y;

	return graphPosition;
};

// ------------------------------------ Functions used by d3Graph -------------------------------------------------

// Create an array of relations with only one link for each node pair and all the relations of the node pair in "relationsList" property of each element of the returned array
var groupLinks = function(relations) {
	// Returns an unique identifier for a node pair (of a link)
	var nodePairId = function(link) {
		if (link.source.id < link.target.id) {
			return link.source.id + '-' + link.target.id;
		}
		return link.target.id + '-' + link.source.id;
	};

	var result = [];
	var link_group_indexes = {}; // Index of a node pair in result array

	if (!(relations instanceof Array)) {
		return result;
	}

	for(var index in relations) {
		var node_pair = nodePairId(relations[index]);

		// Create node_pair element if it does not exist and save it's index in link_group_indexes
		if (!(node_pair in link_group_indexes)) {
			link_group_indexes[node_pair] = result.push(relations[index]) - 1;
			result[link_group_indexes[node_pair]].relationsList = [];
		}
		// Add link to it's group
		result[link_group_indexes[node_pair]].relationsList.push(relations[index]);
	}
	return result;
};

// Determines relations that have the same pair of node, and sets the curvature of each relation
var setRelationsCurves = function(relations) {

	// Returns an unique identifier for a node pair (of a link)
	var getNodePairId = function(relation) {
		if (relation.source.id < relation.target.id) {
			return relation.source.id + '-' + relation.target.id;
		}
		return relation.target.id + '-' + relation.source.id;
	};

	if (!_.isArray(relations)) {
		return _.withError(['setRelationsCurves: relations must be an array of relations', relations]);
	}

	var relGroups = {};

	_.forEach(relations, function(relation) {
		delete relation.__curve;

		var nodePair = getNodePairId(relation);

		if (! _.has(relGroups, nodePair)) {
			relGroups[nodePair] = [relation];
			return;
		}

		relGroups[nodePair].push(relation);


	}, this);

	_.forEach(relGroups, function(relGroup) {
		if (! (relGroup.length > 1)) {
			return;
		}

		// Check if its a self relation group
		let selfRelation = relGroup.every(relation => {
			return relation.target.id === relation.source.id;
		});
		let selfRelationMultiplyFactor = (relGroup.length + 1) * 0.5;

		var relGroupLength = relGroup.length + 1;
		var prevRelation = null;
		_.forEach(relGroup, function(relation){
			relation.__curve = Math.floor(relGroupLength / 2);

			if (! (relGroupLength % 2) && (prevRelation) && (prevRelation.source === relation.source)) {
				relation.__curve = 0 - relation.__curve;
			}

			if (selfRelation) {
				relation.selfRelationMultiplyFactor = selfRelationMultiplyFactor;
				selfRelationMultiplyFactor -= 0.5;
			}

			prevRelation = relation;
			relGroupLength--;
		}, this);
	}, this);

	return relations;
};

// Cypher.php returns relations.source and relations.target as node array indexes, this function returns an array of relations with target and source as nodes (structures/pointers)
var getRelationsWithNodesPointers = function(data){
	var relations = [];
	data.relations.forEach(function(e) {
		// Get the source and target nodes
		if (_.isObject(e.source) && _.has(e.source, 'id')) {
			e.source = e.source.id;
		}

		if (_.isObject(e.target) && _.has(e.target, 'id')) {
			e.target = e.target.id;
		}

		var sourceNode = data.nodes.filter(function(n) { return n.id == e.source; })[0],
			targetNode = data.nodes.filter(function(n) { return n.id == e.target; })[0];

		if (sourceNode && targetNode){
			// Add the true source and target properties
			e.source = sourceNode;
			e.target = targetNode;

			// Add the edge to the array
			relations.push(e);
		}
	});
	return relations;
};
// Split a string into multiple rows to form a square
var splitStringToMultipleRows = function(string, aspectRatio) {
	if (!aspectRatio) {
		aspectRatio = 1;
	}

	if (!(typeof(string) == 'string')) {
		string = string.toString();
	}

	var   result = []
		, words = string.split(' ')
		, maxWordLength = 0
		, charsInRowHeight = 4
		, optimalRowLength = Math.round(charsInRowHeight * aspectRatio * Math.sqrt(string.length / charsInRowHeight))
		, rowLength
		, wordIndex;

		// get max word length
	for (wordIndex in words) {
		maxWordLength = Math.max(maxWordLength, words[wordIndex].length);
	}

	rowLength = Math.max(maxWordLength, optimalRowLength);
	// create the rows
	var currentWord = '';
	for (wordIndex in words) {
		if ((currentWord.length > 0) && (currentWord.length + words[wordIndex].length + 1) > rowLength) {
			result.push(currentWord);
			currentWord = '';
		}
		if (currentWord.length > 0) {
			currentWord += ' ';
		}
		currentWord += words[wordIndex];
	}
	result.push(currentWord);

	return result;
};

function isD3Selection(selection) {
	return (selection instanceof d3.selection);
}

function ensureD3Selection(selection) {
	if (!isD3Selection(selection)) {
		return this.SVGElement.selectAll(selection);
	}
	return selection;
}

function ensureD3Element(selection) {
	if (!isD3Selection(selection)) {

		return d3.select(selection);
	}
	return selection;
}

function htmlReferenceById(id) {
	return 'url(#' + id + ')';
}

function getTransformFromString(transformStr) {
	if (!_.isString(transformStr)) {
		console.error('getTransformFromString: stransformStr must be string', transformStr);
		return false;
	}

	var values = /translate\(([\-\+0-9.]+),([\-\+0-9.]+)\).*scale\(([\-\+0-9.]+)\)/.exec(transformStr);
	if (values.length != 4) {
		return false;
	}
	return {
		x: parseFloat(values[1], 10),
		y: parseFloat(values[2], 10),
		scale: parseFloat(values[3], 10)
	};
}

function d3EventPreventDefault() {
	if (PREVENT_DEFAULT_CONTEXT_MENUS) {
		d3.event.preventDefault();
	}
}

function validateSize(size) {
	var validEmptySize = {
		width: 0,
		height: 0
	};

	if ( ! _.isPlainObject(size)) {
		return validEmptySize;
	}

	if ( ! _.has(size, 'width') || ! _.isNumeric(size.width)) {
		size.width = 0;
	}

	if ( ! _.has(size, 'height') || !_.isNumeric(size.height)) {
		size.height = 0;
	}

	return size;
}

function getCurveFactor(curveLevel) {
	curveLevel = Math.floor(Math.abs(curveLevel));

	if (! (curveLevel > 0)) {
		return 0;
	}

	if (curveLevel > CURVE_FACTOR.length) {
		return 0.5;
	}

	return CURVE_MULTIPLIER * CURVE_FACTOR[curveLevel];
}

function getBeforeContentForCSSClass(cssClass) {
	var $span = $('<span></span>').addClass(cssClass);

	$span.appendTo('body');
	var content = window.getComputedStyle($span[0], ':before').getPropertyValue('content');
	$span.remove();

	content = content.replace(/"/gi, "");
	return content;
}

function drawShape(shape, width, height) {
	let draw;
	switch(shape) {
		case 'square':
			draw = "M -h,-h l w,0 l 0,w l -w,0 l 0,-w z"
			.replace(/w/g, width)
			.replace(/h/g, width / 2);
			break;
		default:
			console.error(`drawShape: '${shape}' is not defined in NetworkView.`);
		case 'circle':
			draw = "M 0 0 m -r, 0 a r,r 0 1,0 w,0 a r,r 0 1,0 -w,0"
			.replace(/r/g, width / 2)
			.replace(/w/g, width);
			break;
		case 'triangle':
			draw = "M -h,-hh l w,0 l -h,th l -h,-th z"
			.replace(/hh/g, width / 4)
			.replace(/th/g, width * 0.866)
			.replace(/w/g, width)
			.replace(/h/g, width / 2);
			break;
		case 'rectangle':
			draw = "M -hw,-hh l w,0 l 0,h l -w,0  l 0,-h z"
			.replace(/hh/g, height / 2)
			.replace(/hw/g, width / 2)
			.replace(/w/g, width)
			.replace(/h/g, height);
			break;
		case 'diamond':
			draw = "M -w,0 l w,-h l w,h l -w,h l -w,-h z"
			.replace(/w/g, width / 2)
			.replace(/h/g, height / 2);
			break;
		case 'ellipse':
			draw = "M 0,-r a w,r 0 1,0 1,0 z"
			.replace(/r/g, height / 2)
			.replace(/w/g, width / 2);
			break;
		case 'hexagon':
			draw = "M -w,0 l ww,-h l w,0 l ww,h l -ww,h l -w,0 z"
			.replace(/ww/g, width / 4)
			.replace(/w/g, width / 2)
			.replace(/h/g, height / 2);
			break;
		case 'octagon':
			draw = "M -w,hh l 0,-h l ww,-h l w,0 l ww,h l 0,h l -ww,h l -w,0 z"
			.replace(/hh/g, height / 6)
			.replace(/ww/g, width / 4)
			.replace(/w/g, width / 2)
			.replace(/h/g, height / 3);
			break;	
	}
	return draw;
}

addHooksTrait(D3Graph, 'D3Graph');

module.exports = D3Graph;
