const ViewRenderer = require('client/src/view-renderer');
const log = require('core/src/log').instance("function/gridview/renderer");
const DataTable = require('core/src/data-table');
const Mustache = require('mustache');

const _ = require('core/src/utils/legacy');

/* Inheritance and constructor */

const GridViewRenderer = function () {
	ViewRenderer.call(this);
	this._jqxOptions = {};
	this._options = {};
};
GridViewRenderer.viewType = 'GridView';
GridViewRenderer.prototype = Object.create(ViewRenderer.prototype);

/* PROPERTIES */

GridViewRenderer.prototype._context = null;
GridViewRenderer.prototype._table = null;
GridViewRenderer.prototype._dataTable = null;
GridViewRenderer.prototype._data = null;
GridViewRenderer.prototype._columns = null;
GridViewRenderer.prototype._options = null;
GridViewRenderer.prototype._jqxOptions = null;
GridViewRenderer.prototype._toolbar = null;
GridViewRenderer.prototype._clickedRow = null;
GridViewRenderer.prototype._rowDoubleClickTimeoutMs = 300; //ms
/* METHODS */

GridViewRenderer.prototype.getBatchSelection = function() {
	return this.getSelection();
};

GridViewRenderer.prototype.getSelection = function () {
	var self = this;
	var idxs = this._grid.jqxGrid('getselectedrowindexes');
	var rows = [];
	_.forEach(idxs, function (idx) {
		var jqxRow = self._grid.jqxGrid('getrowdatabyid', idx);
		var dataRow = _.get(self._data, jqxRow.uid);
		if (_.def(dataRow)) {
			rows.push(dataRow);
		}
	});
	return rows;
};

GridViewRenderer.prototype.getVisible = function () {
	var self = this;
	var visibleRows = this._grid.jqxGrid('getrows');
	var visibleData = [];
	_.forEach(visibleRows, function (row) {
		var dataRow = _.get(self._data, row.uid);
		if (_.def(dataRow)) {
			visibleData.push(dataRow);
		}
	});
	return visibleData;
};

GridViewRenderer.prototype.updateState = function () {
	var selection = this.getSelection();
	var visible = this.getVisible();

	var state = {
		selected: selection,
		visible: visible
	};
	state[Function.VALUE_OBJECT] = true; // make sure entire state gets replaced instead of merged
	return this.requestUpdate({state: state});
};

/**
 *  * Renders table view
 * data = {
*	 #data = [{key1: val1, key2:val2}, {key1: val3, key2: val4}, ...]
*	 columns: {
*		  column_name: {
*			  values : 'key1'
*			  label: 'Column label'
*			  index: 1
*			  width: 10 //not implemented
*			  sortable: true,
*			  hidden: false,
*			  autoCellHeight: true
*		  }
*	 }
* }
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
GridViewRenderer.prototype.doRender = function (renderData) {
	var self = this;

	var data = renderData.data;
	var options = renderData.options;
	var columns = renderData.columns;
	var hiddenColumns = renderData.hiddenColumns;
	var autoColumns = renderData.autoColumns;


	columns = _.omit(columns, hiddenColumns);
	data = _.map(data, row => _.omit(row, hiddenColumns));

	this._columns = columns;
	this._row = renderData.row;

	if (!_.isObject(options)) {
		options = {};
	}

	// For simple arrays, convert to grid with empty string as column header
	if (_.isArray(data)) {
		_.forEach(data, function (item, i) {
			if (!_.isObject(item)) {
				data[i] = {' ': item};
			}
		});
	}

	this._dataTable = new DataTable(data, columns, autoColumns);

	// Transform data
	this._data = this._dataTable.generateTableDataForJqxTable();

	// Set cell renderer
	var cellsrenderer = function (row, column, value) {
		return self._cellrenderer(row, column, value);
	};

	// Data formats
	var defs = this._createJqxColumnDefinitions(this._dataTable, cellsrenderer);

	// Prepare data source
	var source = {
		localData: this._data,
		dataType: "json",
		datafields: defs.datafields
	};
	var dataAdapter = new $.jqx.dataAdapter(source);
	dataAdapter.dataBind();

	// Create containing div
	var div = $('<div class="GridViewRenderer">');

	// Selection mode
	var selectionMode = options.selectionMode || "singlerow";

	// Default grid options
	var defaultOptions = {
		source: dataAdapter,
		columns: defs.columns,
		width: '100%',
		sortable: true,
		columnsresize: true,
		selectionmode: selectionMode,
		pageable: false,
		enablebrowserselection: true,
		autorowheight: true,
		autoheight: true
	};
	var jqxOptions = _.clone(defaultOptions);

	// Overwrite options with user input
	var fixedOptions = ['source', 'columns'];
	for (var i in options) {
		if (fixedOptions.indexOf(i) < 0) { // only non-fixed options can be overwritten
			jqxOptions[i] = options[i];
			delete options[i];
		}
	}

	var grid;
	var initialized = false;
	var i = 0;
	while (!initialized && i++ < 500) { // try to set options (with maximum of 500 failed options)
		try {
			grid = $('<div>'); // create new element, if there was a previous, it has been corrupted
			// Try to set up jqxGrid with given options
			grid.jqxGrid(jqxOptions);
			initialized = true;
		}
		catch (err) {
			// If jqx complains, remove responsible option
			var retry = true;
			var match = /invalid property '(.*)'$/.exec(err);
			if (match === null || !_.has(jqxOptions, match[1])) {
				retry = false;
			} else {
				var prop = match[1];
				this._options[prop] = jqxOptions[prop];
				log.error("jqxGrid option '" + prop + "' was not accepted.");
				delete(jqxOptions[prop]);
			}

			if (!retry) {
				grid = $('<div>'); // create new element, previous was corrupted
				grid.jqxGrid(jqxOptions);
				log.error("Error setting jqxGrid options: ", err);
				initialized = true;
			}
		}
	}
	div.append(grid);

	this._setupEvents(grid);

	this._grid = grid;

	return div;
};

GridViewRenderer.prototype.doResize = function(width, height) {
	this._grid.jqxGrid('render');
};

/**
 * @override
 */
GridViewRenderer.prototype.doUpdate = function (model, changes) {
	var self = this;

	if(!('data' in changes || 'columns' in changes)) {
		// nothing to update
		return;
	}

	// Replace data
	this._dataTable = new DataTable(model.data, model.column);
	var newData = this._dataTable.generateTableDataForJqxTable();
	this._data.length = 0;
	_.forEach(newData, function (row) {
		self._data.push(row);
	});
	this._grid.jqxGrid('updatebounddata');


	// Update selected rows to match model.state.selected
	this._grid.jqxGrid('clearselection');
	_.forEach(
		_.get(model, 'state.selected'),
		function (selectedRow) {
			var rowIndex = _.findIndex(self._data, selectedRow);
			if (!(rowIndex > -1)) {
				return;
			}
			self._grid.jqxGrid('selectrow', rowIndex);
		}
	);
};

/* Private */

GridViewRenderer.prototype._getKeyPressed = function (event) {
	// Using === true for explicit boolean (undefined will become 'false')
	return {
		ctrl: _.get(event, 'ctrlKey') === true,
		meta: _.get(event, 'metaKey') === true,
		shift: _.get(event, 'shiftKey') === true
	};
};

GridViewRenderer.prototype.cleanRowData = function (data) {
	var newData = _.cloneDeep(data);
	delete newData.uid;
	delete newData.boundindex;
	delete newData.uniqueid;
	delete newData.visibleindex;
	return newData;
};

GridViewRenderer.prototype._setupEvents = function (grid) {
	var self = this;

	var rowClickTimeout = null;
	var lastClickedRowUID = null;

	var setLastClickedRowId = function (rowId) {
		lastClickedRowUID = rowId;
	};

	var isLastClickedRowId = function (rowId) {
		return lastClickedRowUID === rowId;
	};

	var clearRowClickTimeout = function () {
		if (!rowClickTimeout) {
			return false;
		}

		setLastClickedRowId(null);
		clearTimeout(rowClickTimeout);
		rowClickTimeout = null;

		return true;
	};


	// Row click event
	grid.on('rowclick', function (event) {
		var jqxRow = _.clone(event.args.row);
		var rowIdx = jqxRow.bounddata.boundindex;
		var rowData = self.cleanRowData(jqxRow.bounddata);
		// Left Click, send row data with click event
		if (event.args.originalEvent.button == 0) {
			//If fast clicking between rows normal click must be triggered
			if (!isLastClickedRowId(rowIdx)) {
				clearRowClickTimeout();
			}
			setLastClickedRowId(rowIdx);

			// This event is triggered on mouse down. To prevent rowclick when selecting text, save the clicked row for
			// when the click is completed (jQuery click event).
			self._clickedRow = rowData;
			// Let jqxGrid first update its selection
			setTimeout(function () {
				self.updateState(); // update selection in model
			}, 0);
		}
		// Right click, open context menu
		else if (event.args.originalEvent.button == 2) {
			var x = event.args.originalEvent.pageX;
			var y = event.args.originalEvent.pageY;
			self.openContextMenu('row', rowData, x, y);
			event.preventDefault();
		}
	});

	// Row double-click event
	grid.on('rowdoubleclick', function (event) {
		var jqxRow = _.clone(event.args.row);
		var rowData = self.cleanRowData(jqxRow.bounddata);
		// Left Click, send row data with click event
		if (event.args.originalEvent.button == 0) {
			self.trigger({
				type: "rowDoubleClick",
				data: rowData,
				keyPressed: self._getKeyPressed(event)
			});
		}
	});

	// The actual left-click (release) event
	grid.on('click', '.jqx-grid-cell', function (event) {
		var selection = _.getSelectedText();
		if (_.isString(selection) && selection.length > 0) {
			return;
		}
		// If previous click did not execute (was canceled successfully) it means it is a double-click
		if (clearRowClickTimeout()) {
			return;
		}

		rowClickTimeout = setTimeout(
			function () {
				clearRowClickTimeout();

				if (!_.def(self._clickedRow)) {
					return;
				}
				self.updateState(); // update selection in model
				self.trigger({
					type: "rowClick",
					data: self._clickedRow,
					keyPressed: self._getKeyPressed(event)
				});
				self._clickedRow = null;
			},
			self._rowDoubleClickTimeoutMs
		);

	});

	// Cell click event
	grid.on('cellclick', function (event) {
		var jqxRow = _.clone(event.args.row);
		var rowData = self.cleanRowData(jqxRow.bounddata);
		// Left Click, send row data with click event
		if (event.args.originalEvent.button == 0) {
			self.trigger({
				type: "cellClick",
				data: event.args.value,
				row: rowData,
				column: event.args.column.datafield,
				keyPressed: self._getKeyPressed(event)
			});
		}
	});


	// Filtering
	grid.on('filter', function (event) {
		self.updateState();
	});

	// Selection
	grid.on('rowselect rowunselect', function (event) {
		self.updateState();
	});

	// Disable browser context menu for tableview
	grid.on('contextmenu', '.jqx-grid-content > div', function (e) {
		return false;
	});
	grid.on('contextmenu', '.context-menu-modal', function (e) {
		return false;
	});
};

GridViewRenderer.prototype._getColumnType = function (columnName) {
	var numeric = true;
	_.find(this._data, function (row) {
		var value = _.get(row, columnName);
		/*
		Numeric strings must:
			- only have numbers
			- not be empty
			- not start with a zero (unless float)
		 */
		numeric = numeric && (
			_.isNumber(value) || (
				_.isNumeric(value)
				&& ("" + value) !== parseFloat(value).toString() // 0.5 is fine, 00.5 is not
			)
		);
		return !numeric;
	});
	// Only need distinction between number and string (objects are handled fine when type is string).
	return numeric ? 'number' : 'string';
};

GridViewRenderer.prototype._createJqxColumnDefinitions = function (dataTable, cellsrenderer) {
	var self = this;
	var columns = dataTable.getColumns();
	var orderedColumns = dataTable.getOrderedColumns();

	var colDefs = [];
	var datafields = [];

	_.forEach(orderedColumns, function (columnReference) {
		var column = columns[columnReference.identifier];
		var datafield = {name: columnReference.identifier};
		if (_.def(column.type)) {
			datafield.type = column.type;
		} else {
			datafield.type = self._getColumnType(columnReference.identifier);
		}
		datafields.push(datafield);
		var columnDef = {
			text: column.label || self._dataTable.columnLabelFromIdentifier(columnReference.identifier),
			datafield: columnReference.identifier,
			cellsrenderer: cellsrenderer,
			cellclassname: self.getCellClassNameRenderer(columnReference.identifier)
		};

		columnDef = _.extend(columnDef, column);

		colDefs.push(columnDef);

	}, this);

	return {
		columns: colDefs,
		datafields: datafields
	};
};

GridViewRenderer.prototype._getCellClass = function (row, column) {
	var classColumn = _.get(this._columns, column + '.cellClassNameColumn');
	return _.get(this._data, row + '.' + classColumn);
};

GridViewRenderer.prototype._getRowClass = function (row) {
	var classColumn = _.get(this._row, 'rowClassNameColumn');
	return _.get(this._data, row + '.' + classColumn);
};

/**
 * Returns a jqxGrid column.cellClassName function if cellClassNameColumn is set for a column
 */
GridViewRenderer.prototype.getCellClassNameRenderer = function (columnName) {
	var classColumn = _.get(this._columns, [columnName, 'cellClassNameColumn']);

	if (!classColumn) {
		return undefined;
	}

	return _.bind(this.getCellClassName, this, classColumn);
}

GridViewRenderer.prototype.getCellClassName = function (classColumn, row, column, value, data) {
	var className = _.get(data, classColumn);
	return _.isNil(className) ? '' : className.toString();
}

/**
 * Renders a cell for a jqxGrid.
 * @param {int} row The row index of the cell.
 * @param {string} column The column name of the cell.
 * @param {mixed} value The value inside the cell.
 * @returns {string} HTML of the rendered cell
 */
GridViewRenderer.prototype._cellrenderer = function (row, column, value) {
	var content = $('<div class="cell-content">');
	var format = _.get(this._columns, 'column.format');

	var rendered;
	var template = _.get(this._columns, [column, 'template']);
	if (_.isString(template)) {
		rendered = Mustache.render(template, {cell: value, row: _.get(this._data, row)});
	}
	else {
		rendered = this._renderValue(value, format);
	}

	content.append(rendered);
	var cellClass = this._getCellClass(row, column);
	if (_.def(cellClass)) {
		content.addClass("" + cellClass);
	}
	var rowClass = this._getRowClass(row);
	if (_.def(rowClass)) {
		content.addClass("" + rowClass);
	}

	return $('<div>').append(content).html();
};

/**
 * Render a value. If the value is an Object or Array, a table will be rendered.
 * @param {mixed} value
 * @param {string} [format]
 * @returns {mixed} A rendered version of the value.
 */
GridViewRenderer.prototype._renderValue = function (value, format) {
	if (this._isPlainValue(value)) {
		if (_.hasBooleanValue(this._options['escapeHtml'], false)) {
			return value;
		} else {
			var escaped = $("<div>").text(value).html();
			return escaped;
		}
	} else if (value instanceof Date) {
		if (!_.isString(format)) {
			format = dateFormat.masks.isoDateTime;
		}
		return value.format(format);
	} else {
		return this._renderTable(value);
	}
};

/**
 * Checks if the value can be printed as-is, or if it needs to be displayed inside a table.
 * @param value
 * @returns {boolean}
 * @private
 */
GridViewRenderer.prototype._isPlainValue = function (value) {
	var plainValueObjects = [
		Number
	// add more if necessary
	];
	var isPlainValue = false;
	plainValueObjects.forEach(function (pvo) {
		if (value instanceof pvo) {
			isPlainValue = true;
		}
	});
	return isPlainValue || (
		!_.isObject(value) && !_.isArray(value));
};

/**
 * Render a table of an Object or Array.
 * @param {Object|Array} object
 * @return {jQuery} jQuery object of table.
 */
GridViewRenderer.prototype._renderTable = function (object) {
	var isObject = _.isObject(object) && !_.isArray(object);

	if (_.isCyclic(object)) {
		return '[cyclic object]';
	}

	var table = $('<table>').attr('cellspacing', 0).attr('cellpadding', 0);
	table.addClass('subtable');
	for (var i in object) {
		var row = $('<tr>');

		// Only show keys for object, not arrays
		if (isObject) {
			var keyCell = $('<td>').addClass('key');
			keyCell.html(i);
			row.append(keyCell);
		}

		// Show value
		var valueCell = $('<td>').addClass('value');
		var value = this._renderValue(object[i]);
		valueCell.html(this._renderValue(object[i]));
		row.append(valueCell);

		table.append(row);
	}

	var html = $('<div>').append(table).html(); // get html
	return html;
};

module.exports = GridViewRenderer;