var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "team-edit-view prologram-viewrenderer-output" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.team.id
            ? _c("div", { staticClass: "description" }, [
                _vm._v("ID: " + _vm._s(_vm.team.id)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            { staticClass: "form-metarial" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.error,
                        expression: "error",
                      },
                    ],
                    staticClass: "error team-edit-error",
                  },
                  [_vm._v(_vm._s(_vm.t(_vm.error)))]
                ),
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.success,
                        expression: "success",
                      },
                    ],
                    staticClass: "info-message team-edit-success",
                  },
                  [_vm._v(_vm._s(_vm.t(_vm.success)))]
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.team.id,
                    expression: "team.id",
                  },
                ],
                staticClass: "team-id",
                attrs: { type: "hidden" },
                domProps: { value: _vm.team.id },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.team, "id", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "label",
                  { staticClass: "col-md-3", attrs: { for: "name" } },
                  [_vm._v(_vm._s(_vm.t("Team Name")))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-9" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.team.properties.name,
                        expression: "team.properties.name",
                      },
                    ],
                    staticClass: "form-control input-sm",
                    attrs: { type: "text", name: "name" },
                    domProps: { value: _vm.team.properties.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.team.properties,
                          "name",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.team.id
            ? _c("div", { staticClass: "select-user" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" " + _vm._s(_vm.t("Add User")))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("vue-bootstrap-typeahead", {
                        attrs: {
                          data: _vm.allUsersData,
                          serializer: (s) => s.name,
                          placeholder: _vm.t("Type user name") + "...",
                          label: _vm.t("Add User"),
                          size: "sm",
                        },
                        on: {
                          hit: function ($event) {
                            _vm.selectedUser = $event.id
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "addUser btn btn-success pull-right",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addUser(_vm.id)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" " + _vm._s(_vm.t("Add"))),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.team.id
            ? _c("ag-grid-vue", {
                ref: "grid",
                staticClass: "ag-grid-vue ag-theme-balham",
                attrs: {
                  deltaRowDataMode: true,
                  suppressAsyncEvents: true /* otherwise we cannot prevent default context menu */,
                  floatingFilter: true,
                  pagination: true,
                  paginationAutoPageSize: true,
                  resizeColumnsToFit: true,
                  enableSorting: true,
                  enableColResize: true,
                  rowData: _vm.teamUsersData,
                  getRowNodeId: _vm.getRowNodeId,
                  defaultColDef: _vm.defaultColumnDefinitions,
                  columnDefs: _vm.columnDefinitions,
                  gridReady: _vm.onGridReady,
                  gridSizeChanged: _vm.onResize,
                  frameworkComponents: _vm.frameworkComponents,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.team.id && _vm.allTeams
            ? _c("div", { staticClass: "select-team" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("label", [_vm._v(" " + _vm._s(_vm.t("Add Team")))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-7" },
                    [
                      _c("vue-bootstrap-typeahead", {
                        attrs: {
                          data: _vm.allTeamsData,
                          serializer: (s) => s.name,
                          placeholder: _vm.t("Type team name") + "...",
                          size: "sm",
                        },
                        on: {
                          hit: function ($event) {
                            _vm.selectedTeam = $event.id
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "addTeam btn btn-success pull-right",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addTeam(_vm.id)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-plus" }),
                        _vm._v(" " + _vm._s(_vm.t("Add"))),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.team.id
            ? _c("ag-grid-vue", {
                ref: "teamGrid",
                staticClass: "ag-grid-vue ag-theme-balham",
                attrs: {
                  deltaRowDataMode: true,
                  suppressAsyncEvents: true /* otherwise we cannot prevent default context menu */,
                  floatingFilter: true,
                  pagination: true,
                  paginationAutoPageSize: true,
                  enableSorting: true,
                  enableColResize: false,
                  getRowNodeId: _vm.getRowNodeId,
                  rowData: _vm.teamTeamsData,
                  defaultColDef: _vm.defaultColumnDefinitions,
                  columnDefs: _vm.teamColumnDefinitions,
                  gridReady: _vm.onTeamGridReady,
                  gridSizeChanged: _vm.onResize,
                  frameworkComponents: _vm.frameworkComponents,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "actions cf align-right" }, [
            _vm.team.id
              ? _c(
                  "button",
                  {
                    staticClass: "save btn btn-danger waves-effect waves-light",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.remove()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-trash" }),
                    _vm._v(" " + _vm._s(_vm.t("Delete Team"))),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "cancel btn btn-warning waves-effect waves-light",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.close()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-times" }),
                _vm._v(" " + _vm._s(_vm.t("Cancel"))),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "save btn btn-success waves-effect waves-light",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-save" }),
                _vm._v(" " + _vm._s(_vm.t("Save"))),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }