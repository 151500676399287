var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "markdown-view prologram-viewrenderer-output" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "markdown-it-vue",
            {
              ref: "myMarkdownItVue",
              staticClass: "md-body",
              attrs: { options: _vm.options, content: _vm.markdown },
            },
            [_vm._v(_vm._s(_vm.markdown))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }