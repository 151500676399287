import Graphileon from 'core/src/graphileon';
import ApiClientAbstract from 'core/src/api-client-abstract';
import ISession from 'core/src/i-session';

import Router from './router';
import ViewManager from './view-manager';
import Log from 'utils/src/log';
import _ from 'lodash';
import setupDefaultClientDependencies from './default-dependencies';
import setupDefaultRenderers from './renderers/all';
import { safeParseNumber } from 'utils/src/validation';
import { isTrue } from 'core/src/utils/validation';
import requireScript from 'client/src/utils/requireScript';

import GlobalTimeout from 'core/src/utils/global-timeout';

const GLOBAL_SESSION_TIMEOUT_IDENTIFIER = 'graphileon-session-expire-timeout-at';
const GLOBAL_SESSION_TIMEOUT_WARNING_IDENTIFIER = 'graphileon-session-expire-warning-timeout-at';

const log = Log.instance("frontend/client");

try {
	// Styles
	require('./css/prologram.css');
	// jQuery plugins
	require('client/libraries/jquery-plugins');
} catch(e) {
	log.error("Could not load styles.");
}

export default class GraphileonClient extends Graphileon {
	static async loadExternalResources(resourceKeys) {
		requireScript(`https://maps.googleapis.com/maps/api/js?v=3.exp&signed_in=true&key=${resourceKeys.googleMapsKey}&libraries=places`).catch(()=>{}); // logging is already taken care of
		requireScript("https://www.gstatic.com/charts/loader.js").catch(()=>{}); // logging is already taken care of
		requireScript("https://www.google.com/jsapi").catch(()=>{}); // logging is already taken care of
		log.log("Loaded external resources.");
	}

	constructor(config = {}, dependencies) {
		super(
			setupDefaultClientDependencies(dependencies, config.nodeSafe),
			config
		);

		// Grab what we need
		this.viewmanager = this.dependencies.get(ViewManager);
		this.session = this.dependencies.get(ISession);
		this.api = this.dependencies.get(ApiClientAbstract);
		this.router = this.dependencies.get(Router);

		this.router.config = {...this.router.config, ...this.config.router};

		this.setupViewManager(this.viewmanager);
		this.setupEvents();
	}

	setupViewManager(viewmanager) {
		viewmanager.connectFTI(this.fti);
		viewmanager.on('getOriginFunction', (event) => {
			this.fire(Graphileon.Event.GET_ORIGIN_FUNCTION, event);
		});
		viewmanager.on('userViewAccountButton', (event) => {
			this.fire(Graphileon.Event.USER_VIEW_ACCOUNT_BUTTON, event);
		});
		viewmanager.on('userViewInfoButton', (event) => {
			this.fire(Graphileon.Event.USER_VIEW_INFO_BUTTON, event);
		});
		if(!this.config.nodeSafe) {
			setupDefaultRenderers(viewmanager);
		}
	}

	setupEvents() {
		this.on(Graphileon.Event.ERROR_CONNECTION, (error) => {
			this.viewmanager.alert(error.message + '<br/><br/><a target="_blank" href="http://docs.graphileon.com/graphileon/Troubleshooting.html#page_Cannot_connect_to_the_graph_store">Troubleshooting connection issues</a>', {headerText: 'Connection Error'});
		});
		this.on(Graphileon.Event.ERROR_LICENSE_BROKEN, this.onLicenseError.bind(this));
		this.on(Graphileon.Event.ERROR_LICENSE_EXPIRED, this.onLicenseError.bind(this));
		this.on(Graphileon.Event.AUTOLOGIN_FAILED, this.onAutoLoginFailed.bind(this));
		this.on(Graphileon.Event.CONTENT_LOADED, this.onContentLoaded.bind(this));
		this.on(Graphileon.Event.ENVIRONMENT_LOADED, this.onEnvironmentLoaded.bind(this));
		this.on(Graphileon.Event.LOGIN, this.onLogin.bind(this));
		this.on(Graphileon.Event.LOGOUT, this.onLogout.bind(this));
		this.on(Graphileon.Event.USER_ACTIVATED, this.onUserActivated.bind(this));
		this.on(Graphileon.Event.USER_ACTIVATION_FAILED, this.onUserActivationFailed.bind(this));
		this.on(Graphileon.Event.PASSWORD_RESET, this.onPasswordReset.bind(this));
		this.on(Graphileon.Event.DASHBOARD_CLOSED, this.onDashboardClosed.bind(this));
		this.on(Graphileon.Event.ERROR_DASHBOARD_LOAD_FAILED, this.onDashboardLoadFailed.bind(this));
		this.on(Graphileon.Event.STYLE_ERROR, error => {
			this.viewmanager.alert(error.message, {headerText: 'Style Error'});
		});
	}

	startRouter() {
		// Routing
		this.router.onDashboardPage(event => {
			let dashboardName = _.get(event, 'dashboardName');
			let bookmarkName = _.get(event, 'bookmarkName');
			let params = _.get(event, 'params');

			// If dashboard name is a numeric ID
			const numericID = safeParseNumber(dashboardName);
			if(numericID !== null) {
				dashboardName = numericID;
			}
			this.loadDashboard(dashboardName, bookmarkName, params).catch(e => log.error("Could not load dashboard", e));
		});
		this.router.onDefaultDashboard(async () => {
			try {
				await this.loadDefaultDashboard();
			} catch(e) {
				log.error("Could not load default dashboard.", e);
			}
		});
		this.router.onUnresolved(() => {
			this.closeDashboard();
			this.fire(GraphileonClient.Event.ROUTE_UNRESOLVED);
		});
		this.router.onActivateUserPage(async event => {
			try {
				let token = _.get(event, 'token');
				let activated = await this.api.activateUser(token);
				log.log("User activated.");
				if (activated){
					this.fire(Graphileon.Event.USER_ACTIVATED, {user: activated.user, token});
				}
			} catch (e) {
				this.fire(Graphileon.Event.USER_ACTIVATION_FAILED, { error: e });
			}
		});
		this.router.onResetPasswordPage(async event => {
			let token = _.get(event, 'token');
			this.fire(Graphileon.Event.PASSWORD_RESET, { token });
		});
		log.log("Starting client router...");
		this.router.start();
	}

	/**
	 * @override
	 *
	 * @param dashboard
	 * @param bookmark
	 * @param params
	 * @param [changeUrl]	Default: true. Changes the URL to the loaded dashboard.
	 */
	async loadDashboard(dashboard, bookmark, params, changeUrl) {
		let dashboardInfo = await super.loadDashboard(dashboard, bookmark, params);

		// Navigate router to the dashboard as well (just by URL)
		if(changeUrl && _.isString(dashboard) && _.get(this.config, 'router.enabled')) {
			log.log("Dashboard loaded. Updating URL.");
			// The dashboard has already loaded, we just want to update the URL and not trigger another dashboard load.
			this.router.pause();
			this.router.goToDashboard(dashboard, bookmark, params);
			setTimeout(()=>{ // callbacks are called in next event loop
				this.router.resume();
			});
		}
		return dashboardInfo;
	}

	requireScript(url, local = false) {
		if(local) {
			url = this.api.getServer() + url;
		}
		return requireScript(url);
	}

	loadScripts() {

	}

	onDashboardClosed() {
		this.viewmanager.closeOrphanRenderers();
	}

	async onDashboardLoadFailed(error) {
		if (this.config.handleDashboardLoadError){
			this.config.handleDashboardLoadError(error);
		} else {
			await this.viewmanager.alert("Could not load dashboard.", {headerText: 'Dashboard ' + (error.data ? `'${error.data}'` : ''), width: '700px'});
			this.router.goHome();
		}
	}

	onContentLoaded() {
		if(!this.router.started && _.get(this.config, 'router.enabled')) {
			log.log("Content loaded; starting router.");
			this.startRouter();
			return;
		}
		log.log("Content loaded; reloading router.");
		this.router.reload();
	}

	onAutoLoginFailed() {
		// Start router for any public dashboard there may be available
		if(_.get(this.config, 'router.enabled')) {
			this.startRouter();
		}
	}

	onLicenseError(msg) {
		if (this.showingLicenseError) {
			return;
		}
		this.showingLicenseError = true;
		this.viewmanager.alert(msg, {headerText: 'License check', modalSize: 'modal-md'})
			.finally(() => (this.showingLicenseError = false))
			.catch(() => true)
	}

	onEnvironmentLoaded(environment) {
		this.appInfo.backend = GraphileonClient.getDefaultBackendUrl();
		Graphileon.setLogLevels(environment.logLevels);

		const serverUrl = this.api.getServer();
		this.loadScripts();

		if(environment.loadExternalResources) {
			GraphileonClient.loadExternalResources({googleMapsKey: this.appInfo.googleMapsKey});
		}
	}

	onLogin({user, automatic}) {
		this.initSessionTimeout();
		this.viewmanager.setDevMode(isTrue(user.properties.devMode));
		this.closeAuthWindow();
	}

	closeAuthWindow() {
		let authWindow = window.localStorage.getItem('authWindow');
		if (!authWindow) return;
		let tab = window.open(authWindow, authWindow);
		tab.close();
		localStorage.removeItem('authWindow');
	}

	onLogout() {
		this.removeSessionTimout();
	}

	onUserActivated({ user, token }){
		this.viewmanager.alert(`User "${user.properties.name}" is activated. Please set a new password to log-in.`, {headerText: "User Activation"});
		this.executeSetPasswordView(token);
	}

	onUserActivationFailed({ error }){
		this.viewmanager.alert(`${error}. Please contact your administrator.`, { headerText: "User Activation Failed" });
	}

	onPasswordReset({ token }){
		this.executeSetPasswordView(token);
	}

	initSessionTimeout() {
		if (! this.getSessionTimeout() > 0) {
			return;
		}

		GlobalTimeout.add({
			identifier: GLOBAL_SESSION_TIMEOUT_IDENTIFIER,
			timeoutMs: this.getSessionTimeout() * 1000, //to milliseconds
			onTimeout: () => {
				this.logout();
				this.fire(Graphileon.Event.SESSION_EXPIRED, {});
			}
		});

		if ((this.getSessionTimeoutWarnBefore() > 0) && (this.getSessionTimeoutWarnBefore() < this.getSessionTimeout())) {
			// set confirmation timeout
			GlobalTimeout.add({
				identifier: GLOBAL_SESSION_TIMEOUT_WARNING_IDENTIFIER,
				timeoutMs: (this.getSessionTimeout() - (this.getSessionTimeoutWarnBefore() + 1)) * 1000, //to milliseconds
				onTimeout: () => {
					this.displaySessionTimeoutWarning();
				}
			});
		}

		this.api.on(ApiClientAbstract.Event.REQUEST_SUCCESS, () => {
			GlobalTimeout.reset(GlobalTimeout.byIdentifier(GLOBAL_SESSION_TIMEOUT_IDENTIFIER));
			GlobalTimeout.reset(GlobalTimeout.byIdentifier(GLOBAL_SESSION_TIMEOUT_WARNING_IDENTIFIER));
		});
	}

	async displaySessionTimeoutWarning() {
		let alertOptions = {
			okButtonText: 'Continue working',
			headerText: 'Warning',
			onShow: (container) => {
				let countDown = (sec, container) => {
					if (GlobalTimeout.byIdentifier(GLOBAL_SESSION_TIMEOUT_WARNING_IDENTIFIER).expiredAt >= _.now() + (this.getSessionTimeoutWarnBefore())) {
						container.modal('hide');
						GlobalTimeout.reset(GlobalTimeout.byIdentifier(GLOBAL_SESSION_TIMEOUT_WARNING_IDENTIFIER));
						return;
					}

					if (sec < 0) {
						container.modal('hide');
						return;
					}

					$('#graphileonModalalert #logoutcountdown').text(sec);
					setTimeout(countDown, 1000, sec - 1, container);
				};

				setTimeout(countDown, 1000, this.getSessionTimeoutWarnBefore() - 1, container);
			}
		};
		let response = await this.viewmanager.alert(`<p><i class="fa fa-exclamation-triangle bg-warning" style="color: white; padding: 3px; margin: -5px; font-size: 42px; vertical-align: middle; margin-right: 10px; float: left;"></i> No activity detected. You will be logged out in <b id="logoutcountdown">${this.getSessionTimeoutWarnBefore()}</b> seconds`, alertOptions);
		if ( response === true )
			this.api.checkLicense();
	}

	getSessionTimeout() {
		return this.appInfo.sessionTimeout;
	}

	getSessionTimeoutWarnBefore() {
		return this.appInfo.sessionTimeoutWarnBefore;
	}

	removeSessionTimout() {
		GlobalTimeout.delete(GlobalTimeout.byIdentifier(GLOBAL_SESSION_TIMEOUT_IDENTIFIER));
		GlobalTimeout.delete(GlobalTimeout.byIdentifier(GLOBAL_SESSION_TIMEOUT_WARNING_IDENTIFIER));
	}

	/**
	 * Execute a LoginView
	 */
	executeLoginView(areaID, containerID) {
		log.log("Executing LoginView...");
		const loginArea = areaID || this.viewmanager.defaultArea;
		const loginContainer = containerID || '_new';

		const LoginView = this.factory.createFunctionInstance('LoginView');
		LoginView.execute({
			area: loginArea,
			container: {
				id: loginContainer,
				title: 'Login'
			}
		});
	};

	/**
	 * Execute a SetPasswordView
	 */
	async executeSetPasswordView(token) {
		log.log("Executing SetPasswordView...");
		// check token is valid
		let params = {
			"$token": token,
		};
		try {
			let tokenStatus = await this.api.checkResetPasswordToken(token);
		} catch(err) {
			
			params['tokenError'] = err.message;	
		}

		const SetPasswordView = this.factory.createFunctionInstance('SetPasswordView', {token});
		SetPasswordView.setParameters(params);
		SetPasswordView.execute({
			area: 'modal',
			container: {
				id: '_new',
				title: 'Set new password',
				width: 400
			}
		});
		SetPasswordView.on('trigger', data => {
			if (data.type === 'success') {
				setTimeout(() => {
					this.router.goHome()
				}, 2000);

			}
		});
	}
}
GraphileonClient.getDefaultBackendUrl = function() {
	return window.location.origin;
};

// Add client-specific events
GraphileonClient.Event.ROUTE_UNRESOLVED = 'unresolved';

window.Graphileon = GraphileonClient;
