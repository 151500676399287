/**
 * Created by Remi on 1-9-15.
 * TODO: NodeFormViewRenderer is currently based on legacy code. Should, in time, be renewed.
 * TODO: NodeFormViewRenderer currently access API directly. Should rather be done by NodeFormView Function.
 */

const EntityFormViewRenderer = require('./entity-form-view-renderer');
const log = require('core/src/log').instance("function/nodeformview/renderer");
const IAPI = require('core/src/api-client-abstract').default;

const $ = require('jquery');
const _ = require('core/src/utils/legacy');

const formToObject = require('client/src/utils/formToObject');

/* Inheritance and constructor */
const NodeFormViewRenderer = function (dependencies) {
	EntityFormViewRenderer.call(this, dependencies, 'node');
	this.api = dependencies.get(IAPI);
};
NodeFormViewRenderer.viewType = 'NodeFormView';
NodeFormViewRenderer.prototype = Object.create(EntityFormViewRenderer.prototype);

/* Statics */

/* Properties */

/* Methods */

NodeFormViewRenderer.prototype.initForm = function ($form) {
	EntityFormViewRenderer.prototype.initForm.call(this, $form);
	var typeInput = $form.find(':input[name=type]');
	typeInput.prop('disabled', 'disabled');
	this._$form.find('fieldset.type').hide();

	// Form might not be on screen at this point so we defer the focusing
	_.defer(() => $form.find(':input[name=labels]').focus());
};

NodeFormViewRenderer.prototype.isEditingTypedEntity = function() {
	const labels = this._$form.find(':input[name=labels]').val().split(/,\s*/);
	// Only if it is a new node or already has type property
	let data = formToObject(this._$form);
	let properties = _.get(data, 'properties.name');
	return (!this._$form.find('input[name=id]').val() || (properties && properties.indexOf('type') >= 0)) && labels.indexOf('IA_Function') >= 0;
};

NodeFormViewRenderer.prototype.validateEntity = function (node) {
	if (!_.isObject(node)) {
		log.error("Invalid node.", node);
	}
	return _.validate({
		id: [node.id, _.isStringOrNumber, "Invalid id.",
			{default: undefined, warn: _.def(node.id)}],
		labels: [node.labels, 'isString', {default: "", warn: _.def(node.labels)}],
		properties: [node.properties, 'isObject', {default: {}, warn: _.def(node.properties)}],
		meta: [node.meta, 'isObject', {default: {}, warn: _.def(node.meta)}]
	}, "Node is invalid.");
};

NodeFormViewRenderer.prototype.createEntityDescription = function(entity) {
	let entityDescription = EntityFormViewRenderer.prototype.createEntityDescription(entity);

	if (entity.meta.core) {
		entityDescription.push({label: 'Core Node', value: entity.meta.core});
	}

	return entityDescription;
}

NodeFormViewRenderer.prototype.doRender = function(renderData) {
	let render = EntityFormViewRenderer.prototype.doRender.call(this, renderData);

	this.core = _.get(renderData, 'node.meta.core');
	this.showRestore();

	return render;
}

NodeFormViewRenderer.prototype.onEditing = function() {
	EntityFormViewRenderer.prototype.onEditing.call(this);

	if (! this.core) {
		return;
	}

	let entity = this.formToEntity();
	let notChanged  = _.isEqual(this._initialData, entity.properties);

	if(notChanged) {
		if (_.eq(this.core, 'original')) {
			this._$form.find('.form-warning').addClass('hidden');
		}
	} else {
		if (_.eq(this.core, 'original')) {
			this._$form.find('.form-warning').removeClass('hidden');
		}
	}
}

NodeFormViewRenderer.prototype.addStoreSelector = function (stores) {
	EntityFormViewRenderer.prototype.onFormRendered.call(this);

	var storeNames = stores;

	var selectStoreTemplate = '\
		<select class="form-control select-store" data-for="meta[store]">\
<%\
				_.forEach(storeNames, function(storeName) {\
%>\
					<option value="<%= storeName %>"><%= storeName %></option>\
<%\
				});\
%>\
		</select>\
	';

	var selectStoreGenerator = _.template(selectStoreTemplate);
	var selectStore = selectStoreGenerator({storeNames: storeNames});
	this._$form.find('.entity-description [property-name=Store] .value').html(selectStore);

	this._$store = this._$form.find('.select-store');
	if(_.isString(this.store) && this.store.length > 0) {
		this._$store.val(this.store);
	}

	var self = this;
	this._$form.on('change', 'select[data-for]', function () {
		var $this = $(this);
		var inputName = $this.attr('data-for');

		var $input = self._$form.find('input[name="' + inputName + '"]');

		if (!$input || !$input[length]) {
			return _.withError('NodeFormViewRenderer.addStoreSelector: could not find input with name', inputName);
		}

		$input.val($this.val());
	});
};

NodeFormViewRenderer.prototype.requestSaveEntity = function (node) {
	var deferred = $.Deferred();
	var self = this;
	var request = this.api.save({
		id: node.id,
		labels: node.labels.split(',').map(v => $.trim(v)),
		properties: node.properties,
		meta: node.meta
	});

	request.done(function (response) {
		let entity = _.extend({}, self._initData['preset'], response);
		deferred.resolve(entity);
	});

	request.fail(function (response) {
		deferred.reject(response);
	});

	return deferred.promise();
};

NodeFormViewRenderer.prototype.restore = async function() {
	if (! this.uuid) return;

	let node = await this.api.getNodeBackup({properties: {uuid: this.uuid}});
	
	if (!node) return;

	node.id = this._initData.node.id;
	this.clearEntityProperties();
	this.setEntity(node);
	this.onEditing();
	this._$form.find('.btn-restore').hide();
}

NodeFormViewRenderer.prototype.showRestore = async function() {
	if (! this.uuid) return;
	if (this.core !== 'modified') {
		return;
	}

	let node = await this.api.getNodeBackup({properties: {uuid: this.uuid}});
	if (! node) return;

	let $btnRestore = this._$form.find('.btn-restore');
	$btnRestore.removeClass('hidden');
	$btnRestore.one('click', () => {
		this.restore();
	})
}

module.exports = NodeFormViewRenderer;

