const ViewRenderer = require('../view-renderer');
const Mustache = require('mustache');
const Editor = require('client/src/editor');

const $ = require('jquery');
const _ = require('core/src/utils/legacy');
const { isjQuery } = require('client/src/utils/jquery');

require('./search-view-renderer.scss');

/* Inheritance and constructor */

const SearchViewRenderer = function (dependencies) {
	ViewRenderer.call(this);
	this.dependencies = dependencies;
};
SearchViewRenderer.viewType = 'SearchView';
SearchViewRenderer.prototype = Object.create(ViewRenderer.prototype);


/* Properties */

SearchViewRenderer.prototype._$form = null;
SearchViewRenderer.prototype._$editor = null;
SearchViewRenderer.prototype._$output = null;
SearchViewRenderer.prototype._$store = null;
SearchViewRenderer.prototype._outputIndexes = {};

/* Methods */

SearchViewRenderer.prototype.submit = function () {
	var data = {};

	data.query = this._$editor.getValue();
	data.store = this._$store.val();

	var event = {
		type: 'execute',
		data: data
	};
	if(isjQuery(this._$output)) {
		event.output = this._$output.val();
		const output = _.find(this._outputs, output => output.label === event.output);
		if(output) event.index = output.index;
	}

	this.trigger(event);
};

SearchViewRenderer.prototype._setupEditor = function (element, settings) {
	var self = this;

	var defaultSettings = {
		executeOnEnter: false,
		rows: 1,
		syntax: null,
		value: ''
	};

	settings = _.extend({}, defaultSettings, settings);

	const editor = new Editor(this.dependencies, element, [
		'cypher',
		'sparql',
		'graphql'
	]);

	editor.setMode(settings.syntax);

	this._$editor = editor;

	setTimeout(function () {
		editor.refresh();
		if (_.def(settings.minHeight)) {
			$(editor._codeMirror.display.scroller).css('min-height', settings.minHeight);
		}
		if (_.def(settings.maxHeight)) {
			$(editor._codeMirror.display.scroller).css('max-height', settings.maxHeight);
		}
		if (settings.syntax === 'graphql' && settings.graphqlSchemaUrl) {
			editor.setContext('graphql', 'graphqlSchemaUrl', settings.graphqlSchemaUrl);
		}
	}, 0);

	if (settings.syntax === 'cypher') {
		editor.setOption('lineNumbers', true);
		editor.setOption('smartIndent', false);
	}

	if (settings.executeOnEnter === true) {
		editor.setOption("extraKeys", {
			Enter: function (cm) {
				self.submit();
			}
		});
	}

	editor.setValue(settings.value);
};

SearchViewRenderer.prototype.doRender = function (renderData) {
	var self = this;

	var context = {};
	context.store = renderData.store;
	context.output = renderData.output;
	context.outputs = renderData.outputs || [];
	context.stores = renderData.stores;
	context.singleLine = renderData.singleLine === true;
	context.hideOutputs = renderData.hideOutputs === true;
	context.hideStores = renderData.hideStores === true;
	context.hideExecuteButton = renderData.hideExecuteButton === true;
	context.queryTitle = renderData.queryTitle || 'Query';

	if(!_.isArray(renderData.stores) || renderData.stores.length === 0) {
		context.hideStores = true;
	}

	// CodeMirror
	var query = renderData.query || '';
	var queryMinHeight = renderData.queryMinHeight || renderData.queryFieldRows; // queryFieldRows is legacy
	var queryMaxHeight = renderData.queryMaxHeight || '300px';
	var executeOnEnter = renderData.executeOnEnter !== false;
	var syntax = renderData.syntax || undefined;
	var graphqlSchemaUrl = renderData.graphqlSchemaUrl || undefined;

	var template = require('./search-view-renderer.html');
	var render = Mustache.render(template, context);

	var $render = $(render);
	if(context.singleLine) {
		$render.addClass('searchview-singleline');
	}

	this._$store = $render.find('.select-store');
	if(context.store) {
		this._$store.val(context.store);
	}

	this._$store.on('change', () => {
		this._$editor.setContext('cypher-editor', 'store', this._$store.val());
	});

	// CodeMirror
	self._setupEditor($render.find('textarea')[0], {
		executeOnEnter: executeOnEnter,
		syntax: syntax,
		value: query,
		minHeight: queryMinHeight,
		maxHeight: queryMaxHeight,
		graphqlSchemaUrl: graphqlSchemaUrl
	});

	this._$output = $render.find('.select-output');
	this._$output.val(context.output);
	this._outputs = context.outputs;

	$render.find('button.execute').click(function(e) {
		e.preventDefault();
		self.submit();
	});

	return $render;
};

module.exports = SearchViewRenderer;
