var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "batch-action",
      style: _vm.style,
      attrs: { disabled: !_vm.enable },
      on: {
        click: function ($event) {
          return _vm.$emit("click", _vm.id)
        },
      },
    },
    [
      _vm.icon ? _c("span", { class: `fa fa-${_vm.icon}` }) : _vm._e(),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.labelHtml) } }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }