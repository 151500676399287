var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "background",
      staticClass: "modal-background",
      style: _vm.backgroundStyle,
      on: {
        click: _vm.onBackgroundClick,
        contextmenu: function ($event) {
          $event.preventDefault()
          return _vm.onBackgroundClick.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-container",
          style: _vm.containerStyle,
          on: {
            click: _vm.onContainerClick,
            contextmenu: function ($event) {
              $event.preventDefault()
              return _vm.onContainerClick.apply(null, arguments)
            },
            mouseenter: _vm.onMouseEnter,
            mouseleave: _vm.onMouseLeave,
          },
        },
        [
          _vm.showTitle
            ? _c(
                "h4",
                {
                  staticClass: "title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  this.hasCloseButton
                    ? _c("i", {
                        staticClass:
                          "fa fa-times clickable close-container-button",
                        attrs: { "data-action": "close-container" },
                        on: { click: _vm.close },
                      })
                    : _vm._e(),
                  _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            ref: "content",
            staticClass: "container-content",
            style: _vm.contentStyle,
            attrs: { slot: "content" },
            slot: "content",
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }